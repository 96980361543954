<template>
  <div class="campfire-container" :class="getCampfireClass()">
    <h1 class="campfire-title">🔥 Campfire ({{ countdownTime }}) 🔥</h1>
    <p v-if="sortedCampfireLeaderboard.length === 0" class="no-contributors">
      It looks like no one has contributed to the campfire. 😳 Throw some wood
      onto the campfire!
    </p>
    <ul class="campfire-list">
      <li
        v-for="contributor in sortedCampfireLeaderboard"
        :key="contributor.userid"
        class="campfire-item"
        :class="{ 'current-user': google_id === contributor.userid }"
        @click="updateProfileModal(contributor.userid)"
      >
        <span class="name">{{ contributor.displayName }}</span>
        <span class="contributions">{{
          formattedPointsOrScore(contributor.contributions)
        }}</span>
      </li>
    </ul>
    <div class="campfire-visual">
      <div
        v-for="i in 5"
        :key="i"
        class="fire-log"
        :class="{ active: i <= getActiveLogCount() }"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";

export default {
  emits: ["updateProfileModal"],
  props: {
    campfireLeaderboard: {
      type: Array,
      required: true,
      default: () => [],
    },
    google_id: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formattedPointsOrScore = (points) => {
      const suffixes = ["", "k", "m", "b", "t", "q", "Q", "s", "S", "o"];

      if (points < 10000) {
        return points.toString();
      }

      let suffixIndex = 0;

      while (points >= 1000 && suffixIndex < suffixes.length - 1) {
        points /= 1000;
        suffixIndex++;
      }

      let formattedPoints =
        points % 1 !== 0 ? points.toFixed(1) : Math.floor(points);

      // Remove '.0' if present
      formattedPoints = formattedPoints.toString().replace(/\.0$/, "");

      return formattedPoints + suffixes[suffixIndex];
    };

    let backendUrl =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_API_URL
        : "http://localhost:3003";
    const countdownTime = ref("00:00");
    const lastExecutionTimestamp = ref(null);

    const calculateTimeUntilNextHour = () => {
      if (!lastExecutionTimestamp.value && lastExecutionTimestamp.value !== 0)
        return;
      const now = Date.now();

      let remainingTime = 3600000 + lastExecutionTimestamp.value - now;
      while (remainingTime < 0) {
        lastExecutionTimestamp.value += 3600000;
        remainingTime += 3600000;
      }

      const secondsLeft = Math.floor((remainingTime % 60000) / 1000);
      const minutesLeft = Math.floor(remainingTime / 60000);

      countdownTime.value = `${String(minutesLeft).padStart(2, "0")}:${String(
        secondsLeft
      ).padStart(2, "0")}`;
    };

    const fetchLastExecutionTimestamp = async () => {
      try {
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }

        const response = await fetch(`${backendUrl}/getLastExecution`, {
          headers: {
            sessionToken,
          },
          // credentials: "include",
        });
        const data = await response.json();

        if (response.ok) {
          lastExecutionTimestamp.value = data.lastExecutionTimestamp;
        } else {
          console.error("Error fetching last execution timestamp:", data.error);
        }
      } catch (error) {
        console.error("Error fetching last execution timestamp:", error);
      }
    };

    const sortedCampfireLeaderboard = computed(() => {
      const leaderboard = props.campfireLeaderboard;

      if (Array.isArray(leaderboard) && leaderboard.length > 0) {
        return leaderboard
          .slice()
          .sort((a, b) => b.contributions - a.contributions);
      } else {
        return [];
      }
    });

    const getCampfireClass = () => {
      const count = sortedCampfireLeaderboard.value.length;
      if (count === 0) return "empty";
      if (count < 3) return "few";
      if (count < 7) return "moderate";
      return "many";
    };

    const getActiveLogCount = () => {
      const count = sortedCampfireLeaderboard.value.length;
      return Math.min(Math.ceil(count / 2), 5);
    };

    onMounted(async () => {
      await fetchLastExecutionTimestamp();
      calculateTimeUntilNextHour();

      // Update the countdown every second
      setInterval(calculateTimeUntilNextHour, 1000);
    });

    return {
      formattedPointsOrScore,
      countdownTime,
      lastExecutionTimestamp,
      sortedCampfireLeaderboard,
      updateProfileModal: (userid) => emit("updateProfileModal", userid),
      getCampfireClass,
      getActiveLogCount,
    };
  },
};
</script>

<style scoped>
.campfire-container {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  padding: 15px;
  margin: auto;
  max-width: 88%;
  position: relative;
  overflow: hidden;
}

.campfire-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.no-contributors {
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}

.campfire-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.campfire-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--secondary-bg);
  border-radius: 4px;
  cursor: pointer;
}

.campfire-item:hover {
  background-color: var(--button-hover-bg);
}

.campfire-item.current-user {
  background-color: var(--accent-bg);
}

.name {
  font-size: 16px;
}

.contributions {
  font-weight: bold;
  font-size: 16px;
}

.campfire-visual {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.fire-log {
  width: 40px;
  height: 10px;
  background-color: #8b4513;
  margin: 0 5px;
  border-radius: 5px;
  opacity: 0.3;
  transition: opacity 0.3s ease;
}

.fire-log.active {
  opacity: 1;
}

.campfire-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 165, 0, 0.1);
  z-index: -1;
  transition: opacity 0.3s ease;
}

.campfire-container.empty::before {
  opacity: 0.05;
}

.campfire-container.few::before {
  opacity: 0.1;
}

.campfire-container.moderate::before {
  opacity: 0.15;
}

.campfire-container.many::before {
  opacity: 0.2;
}
</style>
