<template>
  <div class="loading-container" :class="{ 'loading-dissolve': !loading }">
    <img
      src="/img/icons/android-chrome-512x512-white.png"
      alt="App Logo"
      class="app-logo-loading"
    />
    <div class="loading-overlay"></div>
    <div class="stars-container-loading"></div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
  mounted() {
    this.generateStars();
  },
  methods: {
    generateStars() {
      const starsContainer = this.$el.querySelector(".stars-container-loading");

      const addStarWithDelay = (index) => {
        setTimeout(() => {
          let iteration = 0;
          const star = document.createElement("div");
          star.className = "star-loading";
          const size = Math.pow(Math.random(), 2) * 3 + 1 + "px"; // Bias towards smaller sizes
          star.style.width = size;
          star.style.height = size;

          let x, y;
          do {
            x = Math.random() * 100;
            y = Math.random() * 100;
            iteration++;
          } while (iteration < 4 && x >= 42 && x <= 58 && y >= 42 && y <= 58);

          star.style.top = y + "%";
          star.style.left = x + "%";

          starsContainer.appendChild(star);
          this.animateStar(star);
        }, index * 4);
      };

      for (let i = 0; i < 20; i++) {
        addStarWithDelay(i);
      }
    },
    animateStar(star) {
      const animationDuration = Math.random() * 8 + 4; // Random duration for variety
      const twinkleDelay = Math.random() * animationDuration; // Random twinkle delay within the animation duration

      star.style.animation = `
        twinkle 2s ${twinkleDelay}s infinite ease-in-out
      `;
    },
  },
};
</script>

<style>
.loading-dissolve {
  opacity: 0;
  transition: opacity 0.2s;
}
.loading-container {
  z-index: 100000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black !important;
  user-select: none;
}

.app-logo-loading {
  width: 140px;
  height: auto;
  animation: rotate 3.5s infinite cubic-bezier(0.4, 0, 0.2, 1.05), glow 1.75s infinite alternate;
  z-index: 1221313123123213123;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  user-select: none;
}

.stars-container-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.star-loading {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  animation: twinkle 2s infinite ease-in-out;
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg); /* Speed up the rotation */
  }
  50% {
    transform: rotate(180deg); /* Slow down the rotation */
  }
  75% {
    transform: rotate(270deg); /* Speed up the rotation */
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes glow {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.3);
  }
}
</style>
