import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Changelogs from "../views/Changelogs.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/changelogs",
    name: "changelogs",
    component: Changelogs
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }; // Set the position to the top-left corner of the page
  },
});

router.beforeEach((to, from, next) => {
  // Check if the user is trying to access the changelogs page
  if (to.path === '/changelogs') {
    // Check if the page is refreshed
    const isPageRefreshed = !from.name && to.name;
    if (isPageRefreshed) {
      // If the page is refreshed, redirect to the main route ("/")
      next('/');
    } else {
      // Proceed to the requested route
      next();
    }
  }
  else if (from.path === '/changelogs' && to.path === '/') {
    // If navigating from /changelogs to /, refresh the page
    window.location.reload();
  } 
  else {
    // Proceed to other routes
    next();
  }
});

export default router;
