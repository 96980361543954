<template>
  <div class="donation-container">
    <h2>Support Ranked Rumble</h2>
    <p class="donation-description">
      Your donations are greatly appreciated and help us continue improving the
      game!
    </p>
    <div class="donation-options">
      <a
        :href="stripeUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="donate-button stripe"
      >
        <img src="/img/stripe.svg" alt="Stripe" class="button-icon svg" />
        Donate with Stripe
      </a>
      <button
        @click="toggleInfo"
        class="donate-button ethereum"
        :aria-expanded="showInfo.toString()"
      >
        <img src="/img/ethereum-logo.png" alt="Ethereum" class="button-icon" />
        Donate Ethereum
        <span class="expand-icon" :class="{ rotated: showInfo }">▼</span>
      </button>
    </div>
    <transition name="fade">
      <div v-if="showInfo" class="ethereum-info">
        <p class="address-label">Ethereum Address:</p>
        <div class="address-container">
          <span class="address"
            >0x9dF8406ba1c60C09237A4054473a0f774434f103</span
          >
        </div>
        <img
          src="/img/wallet/qrcode.png"
          alt="Ethereum QR Code"
          class="qr-code"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stripeUrl: "https://donate.stripe.com/4gw16N4gSgS2fAI6oo",
      showInfo: false,
      copyButtonText: "Copy",
      copyButtonLabel: "Copy Ethereum address",
    };
  },
  methods: {
    toggleInfo() {
      this.showInfo = !this.showInfo;
    },
    copyAddress() {
      // ... existing code ...
    },
  },
};
</script>

<style scoped>
.donation-container {
  background-color: var(--primary-bg);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  max-width: 500px;
  padding: 15px 70px;
  text-align: center;
}

h2 {
  color: var(--primary-text);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.donation-description {
  color: var(--secondary-text);
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.donation-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.donate-button {
  align-items: center;
  background-color: var(--button-bg);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  font-weight: bold;
  justify-content: center;
  padding: 0.8rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.1s;
  color: #fff;
}

.donate-button:hover {
  background-color: var(--button-hover-bg);
  transform: translateY(-2px);
}

.donate-button:active {
  transform: translateY(0);
}

.button-icon {
  height: 24px;
  margin-right: 0.2rem;
  width: 24px;
  object-fit: contain;
}
.svg {
  margin-top: -2px;
}

.stripe {
  background-color: #6772e5;
}

.ethereum {
  background-color: #3c3c3d;
}

.expand-icon {
  display: inline-block;
  margin-left: 0.5rem;
  transition: transform 0.3s;
}

.expand-icon.rotated {
  transform: rotate(180deg);
}

.ethereum-info {
  background-color: var(--secondary-bg);
  border-radius: 8px;
  margin-top: 1rem;
  padding: 1.5rem;
}

.address-label {
  color: var(--secondary-text);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.address-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.address {
  background-color: var(--input-bg);
  border-radius: 4px;
  color: var(--primary-text);
  font-family: monospace;
  font-size: 0.9rem;
  padding: 0.5rem;
  user-select: all;
}

.copy-button {
  background-color: var(--button-bg);
  border: none;
  border-radius: 4px;
  color: var(--button-text);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  padding: 0.5rem 0.8rem;
  transition: background-color 0.3s;
}

.copy-button:hover {
  background-color: var(--button-hover-bg);
}

.copy-icon {
  height: 16px;
  margin-right: 0.3rem;
  width: 16px;
}

.qr-code {
  border-radius: 8px;
  max-width: 200px;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, max-height 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  max-height: 0;
}

@media (min-width: 768px) {
  .donation-options {
    flex-direction: row;
    justify-content: center;
  }

  .donate-button {
    min-width: 200px;
  }
}
</style>
