<template>
  <transition name="fade">
    <div @click="onClose" class="profile-modal">
      <div @click.stop class="modal-content">
        <button class="close-btn" @click="onClose" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="user-header">
          <img
            :src="`/img/avatars/${user.avatar || 'chick'}.jpg`"
            :alt="`${formatDisplayName(user.displayName)}'s avatar`"
            class="avatar"
          />
          <h2 class="user-name">
            {{ formatDisplayName(user.displayName) }}
            <span v-if="user.id === currentUserID" class="current-user-badge"
              >(you)</span
            >
          </h2>
        </div>

        <div class="tabs">
          <button
            v-for="tab in ['Resources', 'Buildings', 'Stats']"
            :key="tab"
            @click="activeTab = tab"
            :class="['tab-btn', { active: activeTab === tab }]"
          >
            {{ tab }}
          </button>
        </div>

        <div v-if="activeTab === 'Resources'" class="user-info resources">
          <div
            v-for="(item, index) in resourceItems"
            :key="index"
            class="info-item"
          >
            <div class="item-content">
              <p class="emoji" :aria-label="item.label">{{ item.emoji }}</p>
              <div>
                <p class="label">{{ item.label }}</p>
                <p class="value">{{ formattedPointsOrScore(item.value) }}</p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activeTab === 'Buildings'" class="user-info buildings">
          <div
            v-for="(item, index) in buildingItems"
            :key="index"
            class="info-item"
          >
            <div class="item-content">
              <p class="emoji" :aria-label="item.label">{{ item.emoji }}</p>
              <div>
                <p class="label">{{ item.label }}</p>
                <p class="value">{{ formattedPointsOrScore(item.value) }}</p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activeTab === 'Stats'" class="user-info stats">
          <div
            v-for="(item, index) in userStats"
            :key="index"
            class="info-item stat-item"
          >
            <div class="item-content">
              <p class="emoji" :aria-label="item.label">{{ item.emoji }}</p>
              <div>
                <p class="label">{{ item.label }}</p>
                <p class="value">{{ formattedPointsOrScore(item.value) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  // "destroyFarm", "steal"
  emits: ["close"],
  props: {
    user: {
      type: Object,
      default: () => ({}),
      // Use the deep option to enable deep reactivity
      deep: true,
    },
    currentUser: {
      type: Object,
      default: () => ({}),
      // Use the deep option to enable deep reactivity
      deep: true,
    },
    currentUserID: Number,
  },
  data() {
    return {
      activeTab: "Resources",
    };
  },
  methods: {
    formattedPointsOrScore(points) {
      const suffixes = ["", "k", "m", "b", "t", "q", "Q", "s", "S", "o"];

      if (points < 10000) {
        return points.toString();
      }

      let suffixIndex = 0;

      while (points >= 1000 && suffixIndex < suffixes.length - 1) {
        points /= 1000;
        suffixIndex++;
      }

      let formattedPoints =
        points % 1 !== 0 ? points.toFixed(1) : Math.floor(points);

      // Remove '.0' if present
      formattedPoints = formattedPoints.toString().replace(/\.0$/, "");

      return formattedPoints + suffixes[suffixIndex];
    },

    formatDisplayName(fullName) {
      if (!fullName) return fullName;

      const names = fullName.split(" ");
      const firstName = names[0];

      // Check if lastNameInitial is defined and not an empty string
      const lastNameInitial =
        names.length > 1 ? names[names.length - 1].charAt(0) : "";

      // Filter out empty spaces and handle cases with no last name
      const modifiedDisplayName = `${firstName}${
        lastNameInitial ? ` ${lastNameInitial}` : ""
      }`;

      return modifiedDisplayName;
    },

    onClose() {
      document.body.classList.remove("body-no-scroll");

      this.$emit("close");
    },
    onKeyPress(event) {
      if (event.key === "Escape") {
        this.onClose();
      }
    },
    steal(uid) {
      if (!uid) {
        console.log("No uid");
        return;
      }

      this.$emit("steal", uid);
    },
    destroyFarm(uid, displayName) {
      if (!uid) {
        console.log("No uid");
        return;
      }
      if (!displayName) {
        console.log("No displayName");
        return;
      }
      this.$emit("destroyFarm", uid, displayName);
    },
  },
  computed: {
    userItems() {
      return [
        { label: "Points", emoji: "🌟", value: this.user.points || 0 },
        { label: "Wood", emoji: "🌲", value: this.user.wood || 0 },
        {
          label: "Electricity",
          emoji: "⚡️",
          value: this.user.electricity || 0,
        },
        { label: "Piety", emoji: "🙏", value: this.user.piety || 0 },
        { label: "Science", emoji: "🧬", value: this.user.science || 0 },

        { label: "Farms", emoji: "🚜", value: this.user.farms || 0 },
        { label: "Priests", emoji: "⛪", value: this.user.priests || 0 },
        {
          label: "Guard Towers",
          emoji: "🏰",
          value: this.user.guardTowers || 0,
        },
        {
          label: "Laboratories",
          emoji: "🔬",
          value: this.user.laboratories || 0,
        },
        {
          label: "Siphon Towers",
          emoji: "🗼",
          value: this.user.siphonTowers || 0,
        },
        {
          label: "Wood Powers",
          emoji: "🌲⚡️",
          value: this.user.woodpowers || 0,
        },
        {
          label: "Underlings",
          emoji: "👹",
          value: this.user.underlings || 0,
        },
        {
          label: "Overlords",
          emoji: "🎭",
          value: this.user.overlords || 0,
        },
        {
          label: "Diamonds",
          emoji: "💎",
          value: this.user.diamonds || 0,
        },
        {
          label: "Essence",
          emoji: "🌌",
          value: this.user.essence || 0,
        },
        {
          label: "Bug Remains",
          emoji: "🕷️",
          value: this.user.bugRemains || 0,
        },
        {
          label: "Leech",
          emoji: "🩸",
          value: this.user.leech || 0,
        },
      ];
    },
    userStats() {
      return [
        // {
        //   label: "Points Stolen",
        //   emoji: "💰",
        //   value: this.user.stats?.pointsStolen || 0,
        // },
        // {
        //   label: "Farms Destroyed",
        //   emoji: "🔥",
        //   value: this.user.stats?.farmsDestroyed || 0,
        // },
        {
          label: "Technologies Researched",
          emoji: "🔎",
          value: this.user.stats?.technologiesResearched || 0,
        },
        {
          label: "Wood Thrown",
          emoji: "👋",
          value: this.user.stats?.woodThrown || 0,
        },
        {
          label: "Campfires Won",
          emoji: "🔥",
          value: this.user.stats?.campfiresWon || 0,
        },
        {
          label: "Attacks Launched",
          emoji: "⚔️",
          value: this.user.stats?.attacksLaunched || 0,
        },
      ];
    },
    resourceItems() {
      return this.userItems.filter((item) =>
        [
          "Points",
          "Wood",
          "Electricity",
          "Piety",
          "Science",
          "Diamonds",
          "Essence",
          "Bug Remains",
          "Leech",
        ].includes(item.label)
      );
    },
    buildingItems() {
      return this.userItems.filter((item) =>
        [
          "Farms",
          "Priests",
          "Guard Towers",
          "Laboratories",
          "Siphon Towers",
          "Wood Powers",
          "Underlings",
          "Overlords",
        ].includes(item.label)
      );
    },
  },
  mounted() {
    document.body.classList.add("body-no-scroll");

    window.addEventListener("keydown", this.onKeyPress);
  },
  // Remove the event listener when the component is destroyed
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeyPress);
  },
};
</script>

<style scoped>
.profile-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
}

.modal-content {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  padding: 2rem;
  border: 1px solid var(--border-color);
  max-width: 90%;
  max-height: 90vh;
  width: 600px;
  margin: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  text-align: center;
  position: relative;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) var(--primary-bg);
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 4px;
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
  color: var(--primary-text);
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.close-btn:hover {
  opacity: 1;
}

.user-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border: 3px solid var(--border-color);
}

.user-name {
  font-size: 1.8rem;
  color: var(--primary-text);
  margin: 0;
}

.current-user-badge {
  font-size: 0.9rem;
  background-color: var(--accent-bg);
  color: var(--accent-text);
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  margin-left: 0.5rem;
  vertical-align: middle;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.tab-btn {
  background-color: var(--secondary-bg);
  border: none;
  color: var(--secondary-text);
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  cursor: pointer;
  border-radius: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-btn.active {
  background-color: var(--accent-bg);
  color: var(--accent-text);
}

.user-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  justify-content: center;
}

.info-item {
  padding: 1rem;
  border: 1px solid var(--border-color);
  background-color: var(--secondary-bg);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.item-content {
  display: flex;
  align-items: center;
}

.emoji {
  font-size: 2rem;
  margin-right: 0.5rem;
}

.label {
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
  color: var(--secondary-text);
}

.value {
  font-size: 1.1rem;
  font-weight: 500;
}

.stat-item {
  grid-column: span 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 600px) {
  .modal-content {
    padding: 1.5rem;
  }

  .user-header {
    flex-direction: column;
  }

  .avatar {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .user-name {
    font-size: 1.5rem;
  }

  .user-info {
    grid-template-columns: 1fr;
  }

  .stat-item {
    grid-column: span 1;
  }
}
</style>
