<template>
  <div id="app">
    <Loading v-if="show" :loading="loading"></Loading>
    <router-view @loaded2="loadingDone"></router-view>
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";

export default {
  metaInfo: {
    title: "Ranked Rumble - Dominate the Leaderboards",
    meta: [
      {
        name: "description",
        content:
          "Join Ranked Rumble, the ultimate competitive platform where you can gain points, increase your influence, and dominate in various challenges. Sign up now to start your journey!",
      },
    ],
  },
  data() {
    return {
      loading: true,
      show: true,
      backendUrl:
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_API_URL
          : "http://localhost:3003",
    };
  },
  methods: {
    loadingDone() {
      this.loading = false;
      setTimeout(() => {
        this.show = false;
      }, 200); // Adjust the time as needed
    },

  },
  async mounted() {

    // setTimeout(() => {
    //   this.loading = false;
    // }, 1700); // Adjust the time as needed
    // setTimeout(() => {
    //   this.show = false;
    // }, 1850); // Adjust the time as needed
  },
  components: {
    Loading,
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  min-height: 100vh;
}
body {
  margin: 0;
}
.margin-0 {
  margin: 0;
}
.padding-0 {
  padding: 0;
}
.dark {
  background-color: #eaeaea;
}
.relative {
  position: relative;
}
.disable-highlight {
  user-select: none;
}
.disable-zoom-on-double-tap {
  touch-action: manipulation;
}
/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.relative {
  position: relative;
}
button:active {
  transform: scale(0.95); /* Scale down when the button is pressed */
}
.small-horizontal-margin {
  margin: 0 1.2rem;
}
.fit-content {
  width: fit-content;
}
body {
  cursor: default;
}
.pointer {
  cursor: pointer;
}
.body-no-scroll {
  overflow: hidden;
}
.flex-one {
  flex: 1;
}
.text-align-center {
  text-align: center;
}
.light-text {
  color: #fff;
}
.dark-text {
  color: #000;
}
</style>
