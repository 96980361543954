<template>
  <div v-if="!hide" class="feedback-container">
    <form v-if="showFeedbackForm && !loading" @submit.prevent="submitFeedback">
      <textarea
        @input="checkLength()"
        v-model="feedback"
        placeholder="Leave your feedback"
        maxlength="500"
        required
      ></textarea>
      <div class="char-counter">
        {{ 500 - feedback.length }} characters remaining
      </div>
      <button class="submit" type="submit" :disabled="isSubmitDisabled">Submit Feedback</button>
    </form>
    <div class="reward-text" v-if="showFeedbackForm && !loading">
      Earn exclusive bonuses for reporting bugs
    </div>
    <div v-if="loading" class="loading-spinner">
      <!-- You can use a loading spinner here -->
      Sending Feedback...
    </div>
    <div v-if="showThankYou && !loading" class="proper-text">
      Thank You for leaving your feedback!
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayName: {
      type: String,
      required: true,
    },
    googleId: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      feedback: "",
      hide: false,
      showFeedbackForm: true,
      showThankYou: false,
      lastSubmitTime: null,
      isSubmitDisabled: false,
      backendUrl:
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_API_URL
          : "http://localhost:3003",
    };
  },
  methods: {
    checkLength() {
      if (this.feedback.length > 1000) {
        this.feedback = this.feedback.slice(0, 1000);
      }
    },
    submitFeedback() {
      if (this.feedback.length < 10) {
        alert("Feedback must be at least 10 characters long.");
        return;
      }
      const currentTime = new Date().getTime();
      if (!this.lastSubmitTime || currentTime - this.lastSubmitTime >= 24000) {
        this.sendFeedbackEmail();
        this.lastSubmitTime = currentTime;
      } else {
        alert("Feedback can only be submitted irregularly.");
      }
    },
    async sendFeedbackEmail() {
      this.loading = true;
      // Implement API call to your backend to send email

      const sessionToken = localStorage.getItem("sessionToken");

      if (!sessionToken) {
        // Handle the case when the session token is not available
        console.error("Session token not available");
        return;
      }

      const response = await fetch(`${this.backendUrl}/sendFeedback`, {
        // credentials: "include",
        method: "POST",
        headers: { "Content-Type": "application/json", sessionToken },
        body: JSON.stringify({
          feedback: this.feedback,
          displayName: this.displayName,
          googleId: this.googleId,
          email: this.email,
        }),
      });
      if (response.ok) {
        this.feedback = "";
        this.showFeedbackForm = false;
        this.showThankYou = true;
        setTimeout(() => {
          this.hide = true;
        }, 5000);
      } else {
        alert("Error submitting feedback.");
        this.showFeedbackForm = false;
        this.showThankYou = false;
        this.hide = true;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.submit {
  background-color: var(--border-color);
  color: var(--primary-text);
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--primary-text);
  padding: 20px 0;
}

.feedback-container {
  background-color: var(--primary-bg);
  padding: 20px 70px;
  margin: 10px auto;
  max-width: 500px;
}

.feedback-container h3 {
  text-align: justify
}

.reward-text {
  text-align: justify;
  font-size: 0.8em;
  margin-top: 10px;
  color: var(--primary-text);
  font-weight: 300;
}

.feedback-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feedback-container textarea {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  resize: none; /* Disable resizing */
  min-height: 100px;
  background-color: var(--secondary-bg);
  color: var(--primary-text);
}

.feedback-container textarea::placeholder {
  color: var(--primary-text);
}

.feedback-container button {
  background-color: var(--button-bg);
  color: var(--primary-text);
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feedback-container button:hover {
  background-color: var(--button-hover-bg);
}

.feedback-container button:disabled {
  background-color: var(--third-bg);
  cursor: not-allowed;
}

.char-counter {
  text-align: right;
  font-size: 0.7em;
  color: var(--primary-text);
}

.proper-text {
  text-align: center;
  font-size: 1.2em;
  color: var(--primary-text);
}
</style>
