<template>
  <div class="changelogs">
    <header>
      <h1>Changelogs</h1>
      <button @click="goToMainPage" class="btn">Back to Game</button>
    </header>
    <main>
      <div class="changelog-list">
        <div
          v-for="changelog in changelogs"
          :key="changelog.version"
          class="changelog-item"
        >
          <div class="changelog-header">
            <h2>{{ changelog.version }}</h2>
            <time :datetime="changelog.date">{{
              formatDate(changelog.date)
            }}</time>
          </div>
          <ul class="changes">
            <li v-for="(change, index) in changelog.changes" :key="index">
              {{ change }}
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  methods: {
    goToMainPage() {
      this.$router.push("/");
    },
    formatDate(dateString) {
      const [day, month, year] = dateString.split("/");
      const date = new Date(`${year}-${month}-${day}`);
      const options = { year: "numeric", month: "short", day: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
  },
  data() {
    return {
      changelogs: [
        {
          version: "1.3.7",
          date: "26/07/2024",
          changes: [
            "UI updates",
            "Added four new avatars: rhino, elephant, giraffe, and tiger.",
          ],
        },
        {
          version: "1.3.6",
          date: "25/07/2024",
          changes: [
            "Each leech now leeches at a rate of 0.000002 per point. (min 1 point per leech).",
            "Changed Leaderboard graph to be a log graph.",
          ],
        },
        {
          version: "1.3.5",
          date: "20/07/2024",
          changes: [
            "Introduced Leech. Leeches cost an Overlord to attach to another player and drain their points. Leeches can be removed by clicking on them in your resources overview.",
            "Introduced Avatars. Players can now choose an avatar to represent them in the game. Right now we have 10 different animals to choose more. Feel free to suggest more avatars.",
            "Updated landing page.",
          ],
        },
        {
          version: "1.3.4",
          date: "09/07/2024",
          changes: [
            "Increased max number limit to database fields.",
            "Added bug remains resource - players can be rewarded for finding and reporting bugs.",
            "Added two new Technologies for upgrading Underling attack power.",
          ],
        },
        {
          version: "1.3.3",
          date: "06/07/2024",
          changes: [
            "Added disclaimer about server location. If you experience slightly delayed responses from the server, that is the reason.",
            "Changed Siphon Tower weights for selecting target from a log function to a square root function.",
            "Siphon Tower now cannot put someone below 0 points.",
            "Show current actions being performed by other players in a live feed.",
            "Various small UI improvements.",
          ],
        },
        {
          version: "1.3.2",
          date: "18/06/2024",
          changes: [
            "Attacks now target one random infrastructure. Undefended attacks will still damage all infrastructures.",
          ],
        },
        {
          version: "1.3.1",
          date: "10/06/2024",
          changes: [
            "Added new music: introduced classical and modern tracks. The classical track aligns more closely with the game's theme.",
            "Improved UI for the music section.",
            "Modified modern playlist: removed two songs and renamed remaining four tracks.",
            "Added three new tracks to the classical playlist: 'Hero's Journey', 'Peril and Wonder', and 'Safe Haven'.",
            "Balanced some expedition routes and increased cooldown by 20m.",
            "Implemented a filter to remove parentheses from player names.",
            "Removed players with a score of zero from the leaderboard.",
          ],
        },
        {
          version: "1.3.0.3",
          date: "02/06/2024",
          changes: ["Added Online Players Count to the top of the page."],
        },
        {
          version: "1.3.0.2",
          date: "20/05/2024",
          changes: [
            "Added Essence. Essence is aquired by successfully attacking other players. Will be a powerful resource in future updates.",
          ],
        },
        {
          version: "1.3.0.1",
          date: "16/05/2024",
          changes: [
            "Increase Campfire Contribution Reward by 1 point per 100 wood contributed.",
            "Attacks are now capped at Tier 10.",
            "Guard Towers can be used to minimize infrastructure damage during enemy attacks costing 1 diamond to build.",
            "Fixed a visual bug with the gain point button not showing improved amounts once technology is researched.",
          ],
        },
        {
          version: "1.3.0",
          date: "24/04/2024",
          changes: ["Added music!"],
        },
        {
          version: "1.2.9",
          date: "21/03/2024",
          changes: [
            "Added expedition functionality.",
            "Introduced diverse character-specific stories for expeditions.",
            "Campfire victories now offer diamond rewards: higher chances for winners, with slim chances for second or third place.",
            "Updated top of page layout",
            "Decrease Campfire Decay Rate Slightly",
          ],
        },
        {
          version: "1.2.8",
          date: "01/03/2024",
          changes: [
            "Enhanced Point Gain Feature:",
            "Players can now boost their point accumulation by holding down the gain point button. (5 presses per second)",
            "Adjusted Production Rates:",
            "Fine-tuned the hourly run times for farms, stealthfarms, labs, and priests.",
          ],
        },
        {
          version: "1.2.7",
          date: "27/02/2024",
          changes: [
            "Fixed dark/light theme toggle not saving on refresh: The dark/light theme toggle now saves the user's preference on refresh.",
            "Improving Siphon Tower Stealing Logic: Enhance the logic behind Siphon Towers to improve the stealing mechanism.",
            "Stars on the Loading Screen: Added twinkling stars on the loading screen for a more immersive experience.",
            "Various Bug Fixes: Addressed several bugs to enhance overall game stability.",
          ],
        },
        {
          version: "1.2.6",
          date: "18/02/2024",
          changes: [
            "Added Siphon Towers: Harness the power of Siphon Towers to steal points from other players.",
            "Improved Readability: Formatted high numbers for better visibility and comprehension throughout the game interface.",
            "Reintroduced Spawn Overlord Button: The Spawn Overlord button makes a comeback, allowing players to summon powerful overlords.",
          ],
        },
        {
          version: "1.2.5",
          date: "09/02/2024",
          changes: [
            "Updated UI: Numbers are now displayed in a shortened format for better readability.",
            "Added a new random event to Divine Spell 1: Experience unexpected twists in the magical realm with the introduction of new and mysterious Divine Spell events.",
            "Added new technologies for improving the gain point button",
          ],
        },
        {
          version: "1.2.4",
          date: "04/02/2024",
          changes: [
            "Added Electricity and WoodPowers. Players can now convert wood to electricity using the new Wood Power Plant.",
            "Added Divine Spells Feature: Unleash the power of piety with the new divine spells feature. Players can now convert piety into magical spells for strategic advantages.",
          ],
        },
        {
          version: "1.2.3",
          date: "31/01/2024",
          changes: [
            "Added a toggle attack email notification feature: Players can now toggle email notifications for attacks in the settings modal",
          ],
        },
        {
          version: "1.2.2",
          date: "30/01/2024",
          changes: [
            "Implemented Scatter Plot Graph: A graph is now available to show everyone's scores on a scatter plot",
            "Increased Attack Effects: Successful attacks now deal 5% damage to each infrastructure (instead of 1%)",
            "Modified Recent Events: Events now only exist for the past 3 days (to save database space and lower egress)",
          ],
        },

        {
          version: "1.2.1",
          date: "28/01/2024",
          changes: [
            "Added Styles to Top 5 Players: The top 5 players in the leaderboard now have enhanced styles for better visibility",
            "Temporarily Removed Guard Towers and Overlords",
          ],
        },
        {
          version: "1.2.0",
          date: "25/01/2024",
          changes: [
            "Introduced Attack Feature: Players can now attack enemies using rock, paper, or scissors with underlings",
            "Increased Campfire Contribution Rewards Substantially",
            "Reduce Campfire Decay Rate Substantially",
          ],
        },
        {
          version: "1.1.9",
          date: "15/01/2024",
          changes: [
            "Implemented session tokens to automatically log out inactive users",
          ],
        },
        {
          version: "1.1.8",
          date: "13/01/2024",
          changes: [
            "Added 'Buying in Bulk' feature",
            "Introduced new technologies",
          ],
        },
        {
          version: "1.1.7",
          date: "11/01/2024",
          changes: [
            "Added 'Recent Events' button to view activities during absence",
            "Implemented theme preference memory based on the last app usage",
            "Expanded technology options",
          ],
        },
        {
          version: "1.1.6", // Added version 1.1.5 on 9/01/2024
          date: "9/01/2024", // Update date
          changes: ["Added Feedback Form", "Added Donation Button"],
        },
        {
          version: "1.1.5", // Added version 1.1.5 on 9/01/2024
          date: "8/01/2024", // Update date
          changes: [
            "Improved Rate Limiting",
            "Introduced Campfire Contribution Feature",
          ],
        },
        {
          version: "1.1.4", // Added version 1.1.4 on 4/01/2024
          date: "4/01/2024", // Update date
          changes: [
            "Enhanced Frontend Reactivity",
            "Improved Application Security",
            "Added Loading Screen",
          ],
        },
        {
          version: "1.1.3",
          date: "30/12/2023",
          changes: ["Added Dark Theme."],
        },
        {
          version: "1.1.2", // Added version 1.1.2 on 27/12/2023
          date: "28/12/2023", // Update date
          changes: [
            "Added researching technologies.",
            "Implemented measures against multiple IP usage for the same account.",
          ],
        },
        {
          version: "1.1.1", // Added version 1.1.1 on 26/12/2023
          date: "26/12/2023", // Update date
          changes: [
            "Implemented continuous leaderboard updates to provide the illusion of real-time changes.",
            "Points are now trickled into the leaderboard over 10-second intervals for a smoother experience.",
          ],
        },
        {
          version: "1.1.0",
          date: "25/12/2023",
          changes: [
            "Optimized backend execution with batch updates every 10 seconds for enhanced performance.",
          ],
        },
        {
          version: "1.0.9", // Added version 1.0.9 on 24/12/2023
          date: "24/12/2023", // Update date
          changes: [
            "Implemented rate limiting for enhanced security.",
            "Disabled access to the backend from unauthorized URLs.",
          ],
        },
        {
          version: "1.0.8", // Added version 1.0.8 on 23/12/2023
          date: "23/12/2023", // Update date
          changes: ["Optimized API calls for faster performance."],
        },
        {
          version: "1.0.7",
          date: "21/12/2023",
          changes: ["Improved application security substantially."],
        },
        {
          version: "1.0.6",
          date: "20/12/2023",
          changes: ["Introduced Delete Account feature for user convenience."],
        },
        {
          version: "1.0.5", // Added version 1.0.5 on 19/12/2023
          date: "19/12/2023", // Update date
          changes: [
            "Added Destroy Farm feature.",
            "Players can now destroy a target user's farm, costing 300 points.",
          ],
        },
        {
          version: "1.0.4", // Added version 1.0.4
          date: "16/12/2023", // Update date
          changes: [
            "Added Guard Towers feature, providing protection from thieves.",
          ],
        },
        {
          version: "1.0.3",
          date: "15/12/2023",
          changes: [
            "Added Stealth Farms feature, allowing players to generate points discreetly.",
            "Introduced the ability to convert piety into good fortune for strategic advantages.",
            "Provided additional information for each button in the game interface.",
          ],
        },
        {
          version: "1.0.2", // Update version number
          date: "14/12/2023", // Update date
          changes: [
            "Removed gambling feature for a more streamlined experience.",
            "Introduced Priests and Piety system.",
            "Improved overall game layout for enhanced user experience.",
          ],
        },
        {
          version: "1.0.1", // Update version number
          date: "13/12/2023", // Update date
          changes: [
            "Added farms feature.",
            "Made fetching data more efficient.",
          ],
        },
        {
          version: "1.0.0",
          date: "11/12/2023",
          changes: ["Added stealing points feature."],
        },
      ],
    };
  },
};
</script>

<style scoped>
.changelogs {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
  color: var(--primary-text);
  background-color: var(--primary-bg);
  min-height: 100vh;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-text);
  margin: 0;
}

.btn {
  padding: 0.5rem 1rem;
  background-color: var(--button-bg);
  color: var(--primary-text);
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: var(--button-hover-bg);
}

.changelog-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.changelog-item {
  background-color: var(--secondary-bg);
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  border: 1px solid var(--border-color2);
}

.changelog-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.changelog-header {
  background-color: var(--accent-bg);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changelog-header h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  color: var(--accent-text);
}

time {
  font-size: 0.8rem;
  color: var(--secondary-text);
}

.changes {
  list-style-type: none;
  padding: 1rem;
  margin: 0;
}

.changes li {
  position: relative;
  padding-left: 1.2rem;
  margin-bottom: 0.75rem;
  line-height: 1.4;
  color: var(--primary-text);
}

.changes li::before {
  content: "•";
  color: var(--accent-text);
  position: absolute;
  left: 0;
  font-weight: bold;
}

@media (max-width: 768px) {
  .changelogs {
    padding: 1rem;
  }

  header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .changelog-list {
    grid-template-columns: 1fr;
  }
}
</style>
