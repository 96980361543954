<template>
    <div
      v-if="visible"
      class="resource-tooltip"
    >
      {{ message }}
    </div>
  </template>
  
  <script>
  export default {
    props: {
      message: String,
      visible: Boolean,
    },
  };
  </script>
  
  <style scoped>

.resource-tooltip {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary-text);
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 34px;
  font-weight: 900;
  z-index: 400;
  background-color: var(--secondary-bg);
}
  </style>
  