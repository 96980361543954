<template>
    <div class="random-fact">{{ randomFact }}</div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        randomFact: '',
        facts: [
          "Merlin was a legendary wizard in Arthurian legend.",
          "Avalon is a mythical island where King Arthur's sword, Excalibur, was forged.",
          "King Arthur was a legendary British leader who, according to medieval histories and romances, led the defense of Britain against Saxon invaders.",
          "The Lady of the Lake is a sorceress in the Matter of Britain, the body of medieval literature and legend associated with King Arthur.",
          "Camelot is the castle and court associated with the legendary King Arthur.",
          "Excalibur is the legendary sword of King Arthur, sometimes attributed with magical powers or associated with the rightful sovereignty of Britain.",
          "Sir Lancelot was one of the Knights of the Round Table in the Arthurian legend.",
          "Merlin was said to have been born of a mortal woman, sired by an incubus, from whom he inherited his supernatural powers and abilities.",
          "The Round Table was created by Merlin to prevent quarrels among knights.",
          "Arthur was mortally wounded in the Battle of Camlann and was taken to Avalon to recover from his wounds.",
          "The medieval period, also known as the Middle Ages, lasted from the 5th to the late 15th century.",
          "Medieval knights followed a code of conduct known as chivalry, which emphasized bravery, honor, and respect for women and the weak.",
          "The Black Death, a devastating global epidemic of bubonic plague, struck Europe and Asia in the mid-1300s.",
          "Medieval castles were often built on hills or cliffs to make them easier to defend.",
          "The Magna Carta, signed in 1215, was a document that limited the power of the king and established certain legal protections for English citizens.",
          "Medieval monks played a crucial role in preserving knowledge by copying manuscripts by hand.",
          "The Viking Age (8th to 11th century) saw Norse explorers, traders, and warriors travel and settle across Europe, Asia, and North America.",
          "Joan of Arc, a peasant girl, led the French army to several important victories during the Hundred Years' War.",
          "The Crusades were a series of religious wars initiated by the Latin Church in the medieval period, most commonly associated with campaigns in the Eastern Mediterranean.",
          "Alchemy was a medieval precursor to chemistry, with the goal of transforming base metals into noble metals and discovering the elixir of life.",
          "Medieval tournaments were events where knights competed in jousting and other martial games to display their skills.",
          "The Bayeux Tapestry is an embroidered cloth that depicts the events leading up to the Norman conquest of England in 1066.",
          "The Hanseatic League was a medieval commercial and defensive confederation of merchant guilds and market towns in Northwestern and Central Europe.",
          "Gothic architecture, characterized by pointed arches, ribbed vaults, and flying buttresses, flourished during the High and Late Middle Ages.",
            "The War of the Roses was a series of civil wars fought in England between the House of Lancaster and the House of York.",
            "Buffy vill eis haben."
            
        ]
      };
    },
    mounted() {
      this.displayRandomFact();
    },
    methods: {
      displayRandomFact() {
        const randomIndex = Math.floor(Math.random() * this.facts.length);
        this.randomFact = this.facts[randomIndex];
      }
    },
  };
  </script>
  
  <style scoped>
  .random-fact {
    color: var(--primary-text);
    font-size:1rem;
    margin-top: 20px;
    margin-bottom:28px;
    text-align: center;
    padding: 10px 20px;
    border-radius: 10px;
    max-width: 80%;
    opacity:0.8;
    font-style: italic;
}
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>
