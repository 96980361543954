<template>
  <div
    class="disable-highlight disable-zoom-on-double-tap margin-0 padding-0 app-container"
  >
    <Auth
      @user-data="handleAuthEvent"
      @updateUser="updateUser"
      :user="user"
      :isDarkTheme="isDarkTheme"
      :onlineCount="onlineCount"
      @toggleShowUsersOnline="toggleShowUsersOnline"
      @toggleTheme="toggleTheme"
      @loaded="loadedTrue"
    />
    <Game
      :user="user"
      :leaderboard="leaderboard"
      @updateUser="updateUser"
      :isDarkTheme="isDarkTheme"
    />
    <div @fetch-user-data="handleAuthEvent">
      <button
        v-if="false && user"
        @click="toggleLeaderboard"
        class="show-leaderboard-btn"
      >
        {{ showLeaderboard ? "Hide Leaderboard" : "Show Leaderboard" }}
      </button>
      <Leaderboard
        :showLeaderboard="showLeaderboard"
        :user="user"
        @updateUser="updateUser"
        @updateLeaderboard="updateLeaderboard"
        @updateOnlineCount="updateOnlineCount"
        :isDarkTheme="isDarkTheme"
        :showUsersOnlineModal="showUsersOnlineModal"
        @toggleShowUsersOnline="toggleShowUsersOnline"
      />
    </div>

    <FeedbackForm
      v-if="user && user.displayName && user.google_id"
      :displayName="user.displayName"
      :googleId="user.google_id"
      :email="user.email"
    />

    <Donate v-if="user" />
    <div v-if="user" class="changelogbutton">
      <h2 @click="goToChangelogs">Read Changelogs</h2>
    </div>
    <Disclaimer v-if="user" />
  </div>

  <!-- <Puzzle v-if="user" /> -->
</template>
<script>
import { ref } from "vue";
import Game from "@/components/Game.vue";
import Auth from "@/components/Auth.vue";
import Leaderboard from "@/components/Leaderboard.vue";
import FeedbackForm from "@/components/FeedbackForm.vue";
import Donate from "@/components/Donate.vue";
import Disclaimer from "@/components/Disclaimer.vue";
import { onMounted } from "vue";
import { useRouter } from "vue-router";

export default {
  emits: ["loaded2"],
  components: {
    Game,
    Auth,
    Leaderboard,
    FeedbackForm,
    Donate,
    Disclaimer,
  },
  setup(props, { emit }) {
    const showUsersOnlineModal = ref(false);
    const toggleShowUsersOnline = () => {
      showUsersOnlineModal.value = !showUsersOnlineModal.value;
    };
    const isDarkTheme = ref(true);
    const toggleTheme = () => {
      isDarkTheme.value = !isDarkTheme.value;
      document.body.setAttribute(
        "data-theme",
        isDarkTheme.value ? "dark" : "light"
      );
    };

    const leaderboard = ref(null);
    const user = ref(null);
    const showLeaderboard = ref(true);

    const handleAuthEvent = (userDoc) => {
      user.value = userDoc;
      // console.log("handleAuthEvent", user.value);
    };

    const updateUser = (userDoc) => {
      Object.assign(user.value, userDoc);
      // console.log("updateUser", user.value);
    };

    const toggleLeaderboard = () => {
      showLeaderboard.value = !showLeaderboard.value;
    };

    const updateLeaderboard = (leaderboardDoc) => {
      leaderboard.value = leaderboardDoc;
    };

    const onlineCount = ref(0);

    const updateOnlineCount = (newCount) => {
      onlineCount.value = Number(newCount.ids?.length);
    };

    const loadedTrue = () => {
      emit("loaded2", true);
    };
    const $router = useRouter();
    const goToChangelogs = () => {
      $router.push("/changelogs");
    };

    onMounted(() => {
      document.body.setAttribute("data-theme", "dark");
    });

    return {
      toggleShowUsersOnline,
      showUsersOnlineModal,
      goToChangelogs,
      updateLeaderboard,
      leaderboard,
      toggleTheme,
      isDarkTheme,
      user,
      showLeaderboard,
      handleAuthEvent,
      updateUser,
      toggleLeaderboard,
      loadedTrue,
      updateOnlineCount,
      onlineCount,
    };
  },
};
</script>

<style>
.changelogbutton {
  text-align: start;
  padding: 20px;
  max-width: 600px;
  margin: 10px auto;
  font-weight: 100;
  background-color: var(--primary-bg);
}

.changelogbutton a {
  text-decoration: none;
  color: var(--primary-text);
  display: inline-block; /* Ensure link doesn't expand to full width */
  max-width: 300px; /* Limit the width of the link */
  margin: 0 auto; /* Center the link horizontally */
}

.changelogbutton h2 {
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--primary-text);
  margin: 22px 0;
  width: 100%;
  text-align: center;
}

.changelogbutton h2:hover {
  color: var(--secondary-text);
}
.app-container {
  background-color: var(--third-bg);
}

body[data-theme="light"] {
  --primary-bg: #ffffff;
  --secondary-bg: #f5f5f5;
  --third-bg: #e8e8e8;

  --primary-text: #333333;
  --secondary-text: #555555;

  --button-bg: #4a90e2;
  --button-hover-bg: #3a7bc8;
  --border-color: #d0d0d0;
  --border-color2: #e0e0e0;

  --accent-bg: #f0f7ff;
  --accent-text: #2c3e50;
}

body[data-theme="dark"] {
  --primary-bg: #1a1a1a;
  --secondary-bg: #2c2c2c;
  --third-bg: #383838;

  --primary-text: #ffffff;
  --secondary-text: #b0b0b0;

  --button-bg: #4a90e2;
  --button-hover-bg: #3a7bc8;
  --border-color: #444444;
  --border-color2: #555555;

  --accent-bg: #2c3e50;
  --accent-text: #ecf0f1;
}

body {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  transition: background-color 0.45s ease-out, color 0.45s ease-out;
}

.text-align-right {
  text-align: right;
}
</style>
