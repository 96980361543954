<template>
  <div class="disclaimer">
    <p>
      Please note that our servers are hosted in Australia, which may result in slight delays in response times.
      We are currently in beta with only one server in production.
      We may consider adding an additional server in the future if our player base grows or we receive significant donations.
    </p>
  </div>
</template>

<script>
export default {
  name: "Disclaimer",
};
</script>

<style scoped>
.disclaimer {
  background-color: var(--primary-bg);
  color: var(--secondary-text);
  max-width: 500px;
  text-align: justify;
  padding: 20px 70px;

  margin-left:auto;
  margin-right:auto;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.disclaimer p {
  margin: 5px 0;
  font-size: 13px;
  opacity: 0.94;
  line-height: 1.4;
}

.disclaimer p:last-child {
  margin-bottom: 0;
}
</style>


