<template>
  <div v-if="!isAuthenticated" class="landing-container">
    <div class="version-num">v{{ verNum }}</div>
    <div class="background-image"></div>
    <div class="overlay"></div>

    <div class="info-container">
      <h1 class="game-title">
        <img
          src="../../public/img/icons/android-chrome-512x512-white.png"
          alt="Ranked Rumble Logo"
          class="logo"
        />
        Ranked Rumble
      </h1>
      <p class="tagline">Join the ultimate battle for supremacy!</p>
      <p class="beta-notice">Beta Version - Your Feedback is Welcome!</p>
      <button class="google-sign-in-button" @click="signInWithGoogle">
        <img
          src="https://www.svgrepo.com/show/475656/google-color.svg"
          alt="Google logo"
          class="google-logo"
        />
        <span>Login with Google</span>
      </button>
    </div>
  </div>

  <div v-if="isAuthenticated && user" class="auth-container">
    <div class="header">
      <div class="left-section">
        <button @click="emitToggleTheme" class="icon-button">
          {{ isDarkTheme ? "☀️" : "🌙" }}
        </button>
      </div>
      <div class="center-section">
        <h2 class="title">Ranked Rumble</h2>
        <span class="ver-num">v{{ verNum }}</span>
      </div>
      <div class="right-section">
        <button @click="togglePlayer" class="icon-button">🎵</button>
      </div>
    </div>

    <div @click="emitToggleShowUsersOnline" class="users-online-span">
      👥 {{ onlineCount }} {{ onlineCount > 1 ? "users" : "user" }} online
    </div>

    <div v-if="playerVisible" class="audio-controls" @click.stop>
      <div class="song-title">{{ songTitle }}</div>
      <div class="audio-buttons">
        <button @click="toggleAudio" class="audio-button">
          <span v-if="audioPlaying">⏸</span>
          <span v-else>▶️</span>
        </button>
        <button @click="stopAudio" class="audio-button">⏹</button>
        <button @click="skipAudio" class="audio-button">⏭</button>
      </div>

      <div class="audio-sliders">
        <label for="progress-control">Duration</label>
        <input
          id="progress-control"
          type="range"
          min="0"
          :max="audioDuration"
          step="0.00001"
          v-model="audioCurrentTime"
          @input="seekAudio"
          class="progress-control"
        />

        <label for="volume-control">Volume</label>
        <input
          id="volume-control"
          type="range"
          min="0"
          max="1"
          step="0.05"
          v-model="audioVolume"
          @input="adjustVolume"
          class="volume-control"
        />
      </div>

      <select
        v-model="currentPlaylist"
        @change="changePlaylist"
        class="playlist-dropdown"
      >
        <option value="classical">Classical</option>
        <option value="goofy">Modern</option>
      </select>
      <button @click="togglePlayer" class="close-button">✖️</button>
    </div>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  watch,
  beforeUnmount,
} from "vue";

import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut as signOutFirebase,
} from "firebase/auth";
import { isAuthenticated, setAuthenticated } from "../authStore"; // Update the path to your store
import { auth } from "@/main";

export default {
  emits: [
    "user-data",
    "updateUser",
    "toggleTheme",
    "loaded",
    "toggleShowUsersOnline",
  ],
  props: {
    user: Object,
    isDarkTheme: {
      type: Boolean,
      default: true,
    },
    onlineCount: Number,
  },
  setup(props, { emit }) {
    watch(
      () => props.user?.dark,
      (newValue, oldValue) => {
        if (newValue !== props.isDarkTheme) {
          emitToggleTheme();
        }
      }
    );
    const verNum = ref("1.3.7");
    const audioPlaying = ref(false);
    const playerVisible = ref(false);
    const audio = new Audio();
    const audioVolume = ref(0.35);
    const audioCurrentTime = ref(0);
    const audioDuration = ref(0);
    const songTitle = ref("");
    const firstTimeOpen = ref(true);

    const playlists = {
      classical: [
        "main-audio-classical0-Hero's Journey.mp3",
        "main-audio-classical1-Peril and Wonder.mp3",
        "main-audio-classical2-Safe Haven.mp3",
      ],
      goofy: [
        "main-audio-modern0-Space Tune.mp3",
        "main-audio-modern1-Flute.mp3",
        "main-audio-modern2-Ocean Song.mp3",
        "main-audio-modern3-Daniel's Song.mp3",
      ],
    };

    // Shuffle both playlists.
    playlists.classical.sort(() => Math.random() - 0.5);
    playlists.goofy.sort(() => Math.random() - 0.5);

    const currentPlaylist = ref("classical");
    let remainingSongs = [...playlists[currentPlaylist.value]];

    const extractSongTitle = (fileName) => {
      const parts = fileName.split("-");
      return parts.length > 1
        ? parts[parts.length - 1].replace(".mp3", "")
        : fileName.replace(".mp3", "");
    };

    const startAudio = () => {
      if (remainingSongs.length === 0) {
        remainingSongs = [...playlists[currentPlaylist.value]];
      }

      const nextTrack = remainingSongs.shift();
      songTitle.value = extractSongTitle(nextTrack);
      audio.src = require(`@/assets/audio/${nextTrack}`);
      audio.loop = false;
      audio.autoplay = true;
      audio.volume = audioVolume.value;

      audio.onended = () => {
        startAudio();
      };

      audio.ontimeupdate = () => {
        audioCurrentTime.value = audio.currentTime;
        audioDuration.value = audio.duration;
      };

      audio.play();
      audioPlaying.value = true;
    };

    const toggleAudio = () => {
      if (audioPlaying.value) {
        audio.pause();
      } else {
        audio.play();
      }
      audioPlaying.value = !audioPlaying.value;
    };

    const stopAudio = () => {
      audio.pause();
      audio.currentTime = 0;
      audioPlaying.value = false;
    };

    const skipAudio = () => {
      audio.pause();
      startAudio();
    };

    const adjustVolume = () => {
      audio.volume = audioVolume.value;
    };

    const seekAudio = () => {
      audio.currentTime = audioCurrentTime.value;
    };

    const changePlaylist = () => {
      remainingSongs = [...playlists[currentPlaylist.value]];
      stopAudio();
      startAudio();
    };

    const togglePlayer = () => {
      playerVisible.value = !playerVisible.value;
      if (playerVisible.value && firstTimeOpen.value) {
        firstTimeOpen.value = false;
        startAudio();
      }
    };

    document.addEventListener("click", (event) => {
      if (
        playerVisible.value &&
        !event.target.closest(".audio-controls") &&
        !event.target.closest(".icon-button")
      ) {
        playerVisible.value = false;
      }
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && playerVisible.value) {
        playerVisible.value = false;
      }
    });

    const emitToggleShowUsersOnline = () => {
      emit("toggleShowUsersOnline");
    };

    const emitToggleTheme = () => {
      emit("toggleTheme");
    };
    let backendUrl =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_API_URL
        : "http://localhost:3003";

    // const isAuthenticated = ref(false);
    const createUserData = async (userData) => {
      try {
        // Replace with your server URL

        const response = await fetch(`${backendUrl}/createUserData`, {
          // credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // // credentials: "include",
          body: JSON.stringify(userData),
        });

        const data = await response.json();

        if (response.ok) {
          // Assuming the session token is in the response as "sessionToken"
          const sessionToken = data.sessionToken;

          // Store the session token in session storage
          localStorage.setItem("sessionToken", sessionToken);

          console.log(data.message);
          return data;
        } else {
          console.error("Error creating user data:", data.message);
          throw new Error(data.message);
        }
      } catch (error) {
        console.error("Error creating user data:", error.message);
        throw error;
      }
    };

    const updateUserDataOnLogin = async (userData) => {
      try {
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }
        const idToken = await user.getIdToken();
        // Replace with your server URL

        const response = await fetch(`${backendUrl}/updateUserDataOnLogin`, {
          // credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken, // Add this line to include the ID token in the headers
          },
          // // credentials: "include",
          body: JSON.stringify(userData),
        });

        const data = await response.json();

        if (response.ok) {
          console.log(data.message);

          // Assuming the session token is in the response as "sessionToken"
          const sessionToken = data.sessionToken;

          // Store the session token in session storage
          localStorage.setItem("sessionToken", sessionToken);

          return data;
        } else {
          console.error("Error updating user data:", data.message);
          throw new Error(data.message);
        }
      } catch (error) {
        console.error("Error updating user data:", error.message);
        throw error;
      }
    };

    const signInWithGoogle = async () => {
      const provider = new GoogleAuthProvider();

      try {
        const result = await signInWithPopup(auth, provider);
        const { user } = result;

        if (user) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      } catch (error) {
        console.error("Google Sign-In Error:", error);

        // Log more details about the error
        if (error.code) {
          console.error("Error Code:", error.code);
        }
        if (error.message) {
          console.error("Error Message:", error.message);
        }
        if (error.email) {
          console.error("Email:", error.email);
        }
        if (error.credential) {
          console.error("Credential:", error.credential);
        }
      }
    };

    const updateUserDarkTheme = async () => {
      if (props.user && props.user.dark !== undefined) {
        console.log("Updating user dark theme...");

        const localIsDarkTheme = props.isDarkTheme;
        if (
          localIsDarkTheme === undefined ||
          localIsDarkTheme === null ||
          localIsDarkTheme === props.user.dark
        ) {
          return;
        }

        try {
          const user = auth.currentUser;

          if (!user) {
            console.error("User not authenticated");
            return;
          }

          if (props.user?.dark !== localIsDarkTheme) {
            const idToken = await user.getIdToken();

            const response = await fetch(`${backendUrl}/updateUserDarkTheme`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
              },
              body: JSON.stringify({
                uid: props.user.google_id,
                dark: localIsDarkTheme,
              }),
            });

            if (!response.ok) {
              console.error(
                "Error updating user dark theme:",
                response.statusText
              );
              // Handle error as needed
            } else {
              console.log("User dark theme updated successfully.");
            }
          }
        } catch (error) {
          console.error("Error updating user dark theme:", error.message);
          // Handle error as needed
        }
      }
    };

    const userGoogleId = ref(null);
    const userName = ref(null);

    const hideSignInButton = ref(false);

    const handleAuthStateChange = async (user) => {
      try {
        if (user) {
          if (!user) {
            console.error("User not authenticated");
            return;
          }
          const idToken = await user.getIdToken();
          const response = await fetch(
            `${backendUrl}/getUserData?uid=${user.uid}`,
            {
              // credentials: "include",
              method: "GET",
              headers: {
                Authorization: idToken,
              },
            }
          );

          // Cache the user's ID in the Cache API
          caches.open("user-cache").then((cache) => {
            cache.put("user-object", new Response(user));
          });

          let userData = null;
          let result = await response.json();
          if (result.success) {
            userData = result.data;
          }
          if (userData) {
            hideSignInButton.value = true;
            console.log("User document exists in database");
            const uid = user.uid;
            await updateUserDataOnLogin({ uid });
            emit("user-data", { ...userData, google_id: user.uid });
          } else {
            console.log("User document does not exist in database");
            emit("user-data", null);
            const { uid, displayName, email } = user;
            await createUserData({
              uid,
              displayName,
              email,
            });
            const newUserResponse = await fetch(
              `${backendUrl}/getUserData?uid=${user.uid}`,
              {
                // credentials: "include",
                method: "GET",
                headers: {
                  Authorization: idToken,
                },
              }
            );
            const newUserData = await newUserResponse.json();
            emit("user-data", { ...newUserData.data, google_id: user.uid });
          }
          emit("loaded", true);

          setAuthenticated(true);
        } else {
          // User is signed out
          emit("loaded", true);

          setAuthenticated(false);
        }
      } catch (error) {
        console.error("Error handling auth state change:", error);
      }
    };

    onMounted(async () => {
      window.addEventListener("beforeunload", updateUserDarkTheme);
      // await fetchCsrfToken();
      // setInterval(updateUserDarkTheme, 30000);

      const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);

      return () => unsubscribe();
    });

    onUnmounted(() => {});

    return {
      verNum,
      emitToggleShowUsersOnline,
      emitToggleTheme,
      signInWithGoogle,
      userGoogleId,
      userName,
      isAuthenticated,
      hideSignInButton,
      audioPlaying,
      playerVisible,
      audioVolume,
      audioCurrentTime,
      audioDuration,
      currentPlaylist,
      songTitle,
      toggleAudio,
      stopAudio,
      skipAudio,
      adjustVolume,
      seekAudio,
      changePlaylist,
      togglePlayer,
    };
  },
};
</script>

<style scoped>
.auth-container {
  padding: 0.5rem;
  background-color: var(--primary-bg);
  z-index: 10;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--secondary-bg);
  border-radius: 8px;
  margin-bottom: 0.5rem;
  z-index: 11;
}

.left-section,
.right-section {
  flex: 1;
  display: flex;
  align-items: center;
}

.left-section {
  justify-content: flex-start;
}

.right-section {
  justify-content: flex-end;
}

.center-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--primary-text);
  margin: 0;
}

.ver-num {
  font-size: 0.6rem;
  color: var(--secondary-text);
}

.icon-button {
  font-size: 1.2rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-text);
  padding: 0.25rem;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.icon-button:hover {
  background-color: var(--button-hover-bg);
}

.users-online-span {
  font-size: 0.9rem;
  color: var(--secondary-text);
  cursor: pointer;
  text-align: center;
  padding: 0.25rem;
  background-color: var(--secondary-bg);
  border-radius: 8px;
  z-index: 11;
}

.users-online-span:hover {
  background-color: var(--button-hover-bg);
}

.audio-controls {
  background-color: var(--secondary-bg);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 12;
}

.song-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--primary-text);
  text-align: center;
}

.audio-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.audio-button {
  background-color: var(--button-bg);
  border: none;
  color: var(--primary-text);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.audio-button:hover {
  background-color: var(--button-hover-bg);
}

.audio-sliders {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.progress-control,
.volume-control {
  width: 100%;
  margin: 0.5rem 0;
}

.playlist-dropdown {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--button-bg);
  color: var(--primary-text);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button {
  background-color: var(--button-bg);
  border: none;
  color: var(--primary-text);
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
  display: block;
  margin: 0 auto;
}

.close-button:hover {
  background-color: var(--button-hover-bg);
}

/* Existing styles for the landing page */
.landing-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 1;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/background.png") no-repeat center center/cover;
  filter: blur(3px) brightness(0.6);
  z-index: -2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: -1;
}

.info-container {
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  width: 90%;
}

.game-title {
  font-size: 3rem;
  color: #f39c12;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

.tagline {
  font-size: 1.5rem;
  color: #ecf0f1;
  margin-bottom: 1rem;
}

.beta-notice {
  font-size: 0.9rem;
  color: #bdc3c7;
  margin-bottom: 2rem;
}

.google-sign-in-button {
  background: #4285f4;
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, transform 0.3s ease;
  margin: 0 auto;
}

.google-sign-in-button:hover {
  background: #357ae8;
  transform: translateY(-2px);
}

.google-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.version-num {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 0.8rem;
  color: #ecf0f1;
  opacity: 0.7;
  z-index: 2;
}

@media (max-width: 768px) {
  .icon-button {
    margin-bottom: 0.5rem;
  }

  .title {
    margin-bottom: 0.5rem;
  }
}

.version-num {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 0.8rem;
  color: #ecf0f1;
  opacity: 0.7;
  z-index: 2;
}

@media (max-width: 768px) {
  .icon-button {
    margin-bottom: 0.5rem;
  }

  .title {
    margin-bottom: 0.5rem;
  }
}
</style>
