<template>
  <!-- make show users online modal -->

  <div
    v-if="showUsersOnlineModal"
    class="users-online-modal"
    @click="closeShowOnlineUsers"
  >
    <div class="users-online-modal-content" @click.stop>
      <button class="close-button" @click="closeShowOnlineUsers">×</button>
      <h2>Users Online</h2>
      <ul class="users-list">
        <li v-for="(user, index) in onlineUsers" :key="index" class="user-item">
          <img
            :src="`/img/avatars/${user?.avatar || 'chick'}.jpg`"
            alt="player avatar"
            class="avatarr"
          />
          <span class="display-name">{{ user?.displayName }}</span>
        </li>
      </ul>
    </div>
  </div>

  <div class="notification-container">
    <transition-group name="notification-fade">
      <div
        v-for="(event, index) in displayedEvents"
        :key="index"
        class="notification-popup"
        :class="{
          'fade-out': event.fadingOut,
          'fade-out-fast': event.fadingOutFast,
        }"
        @click="startFadeOutFast(event)"
      >
        {{ event.message?.name }} {{ event.message?.type }}
      </div>
    </transition-group>
  </div>

  <CustomAlertModal :isVisible="alertModalIsVisible" />
  <div
    v-if="show"
    :class="{ notification: true, positive: isPositive, negative: !isPositive }"
  >
    {{ message }}
  </div>
  <div v-if="showLeaderboard && loadingLeaderboard && user">
    Loading leaderboard...
  </div>
  <div
    v-else-if="showLeaderboard && user && leaderboard?.length > 0"
    class="leaderboard-container"
  >
    <ProfileModal
      :key="user.id"
      :user="selectedUserProfile"
      :currentUser="user"
      :currentUserID="user.id"
      @close="closeProfileModal"
      v-if="isProfileModalVisible"
    />

    <Campfire
      v-if="showLeaderboard"
      :campfireLeaderboard="campfireLeaderboard"
      :google_id="user?.google_id"
      @updateProfileModal="openProfileModalWithId"
    />
    <h1 @click="changeMode" class="leaderboard-title">
      Leaderboard {{ modeLabels[mode] }}
    </h1>
    <ul class="leaderboard-list" :class="{ 'one-column': mode === 2 }">
      <li
        v-if="mode !== 2"
        class="pointer"
        v-for="(player, index) in sortedLeaderboard"
        :key="index"
        :class="{
          'leaderboard-item': true,
          'current-user': isCurrentUser(player),
          gold: index === 0,
          silver: index === 1,
          bronze: index === 2,
          leech: player.leech > 0,
        }"
        @click="openProfileModal(player)"
      >
        <span v-if="index === 0" class="position">🤴</span>

        <span v-else class="position">{{ index + 1 }}.</span>

        <img
          :src="`/img/avatars/${player.avatar || 'chick'}.jpg`"
          alt="player avatar"
          class="avatar"
        />

        <span class="player-info">
          <span class="name">{{ formatDisplayName(player.displayName) }}</span>
          {{ renderModeContent(player) }}
        </span>
      </li>
      <div v-else-if="mode === 2" class="width100">
        <div class="scatter-plot">
          <!-- <div class="reference-label" :style="{ bottom: '0%' }">0</div> -->

          <div
            v-for="(player, index) in sortedLeaderboard"
            :key="player.id"
            :class="{ you: isYou(player), dot: true }"
            :style="getDotStyles(player, index)"
            @mouseover="showPlayerScore(player, index)"
            @mouseout="hidePlayerScore"
          ></div>
          <div
            v-if="showScoreLabel"
            class="player-score-label"
            :style="{
              bottom: labelPosition.bottom,
              left: `calc(${labelPosition.left} + 2%)`,
            }"
          >
            {{ currentPlayerInfo }}
          </div>

          <!-- <div class="reference-label" :style="{ bottom: '12.5%' }">
            {{ formattedPointsOrScore(sortedLeaderboard[0].score * 0.125) }}
          </div>
          <div class="reference-label" :style="{ bottom: '25%' }">
            {{ formattedPointsOrScore(sortedLeaderboard[0].score * 0.25) }}
          </div>
          <div class="reference-label" :style="{ bottom: '37.5%' }">
            {{ formattedPointsOrScore(sortedLeaderboard[0].score * 0.375) }}
          </div>
          <div class="reference-label" :style="{ bottom: '50%' }">
            {{ formattedPointsOrScore(sortedLeaderboard[0].score / 2) }}
          </div>
          <div class="reference-label" :style="{ bottom: '62.5%' }">
            {{ formattedPointsOrScore(sortedLeaderboard[0].score * 0.625) }}
          </div>
          <div class="reference-label" :style="{ bottom: '75%' }">
            {{ formattedPointsOrScore(sortedLeaderboard[0].score * 0.75) }}
          </div>
          <div class="reference-label" :style="{ bottom: '87.5%' }">
            {{ formattedPointsOrScore(sortedLeaderboard[0].score * 0.875) }}
          </div>
          <div class="reference-label" :style="{ bottom: '100%' }">
            {{ formattedPointsOrScore(sortedLeaderboard[0].score) }}
          </div> -->
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import ProfileModal from "@/components/ProfileModal.vue";
import Campfire from "@/components/Campfire.vue";
import CustomAlertModal from "@/components/CustomAlertModal.vue";

import {
  ref,
  watch,
  onUnmounted,
  computed,
  reactive,
  watchEffect,
  onMounted,
  onBeforeUnmount,
} from "vue";

export default {
  emits: [
    "updateUser",
    "updateLeaderboard",
    "updateOnlineCount",
    "toggleShowUsersOnline",
  ],
  props: {
    user: Object,

    duration: {
      type: Number,
      default: 3500,
    },
    isDarkTheme: {
      type: Boolean,
      default: true,
    },
    showLeaderboard: {
      type: Boolean,
      default: true,
    },
    showUsersOnlineModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProfileModal,
    Campfire,
    CustomAlertModal,
    CustomAlertModal,
  },
  setup(props, { emit }) {
    const onlineIds = ref([]);

    const alertModalIsVisible = ref(false);
    try {
      let backendUrl =
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_API_URL
          : "http://localhost:3003";

      // const stealPoints = async (targetUserId) => {
      //   try {
      //     const user = auth.currentUser;
      //     // Assuming you have the user's authentication status and UID
      //     const idToken = await user.getIdToken();

      //     // Replace "YOUR_BACKEND_URL" with the actual URL where your backend is hosted

      //     // Make the request to your backend
      //     const response = await fetch(`${backendUrl}/stealPoints`, {
      //       method: "POST",
      //       headers: {
      //         Authorization: idToken,
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify({ targetUserId, uid: props.user.google_id }),
      //     });

      //     if (response.ok) {
      //       const result = await response.json();
      //       updateProfileModalValues();
      //       if (result) {
      //         displayNotification(result.message, result.success);
      //       } else {
      //         displayNotification(result, false);
      //       }
      //       console.log(result);
      //     } else {
      //       const result = await response.json();
      //       if (result) {
      //         displayNotification(result.message, result.success);
      //       } else {
      //         displayNotification(response);
      //       }
      //     }
      //   } catch (error) {
      //     console.error("Fetch error:", error);
      //   }
      // };

      // const destroyFarm = async (targetUserId) => {
      //   try {
      //     const user = auth.currentUser;
      //     // Assuming you have the user's authentication status and UID
      //     const idToken = await user.getIdToken();

      //     // Replace "YOUR_BACKEND_URL" with the actual URL where your backend is hosted

      //     // Make the request to your backend
      //     const response = await fetch(`${backendUrl}/destroyFarm`, {
      //       method: "POST",
      //       headers: {
      //         Authorization: idToken,
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify({ targetUserId, uid: props.user.google_id }),
      //     });

      //     if (response.ok) {
      //       const result = await response.json();
      //       updateProfileModalValues();
      //       if (result) {
      //         displayNotification(result.message, result.success);
      //       } else {
      //         displayNotification(result, false);
      //       }
      //       console.log(result);
      //     } else {
      //       const result = await response.json();
      //       if (result) {
      //         displayNotification(result.message, result.success);
      //       } else {
      //         displayNotification(response);
      //       }
      //     }
      //   } catch (error) {
      //     console.error("Fetch error:", error);
      //   }
      // };
      const closeShowOnlineUsers = () => {
        emit("toggleShowUsersOnline");
      };

      const updateProfileModalValues = () => {
        selectedUserProfile.value = leaderboard.value.find(
          (player) => player.google_id === selectedUserProfile.value.google_id
        );
      };

      const openProfileModalWithId = (userid) => {
        selectedUserProfile.value = leaderboard.value.find(
          (player) => player.google_id === userid
        );
        isProfileModalVisible.value = true;
      };
      const socket = ref(null);

      const userScores = reactive(new Map());

      // Function to calculate and update a user's score
      const updateUserScore = (user) => {
        const totalPoints =
          user.farms * 100 +
          user.priests * 250 +
          user.guardTowers * 500 +
          user.laboratories * 400 +
          user.woodpowers * 1000 +
          user.siphonTowers * 10000 +
          user.points;

        userScores.set(user.id, totalPoints);
      };

      const formattedPointsOrScore = (points) => {
        const suffixes = ["", "k", "m", "b", "t", "q", "Q", "s", "S", "o"];

        if (points < 10000) {
          return points.toString();
        }

        let suffixIndex = 0;

        while (points >= 1000 && suffixIndex < suffixes.length - 1) {
          points /= 1000;
          suffixIndex++;
        }

        let formattedPoints =
          points % 1 !== 0 ? points.toFixed(1) : Math.floor(points);

        // Remove '.0' if present
        formattedPoints = formattedPoints.toString().replace(/\.0$/, "");

        return formattedPoints + suffixes[suffixIndex];
      };

      const mode = ref(0);

      const show = ref(false);
      const loadingLeaderboard = ref(true);
      const leaderboard = ref([]);
      const onlineUsers = computed(() => {
        if (leaderboard.value.length > 0) {
          return onlineIds.value.map((id) => {
            const user = leaderboard.value.find(
              (player) => player.google_id === id
            );
            if (user && user.displayName && user.avatar) {
              return user;
            } else {
              return {
                displayName: "Unknown",
                avatar: "chick",
              };
            }
          });
        } else {
          return [];
        }
      });
      const campfireLeaderboard = ref([]);
      const message = ref("");
      const isPositive = ref(true);

      const isProfileModalVisible = ref(false);
      const selectedUserProfile = ref(null);
      const openProfileModal = (player) => {
        selectedUserProfile.value = player;
        isProfileModalVisible.value = true;
      };
      const closeProfileModal = () => {
        isProfileModalVisible.value = false;
        selectedUserProfile.value = null;
      };

      const hideNotification = () => {
        show.value = false;
      };

      const displayNotification = (newMessage, positive = true) => {
        message.value = newMessage;
        isPositive.value = positive;
        show.value = true;

        // Clear the previous timeout
        if (hideNotification.timeout) {
          clearTimeout(hideNotification.timeout);
        }

        // Set a new timeout
        hideNotification.timeout = setTimeout(() => {
          hideNotification();
        }, props.duration);
      };

      const debounceDelay = 1200; // Set the debounce delay in milliseconds
      let debounceTimer = null;
      let isEmitPending = false;

      // Update the updateLeaderboard function to include priests and piety
      const updateLeaderboard = (data) => {
        const id = data.id;

        const existingPlayerIndex = leaderboard.value.findIndex(
          (player) => player.id === id
        );

        if (existingPlayerIndex !== -1) {
          // If the player already exists in the leaderboard, update their data
          const existingPlayer = leaderboard.value[existingPlayerIndex];
          // Update fields in the existing player object based on 'data'
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              existingPlayer[key] = data[key];
            }
          }
          updateUserScore(existingPlayer);

          if (data.id === props.user.id) {
            if (isEmitPending) {
              clearTimeout(debounceTimer);
            }

            isEmitPending = true;

            // Set a new timeout for the debounce delay
            debounceTimer = setTimeout(() => {
              // Emit the current user's data after the debounce delay
              emit("updateUser", data);
              isEmitPending = false; // Reset the flag after emitting
            }, debounceDelay);
          }
          if (
            selectedUserProfile.value &&
            selectedUserProfile.value.id === id
          ) {
            selectedUserProfile.value = data;
          }
        } else {
          // If the player is not in the leaderboard, add them
          leaderboard.value.push(data);
          updateUserScore(data);
        }
        // Sort the leaderboard
      };

      const modeLabels = computed(() => {
        return ["Scores", "Points", "Graph"];
      });

      const sortedLeaderboard = computed(() => {
        return Array.from(leaderboard.value)
          .map((user) => ({ ...user, score: userScores.get(user.id) || 0 }))
          .filter((user) => user.score > 0 || user.points > 0) // Filter users with score > 0 or points > 0
          .sort((a, b) => b.score - a.score);
      });
      // watch changes in sorted leaderboard

      watch(
        () => sortedLeaderboard.value,
        (newLeaderboard) => {
          emit("updateLeaderboard", newLeaderboard);
        }
      );

      const showScoreLabel = ref(false);
      const currentPlayerInfo = ref("");
      const labelPosition = ref({ bottom: "0%", left: "0%" });

      const showPlayerScore = (player, index) => {
        if (player && "score" in player) {
          showScoreLabel.value = true;
          currentPlayerInfo.value = `${
            player.displayName
          } - ${formattedPointsOrScore(player.score)}`;
          // Calculate the position based on the player's index
          labelPosition.value = calculateDotPosition(player, index);
        }
      };

      const hidePlayerScore = () => {
        showScoreLabel.value = false;
      };
      const formatDisplayName = (fullName) => {
        if (!fullName) return fullName;

        const names = fullName.split(" ");
        const firstName = names[0];

        // // Check if lastNameInitial is defined and not an empty string
        // const lastNameInitial =
        //   names.length > 1 ? ` ${names[names.length - 1].charAt(0)}` : "";

        // // Filter out empty spaces and handle cases with no last name
        // const modifiedDisplayName = `${firstName}${lastNameInitial}`;

        return firstName;
      };

      const isCurrentUser = (player) => {
        return player.id === props.user.id;
      };

      async function fetchLeaderboard() {
        try {
          const sessionToken = localStorage.getItem("sessionToken");

          if (!sessionToken) {
            // Handle the case when the session token is not available
            console.error("Session token not available");
            return;
          }
          const response = await fetch(`${backendUrl}/getLeaderboard`, {
            headers: {
              sessionToken,
            },
            // credentials: "include",
          });

          if (!response.ok) {
            throw new Error(
              `Failed to fetch leaderboard. Status: ${response.status}`
            );
          }

          const fetchedUsers = await response.json();

          if (!fetchedUsers) {
            console.warn("Empty response received. Retrying in 1 second...");
            setTimeout(() => {
              fetchLeaderboard();
            }, 1000);
            return;
          }

          // Add new users to the leaderboard array if they don't already exist
          fetchedUsers.forEach((user) => {
            const existingUser = leaderboard.value.find(
              (u) => u.id === user.id
            );
            if (!existingUser) {
              leaderboard.value.push(user);
            }
            updateUserScore(user);
          });

          // updateLeaderboard(leaderboard.value);
          loadingLeaderboard.value = false;
        } catch (error) {
          console.error("Error fetching leaderboard:", error.message);
          // You may want to add additional error handling or logging here.
        }
      }

      const changeMode = () => {
        mode.value = (mode.value + 1) % 3; // Cycle through 0, 1, 2
      };

      const alphabeticalSortedLeaderboard = computed(() => {
        return leaderboard.value.sort((a, b) =>
          a.displayName.localeCompare(b.displayName)
        );
      });

      const isYou = (player) => player.google_id === props.user.google_id;
      const calculateDotPosition = (player, index) => {
        const highestScore = Math.log(findHighestScore());

        // Calculate evenly spaced left percentage based on alphabetical order
        const totalPlayers = alphabeticalSortedLeaderboard.value.length;
        const alphabeticalIndex = alphabeticalSortedLeaderboard.value.findIndex(
          (p) => p.google_id === player.google_id
        );

        const leftPercentage = (alphabeticalIndex / (totalPlayers - 1)) * 100;

        const randomLeft = isYou(player) ? 50 : leftPercentage;

        const percentage = (Math.log(player.score) / highestScore) * 100;
        const adjustedBottom = percentage - 2; // Adjust this value to move the dot down

        return {
          bottom: `${adjustedBottom}%`,
          left: `${randomLeft}%`,
        };
      };

      const findHighestScore = () => {
        return Math.max(
          ...sortedLeaderboard.value.map((player) => player.score)
        );
      };

      const renderModeContent = (player) => {
        switch (mode.value) {
          case 1:
            return formattedPointsOrScore(player.points);
          case 0:
            return formattedPointsOrScore(player.score);
        }
      };

      const getDotStyles = (player, index) => {
        return {
          ...calculateDotPosition(player, index),
          ...getDotStyle(player.avatar),
        };
      };

      const getDotStyle = (avatar) => {
        return {
          backgroundImage: `url(/img/avatars/${avatar}.jpg)`,
        };
      };

      let keepAliveInterval;
      const KEEP_ALIVE_INTERVAL_MS = 90000; // Adjust this interval based on server timeout settings

      const startKeepAlive = () => {
        keepAliveInterval = setInterval(() => {
          if (socket.value && socket.value.readyState === WebSocket.OPEN) {
            socket.value.send(JSON.stringify({ type: "keep-alive" }));
            console.log("Sent keep-alive message to the server");
          }
        }, KEEP_ALIVE_INTERVAL_MS);
      };

      async function fetchCampfireLeaderboard() {
        try {
          const sessionToken = localStorage.getItem("sessionToken");

          if (!sessionToken) {
            // Handle the case when the session token is not available
            console.error("Session token not available");
            return;
          }
          const response = await fetch(`${backendUrl}/getCampfireLeaderboard`, {
            headers: {
              sessionToken,
            },
            // credentials: "include",
          });

          if (!response.ok) {
            throw new Error(
              `Failed to fetch campfire leaderboard. Status: ${response.status}`
            );
          }

          const fetchedContributors = await response.json();

          if (!fetchedContributors) {
            console.warn("Empty response received. Retrying in 1 second...");
            setTimeout(() => {
              fetchCampfireLeaderboard();
            }, 1000);
            return;
          }

          fetchedContributors.forEach((contributor) => {
            const existingElement = campfireLeaderboard.value.find(
              (c) => c.userid === contributor.userid
            );
            if (!existingElement) {
              campfireLeaderboard.value.push(contributor);
            }
          });
        } catch (error) {
          console.error("Error fetching campfire leaderboard:", error.message);
          // You may want to add additional error handling or logging here.
        }
      }

      const watchIsActive = ref(true);

      watch(
        () => props.user,
        (newUser) => {
          if (newUser && watchIsActive.value) {
            const initializeWebSocket = () => {
              const userId = props.user.google_id;
              if (!userId) {
                console.error("No user ID");
                return;
              }

              const socketUrl =
                process.env.NODE_ENV === "production"
                  ? `wss://mmo-rts.com:3003/ws?userId=${userId}`
                  : `ws://localhost:3003/ws?userId=${userId}`;

              socket.value = new WebSocket(socketUrl);
              console.log("Connecting to WebSocket...");
              socket.value.onopen = () => {
                console.log("WebSocket Client Connected");
                startKeepAlive();
              };

              socket.value.onerror = (error) => {
                console.error("WebSocket encountered an error:", error);
              };

              socket.value.onmessage = (event) => {
                try {
                  const data = JSON.parse(event.data);
                  if (data.hasOwnProperty("ids")) {
                    emit("updateOnlineCount", data);
                    onlineIds.value = data.ids;
                    return;
                  } else if (data.hasOwnProperty("events")) {
                    // loop through and display all events with a delay
                    data.events.forEach((event, index) => {
                      addEvent(event);
                    });
                    return;
                  }
                  if (data.tableName === "users") {
                    // Handle changes for the "users" table
                    if (!data.data && data.isDeleted && data.id) {
                      // Remove the user from the leaderboard
                      leaderboard.value = leaderboard.value.filter(
                        (player) => player.id !== data.id
                      );
                    } else {
                      // Update the leaderboard with the new user data
                      updateLeaderboard(data.data);
                    }
                  } else if (data.tableName === "campfirecontributions") {
                    if (!data.data && data.isDeleted && data.userid) {
                      // Remove the user from the leaderboard
                      campfireLeaderboard.value =
                        campfireLeaderboard.value.filter(
                          (player) => player.userid !== data.userid
                        );
                    } else {
                      const userId = data.data?.userid;

                      if (userId) {
                        // Check if the user with the same ID is already in campfireLeaderboard
                        const existingUserIndex =
                          campfireLeaderboard.value.findIndex(
                            (user) => user.userid === userId
                          );

                        if (existingUserIndex !== -1) {
                          // If user exists, update the contributions
                          campfireLeaderboard.value[
                            existingUserIndex
                          ].contributions = data.data.contributions;
                        } else {
                          // If user doesn't exist, add them to the campfireLeaderboard
                          campfireLeaderboard.value.push(data.data);
                        }
                      }
                    }
                  }
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                  console.log("Received non-JSON data:", event.data);
                }
              };

              socket.value.onclose = (event) => {
                console.error("WebSocket closed unexpectedly:", event);

                alertModalIsVisible.value = true;
              };

              fetchLeaderboard();
              fetchCampfireLeaderboard();

              watchIsActive.value = false;
            };

            initializeWebSocket();

            document.addEventListener("visibilitychange", () => {
              if (document.visibilityState === "visible") {
                if (
                  socket.value &&
                  socket.value.readyState !== WebSocket.OPEN
                ) {
                  window.location.reload();
                  // Reconnect and update the leaderboard
                  // loadingLeaderboard.value = true;
                  // campfireLeaderboard.value = [];
                  // leaderboard.value = [];
                  // fetchLeaderboard();
                  // fetchCampfireLeaderboard();
                  // initializeWebSocket();
                  // window.alert("Reconnecting to the server...");
                }
              }
            });
          }
        }
      );
      onUnmounted(() => {
        if (socket.value) {
          socket.value.close();
          console.log("WebSocket Connection Closed");
        }
      });
      const events = ref([]);
      const eventQueue = ref([]);
      let isProcessing = false;

      const addEvent = (message) => {
        eventQueue.value.push({ message, fadingOut: false });
        processQueue();
      };

      const displayedEvents = computed(() => {
        return events.value.slice(0, 1);
      });

      const removeEvent = (event) => {
        const index = events.value.indexOf(event);
        if (index > -1) {
          events.value.splice(index, 1);
          if (events.value.length === 0) {
            isProcessing = false;
            processQueue();
          }
        }
      };

      const startFadeOutFast = (event) => {
        const index = events.value.indexOf(event);
        if (index > -1) {
          events.value[index].fadingOutFast = true;
        }
      };

      const processQueue = () => {
        if (
          !isProcessing &&
          events.value.length < 2 &&
          eventQueue.value.length > 0
        ) {
          const nextEvent = eventQueue.value.shift();
          events.value.push(nextEvent);
          startFadeOut();
        }
      };

      const startFadeOut = () => {
        if (events.value.length === 0) return;

        isProcessing = true;

        setTimeout(() => {
          const oldestEvent = events.value[0];
          if (oldestEvent) oldestEvent.fadingOut = true;

          setTimeout(() => {
            if (oldestEvent) {
              removeEvent(oldestEvent);
              isProcessing = false;
              processQueue();
            }
          }, 1000); // 1 second fade out
        }, 2000); // 3 seconds display
      };

      // Initialize processing when events change
      watchEffect(() => {
        if (events.value.length > 0 && !isProcessing) {
          startFadeOut();
        }
      });
      const onKeyPress = (event) => {
        if (event.key === "Escape") {
          document.body.classList.remove("body-no-scroll");

          if (props.showUsersOnlineModal) {
            closeShowOnlineUsers();
          }
        }
      };
      onMounted(() => {
        window.addEventListener("keydown", onKeyPress);
        // preloadImages();
      });
      // Remove the event listener when the component is destroyed

      onBeforeUnmount(() => {
        window.removeEventListener("keydown", onKeyPress);
      });

      return {
        onlineIds,
        closeShowOnlineUsers,
        onlineUsers,
        getDotStyles,
        startFadeOutFast,
        displayedEvents,
        removeEvent,
        addEvent,
        events,
        showScoreLabel,
        currentPlayerInfo,
        labelPosition,
        showPlayerScore,
        hidePlayerScore,
        isYou,
        calculateDotPosition,
        mode,
        modeLabels,
        changeMode,
        renderModeContent,
        formattedPointsOrScore,
        alertModalIsVisible,
        userScores,
        sortedLeaderboard,
        openProfileModalWithId,
        fetchCampfireLeaderboard,
        campfireLeaderboard,
        sortedLeaderboard,
        formatDisplayName,
        // stealPoints,
        isCurrentUser,
        show,
        message,
        isPositive,
        isProfileModalVisible,
        selectedUserProfile,
        openProfileModal,
        closeProfileModal,
        loadingLeaderboard,
        leaderboard,
        // destroyFarm,
      };
    } catch (error) {
      console.error("Error in setup function:", error);
      throw error; // Rethrow the error to make Vue.js aware of it
    }
  },
};
</script>

<style scoped>
.current-user {
  background-color: #28a745 !important; /* Green background for the current user */
}
.current-user .position {
  color: #fff; /* White text for the current user */
}

.current-user .player-info {
  color: #fff; /* White text for the current user */
}
.current-user .steal-btn {
  opacity: 0; /* Hide steal button for the current user */
  cursor: auto;
}
.notification {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  padding: 16px;
  z-index: 15;
  width: 100%;
  transition: background-color 0.5s, top 0.5s;
}

.notification.positive {
  background-color: #4caf50; /* Green background for positive events */
}

.notification.negative {
  background-color: #f44336; /* Red background for negative events */
}

.notification:hover {
  background-color: #555; /* Darker background on hover */
}

.steal-btn {
  background-color: #ffcc00;
  color: #000000;
  padding: 8px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.steal-btn:hover {
  background-color: #ffb100; /* Darker yellow on hover */
}

.leaderboard-container {
  background-color: var(--secondary-bg);
  padding: 0 20px 20px 20px;
  margin: 0px auto;
  max-width: 600px;
  position: relative;
}

.leaderboard-title {
  color: var(--primary-text);
  font-size: 24px;
  padding-bottom: 20px;
  margin-top: 00px;
  background-color: var(--primary-bg);
  margin: 0;
  min-width: 300px;
  max-width: 88%;
  padding: 15px;
  margin: auto;
}
.leaderboard-title:hover {
  background-color: var(--button-hover-bg); /* Lighter background on hover */
}
.leaderboard-list {
  list-style: none;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-auto-rows: minmax(120px, auto);
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg);
  color: var(--primary-text);
  max-width: 88%;
  min-width: 300px;
  padding: 10px 15px; /* Corrected padding syntax */
  font-size: calc(0.8rem + 0.5vw);
}
.one-column {
  grid-template-columns: 1fr; /* Single column layout */
}

.leaderboard-item {
  background-color: var(--primary-bg);
  border: 1px solid var(--border-color2);
  padding: calc(8px + 0.5vw);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  box-sizing: border-box;
  position: relative;
  margin-top: 2px;
  margin-bottom: 2px;
}

.avatar {
  width: calc(54px + 1.75vw); /* Increased avatar size */
  height: calc(54px + 1.75vw); /* Increased avatar size */
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  margin-bottom: 8px;
}

.position {
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: calc(0.8rem + 0.5vw);
  margin: 0;
}

.player-info {
  text-align: center;
  font-size: calc(0.8rem + 0.5vw);
}

.leaderboard-item:hover {
  background-color: var(--button-hover-bg); /* Lighter background on hover */
}
.leaderboard-item:hover .player-info {
  background-color: var(--button-hover-bg); /* White text on hover */
}

.position {
  font-weight: bold;
  margin-right: 4px;
  font-size: 16px !important;
  min-width: 22.25px;
}

.player-info {
  display: flex;
  align-items: center;
  width: 100%;

  font-size: 16px;
  background-color: var(--primary-bg);
  color: var(--primary-text);
}
.current-user .player-info {
  background-color: #28a745; /* Green background for the current user */
  color: #fff; /* White text for the current user */
}
.current-user:hover .player-info {
  background-color: #28a745; /* Green background for the current user */
  color: #fff; /* White text for the current user */
}
.name {
  flex: 1;
  text-align: left;
  font-size: 17px !important;
}
.gold {
  box-shadow: 0 0 0 2px gold;
}

.silver {
  box-shadow: 0 0 0 2px silver;
}

.bronze {
  box-shadow: 0 0 0 2px #cd7f32;
}

.leech {
  border: 1px solid #ff0000;
  box-sizing: border-box;
}

.end-positions {
  border: 1px solid #b57edc; /* Soft lavender color */
}

.middle-positions {
  border: 1px solid #9c27b0; /* Soft purple color */
}
.scatter-plot {
  width: 100%;
  position: relative;
  height: 530px; /* Set your preferred height for the scatter plot */
  z-index: 0;
}

.dot {
  position: absolute;
  height: 40px; /* Adjust size as needed */
  width: 40px; /* Adjust size as needed */
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  z-index: 1;
  transform: translate(-50%, 0);
}
.dot:hover {
  z-index: 2; /* Ensure the hovered dot appears above others */
}

.you {
  border: 1px solid #3498db !important; /* Set a different color for the dot representing you */
  z-index: 2;
}
.width100 {
  width: 100%;
}

.reference-label {
  position: absolute;
  left: -18px; /* Adjust the left position for labels */
  transform: translate(-100%, 3px);
  z-index: 2; /* Ensure the label appears above the dots */
}
.player-score-label {
  background-color: var(--primary-bg);
  position: absolute;
  z-index: 3; /* Ensure the label appears above the dots and reference labels */
  min-width: fit-content;
  font-size: 20px;
}
.notification-container {
  position: fixed;
  top: 2px;
  left: 10px;
  z-index: 1;
  max-width: 500px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.notification-popup {
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.98;
  margin-top: 8px;
  font-size: 16px;
  z-index: 2;
  background-color: var(--primary-bg);
  color: var(--primary-text);
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: opacity 0.366s ease-in-out;
}

.notification-popup.fade-out {
  opacity: 0;
}
.notification-popup.fade-out-fast {
  opacity: 0;
}
.notification-popup.fade-out-fast {
  opacity: 0;
  transition: opacity 0.09s ease-in-out; /* Faster fade-out transition */
}
.notification-fade-enter-active,
.notification-fade-leave-active {
  transition: opacity 1s ease;
}

.notification-fade-enter,
.notification-fade-leave-to {
  opacity: 1;
}

.users-online-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.users-online-modal-content {
  background-color: var(--primary-bg);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  position: relative;
  text-align: center;
  color: var(--primary-text);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 52px;
  cursor: pointer;
  color: red;
}

.close-button:hover {
  color: var(--secondary-text);
}

.users-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.user-item {
  flex: 1 1 calc(33.333% - 20px);
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.avatarr {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 8px;
}

.display-name {
  font-size: 18px;
  color: var(--primary-text);
}
</style>
