<template>
  <div v-if="isVisible" class="custom-alert-modal">
    <div class="modal-content">
      <p>Please refresh to continue playing!</p>
      <button @click="refreshPage">Refresh</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
    refreshPage() {
      location.reload();
    },
  },
};
</script>

<style scoped>
.custom-alert-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 91919192132123123199;
}

.modal-content {
  background: rgb(0, 153, 255);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
.modal-content p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-text);
}
.modal-content button {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background: var(--button-bg);
  color: var(--primary-text);
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
</style>
