<template>
  <ResourceTooltip
    :message="tooltipMessage"
    :visible="showResourceTooltipBool"
  />

  <div class="game-container relative" v-if="user && typeof user === 'object'">
    <!-- <h1 class="game-title">🎮 Ranked Rumble!</h1> -->
    <div v-if="showBuyMenu" @click="closeBuyMenu" class="buy-menu-overlay">
      <div @click.stop class="buy-menu">
        <div class="modal">
          <div class="modal-content">
            <span class="close-btn" @click="closeBuyMenu">❌</span>
            <div class="type-title">
              {{ currentBuyType.type }}
            </div>
            <div class="buy-emoji">
              {{ currentBuyType.emoji }}
            </div>
            <div class="buy-cost">
              cost: {{ currentBuyType.cost * buyQuantity }}
              {{ currentBuyType.resourceType || "points" }}
            </div>

            <div class="quantity-selector">
              <button @click="buyQuantity = 1" min="1">Min</button>
              <button @click="decreaseBuyQuantity(5)" min="1">-5</button>

              <button @click="decreaseBuyQuantity(1)" min="1">-</button>
              <input
                type="number"
                v-model="buyQuantity"
                :max="maxQuantity"
                @input="validateInput"
              />
              <button
                @click="increaseBuyQuantity(1)"
                :disabled="buyQuantity >= maxQuantity"
              >
                +
              </button>
              <button
                @click="increaseBuyQuantity(5)"
                :disabled="buyQuantity >= maxQuantity"
              >
                +5
              </button>

              <button @click="setToMaxBuyQuantity">Max</button>
            </div>
            <button class="confirm-btn" @click="confirmPurchase">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showDefendModal" class="defend-modal">
      <div class="defend-modal-content">
        <h2>
          You have {{ myAttacksToDefend.length }} Attack{{
            myAttacksToDefend.length > 1 ? "s" : ""
          }}
          to Defend!
        </h2>
        <h3>
          Tier {{ myAttacksToDefend[0].tier }} Attack incoming from
          {{ myAttacksToDefend[0].attackerDisplayName }}
        </h3>

        <div class="defend-sequence">
          <p class="sequence-label">Select One Symbol In Each Row</p>
          <div
            v-for="(moves, rowIndex) in defendSequence"
            :key="rowIndex"
            class="sequence-row"
          >
            <button
              v-for="(move, moveIndex) in moves"
              :key="moveIndex"
              @click="selectDefendMove(rowIndex, moveIndex)"
              :class="{
                'move-button': true,
                'selected-defend': defendMoves[rowIndex] === moveIndex,
                correct:
                  lastRowClicked !== null &&
                  lastRowClicked === rowIndex &&
                  defendMoves[rowIndex] === moveIndex &&
                  moveCorrect === true,
                incorrect:
                  lastRowClicked !== null &&
                  lastRowClicked === rowIndex &&
                  defendMoves[rowIndex] === moveIndex &&
                  moveCorrect === false,
                unknown:
                  lastRowClicked !== null &&
                  lastRowClicked === rowIndex &&
                  defendMoves[rowIndex] === moveIndex &&
                  moveCorrect === null,
                disabled: disabledRows[rowIndex],
              }"
            >
              {{ move }}
            </button>
          </div>
        </div>

        <p>Time Left To Defend: {{ timeLeftToDefendAttack }}</p>
      </div>
    </div>

    <div
      v-if="showDivineSpellMenu"
      @click="closeDivineSpellMenu"
      class="divine-spell-menu-overlay"
    >
      <div @click.stop class="spell-menu">
        <div class="modal">
          <div class="modal-content">
            <span class="close-btn" @click="closeDivineSpellMenu">❌</span>

            <div class="spell-title">Divine Spells</div>

            <div class="spells-container">
              <div
                @click="selectDivineSpell(1)"
                class="spell-item"
                :class="{ selectedSpell: selectedDivineSpell === 1 }"
              >
                <h3>Divine Intervention</h3>
                <p>
                  Cast this spell, and the divine forces will favor you,
                  bringing forth a random outcome.
                </p>
                <p>Cost: {{ spellCosts[1] }} Piety</p>
                <p>Cooldown: {{ spellCooldowns[1] }} Hours</p>
              </div>

              <div
                class="cast-spell-btn"
                @click="castDivineSpell"
                :class="{ grayedOut: !selectedDivineSpell }"
                :disabled="!selectedDivineSpell"
              >
                Cast Spell
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showMiningExpeditionMenu"
      @click="closeMiningExpeditionMenu"
      class="mining-expedition-container"
    >
      <div @click.stop class="mining-expedition-overlay">
        <div class="mining-expedition-modal">
          <div class="mining-expedition-header">
            <span
              class="close-expedition-btn"
              @click="closeMiningExpeditionMenu"
              >❌</span
            >
            <h2 class="expedition-title">🧭 Expedition 🧭</h2>
          </div>
          <div class="expedition-info">
            <p>Embark on an expedition to uncover valuable resources!</p>
          </div>
          <div class="character-selection">
            <div class="character-options">
              <button
                @click="selectCharacter('rat')"
                :class="{ 'selected-character': selectedCharacter === 'rat' }"
                :style="{ opacity: selectedCharacter !== 'rat' ? 0.4 : 1 }"
                @mouseenter="characterDance('rat')"
              >
                <img
                  src="/img/mining/rat.png"
                  alt="Rat"
                  class="character-image"
                />
                Rat
              </button>
              <button
                @click="selectCharacter('dwarf')"
                :class="{ 'selected-character': selectedCharacter === 'dwarf' }"
                :style="{ opacity: selectedCharacter !== 'dwarf' ? 0.4 : 1 }"
                @mouseenter="characterDance('dwarf')"
              >
                <img
                  src="/img/mining/dwarf.png"
                  alt="Dwarf"
                  class="character-image"
                />
                Dwarf
              </button>
              <button
                @click="selectCharacter('elf')"
                :class="{ 'selected-character': selectedCharacter === 'elf' }"
                :style="{ opacity: selectedCharacter !== 'elf' ? 0.4 : 1 }"
                @mouseenter="characterDance('elf')"
              >
                <img
                  src="/img/mining/elf.png"
                  alt="Elf"
                  class="character-image"
                />
                Elf
              </button>
            </div>
          </div>
          <div class="expedition-actions">
            <button @click="startMiningExpedition" class="start-expedition-btn">
              Start Expedition
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="expeditionResult">
      <div class="expedition-result-overlay">
        <div class="expedition-result-modal">
          <div class="expedition-result-content">
            <h2 class="expedition-title">🧭 Expedition Results 🧭</h2>
            <p v-if="expeditionResult.points">
              Points: {{ expeditionResult.points }} 🌟
            </p>
            <p v-if="expeditionResult.wood">
              Wood: {{ expeditionResult.wood }} 🌲
            </p>
            <p v-if="expeditionResult.diamonds">
              Diamonds: {{ expeditionResult.diamonds }} 💎
            </p>

            <p
              v-if="
                !expeditionResult.points &&
                !expeditionResult.wood &&
                !expeditionResult.diamonds
              "
            >
              No resources found on this expedition. SORRY!!!
            </p>
          </div>
          <button
            @click="closeExpeditionResult"
            class="close-expedition-result-btn"
          >
            Close
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="showExpeditionContinueModal && expedition && expedition.outcome"
      class="expedition-continue-modal-overlay"
    >
      <div class="expedition-continue-modal">
        <div class="expedition-continue-modal-content">
          <p v-if="expedition.outcome.message">
            {{ expedition.outcome.message }}
          </p>
          <p
            v-if="expedition.outcome.pointsChange"
            :class="{
              'red-text': containsMinus(expedition.outcome.pointsChange),
              'green-text': !containsMinus(expedition.outcome.pointsChange),
            }"
          >
            Points: {{ expedition.outcome.pointsChange }} 🌟
          </p>
          <p
            v-if="expedition.outcome.woodChange"
            :class="{
              'red-text': containsMinus(expedition.outcome.woodChange),
              'green-text': !containsMinus(expedition.outcome.woodChange),
            }"
          >
            Wood: {{ expedition.outcome.woodChange }} 🌲
          </p>
          <p
            v-if="expedition.outcome.diamondsChange"
            :class="{
              'red-text': containsMinus(expedition.outcome.diamondsChange),
              'green-text': !containsMinus(expedition.outcome.diamondsChange),
            }"
          >
            Diamonds: {{ expedition.outcome.diamondsChange }} 💎
          </p>
          <p
            v-if="expedition.outcome.healthChange"
            :class="{
              'red-text': containsMinus(expedition.outcome.healthChange),
              'green-text': !containsMinus(expedition.outcome.healthChange),
            }"
          >
            Health: {{ expedition.outcome.healthChange }} 💖
          </p>
        </div>
        <button
          @click="showExpeditionContinueModal = false"
          class="close-expedition-continue-btn"
        >
          Continue
        </button>
      </div>
    </div>

    <div
      v-if="showMiningExpeditionGame && expedition"
      class="mining-expedition-game-container"
    >
      <div class="mining-expedition-game-overlay">
        <div class="mining-expedition-game-modal">
          <div class="mining-game-container">
            <div v-if="expedition" class="mining-game">
              <div class="mining-game-header">
                <h2 class="expedition-title">🧭 Expedition 🧭</h2>
                <img
                  v-if="selectedCharacter"
                  :src="`/img/mining/${
                    expedition.characterType || 'dwarf'
                  }.png`"
                  :alt="selectedCharacter"
                  class="selected-character-image"
                />
              </div>
              <div class="mining-game-stats">
                <div class="stat-box">
                  <span class="stat-emoji">💖</span>
                  <span class="stat-value">{{ expedition.health || 0 }}</span>
                </div>
                <div class="stat-box">
                  <span class="stat-emoji">🌟</span>
                  <span class="stat-value">{{ expedition.points || 0 }}</span>
                </div>
                <div class="stat-box">
                  <span class="stat-emoji">💎</span>
                  <span class="stat-value">{{ expedition.diamonds || 0 }}</span>
                </div>
                <div class="stat-box">
                  <span class="stat-emoji">🌲</span>
                  <span class="stat-value">{{ expedition.wood || 0 }}</span>
                </div>
              </div>

              <div v-if="expedition.prompt" class="expedition-prompt">
                <p>{{ expedition.prompt }}</p>
              </div>

              <div
                v-if="expedition.options && showOptions"
                class="mining-game-options"
              >
                <div v-for="(option, index) in expedition.options" :key="index">
                  <button
                    @click="makeExpeditionMove(index)"
                    class="mining-option-btn"
                  >
                    {{ option }}
                  </button>
                </div>
              </div>

              <div v-else>
                <div v-for="(option, index) in expedition.options" :key="index">
                  <button class="mining-option-btn">
                    {{ option }}
                  </button>
                </div>
              </div>

              <div @click="exitMiningExpedition" class="exit-mine-btn">
                🏠 Return Home 🏠
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showLeechMenu"
      @click="closeLeechMenu"
      class="leech-menu-overlay"
    >
      <div @click.stop class="buy-menu">
        <div class="leech-modal">
          <div class="leech-modal-content">
            <span class="close-btn" @click="closeLeechMenu">❌</span>
            <h2 class="leech-title">🩸 Attach Leech 🩸</h2>
            <div class="tier-selector"></div>

            <div v-if="leaderboard.length && user" class="targetUser">
              <div
                class="dropdown"
                :class="{ greenBorder: targetUser && !showDropdown }"
              >
                <div class="dropdown-selected" @click="toggleDropdown">
                  {{ selectedUser.displayName || "Select a user" }}
                </div>
                <div class="dropdown-options" :class="{ show: showDropdown }">
                  <div v-for="userObj in leaderboard" :key="userObj.id">
                    <div
                      v-if="userObj && userObj.google_id !== user.google_id"
                      class="dropdown-option"
                      @click="selectUser(userObj)"
                    >
                      <span v-if="userObj && userObj.displayName">{{
                        userObj.displayName
                      }}</span>
                      <span v-if="userObj">{{
                        formattedPointsOrScore(userObj.score || 0)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="confirm-btn" @click="confirmLeeching">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showAttackMenu"
      @click="closeAttackMenu"
      class="attack-menu-overlay"
    >
      <div @click.stop class="buy-menu">
        <div class="modal">
          <div class="modal-content">
            <span class="close-btn" @click="closeAttackMenu">❌</span>

            <div class="type-title">
              {{ currentBuyType.type }}
            </div>
            <div class="buy-emoji">
              {{ currentBuyType.emoji }}
            </div>
            <div class="buy-cost">
              cost: {{ currentBuyType.cost * buyQuantity }}
              {{ currentBuyType.resourceType || "points" }}
            </div>

            <div class="quantity-selector">
              <button
                @click="
                  buyQuantity = 1;
                  alterSelectedMovesLength();
                "
                min="1"
                max="1000000000"
              >
                Min
              </button>

              <button @click="divideBuyQuantity" min="1">÷ 10</button>
              <input
                type="number"
                v-model="buyQuantity"
                :max="Math.floor(maxQuantity / 10) * 10 || 1"
                @input="validateInputAttack"
              />
              <button
                @click="multiplyBuyQuantity"
                :disabled="buyQuantity >= maxQuantity"
              >
                x 10
              </button>

              <button @click="setToMaxAttackQuantity">Max</button>
            </div>

            <div v-if="leaderboard.length && user" class="targetUser">
              <div
                class="dropdown"
                :class="{ greenBorder: targetUser && !showDropdown }"
              >
                <div class="dropdown-selected" @click="toggleDropdown">
                  {{ selectedUser.displayName || "Select a user" }}
                </div>
                <div class="dropdown-options" :class="{ show: showDropdown }">
                  <div v-for="userObj in leaderboard" :key="userObj.id">
                    <div
                      v-if="userObj && userObj.google_id !== user.google_id"
                      class="dropdown-option"
                      @click="selectUser(userObj)"
                    >
                      <span v-if="userObj && userObj.displayName">{{
                        userObj.displayName
                      }}</span>
                      <span v-if="userObj">{{
                        formattedPointsOrScore(userObj.score || 0)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sequence">
              <p class="sequence-label">Attack Sequence</p>
              <div
                v-for="(moves, rowIndex) in sequence"
                :key="rowIndex"
                class="sequence-row"
              >
                <button
                  v-for="(move, moveIndex) in moves"
                  :key="moveIndex"
                  @click="selectMove(rowIndex, moveIndex)"
                  class="move-button"
                  :class="{ selected: selectedMoves[rowIndex] === moveIndex }"
                >
                  {{ move }}
                </button>
              </div>
            </div>
            <RandomFact />
            <button class="confirm-btn" @click="confirmPurchase">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>

    <div @click="closeInfoBox" v-if="showInfoModal" class="info-modal-overlay">
      <div @click.stop class="info-modal">
        <div class="modal-header">
          <span class="close-btn" @click="closeInfoBox">✖</span>
          <h2 class="text-info-name">{{ selectedTechnology.name }}</h2>
        </div>
        <div v-if="selectedTechnology" class="tech-info">
          <p>
            {{ selectedTechnology.description }}
          </p>
          <p><strong>Cost:</strong> {{ selectedTechnology.cost }} Science</p>
          <p>
            <strong>Research Cooldown:</strong>
            {{ Math.ceil(selectedTechnology.timeToResearch / 60 / 60) }} Hours
          </p>

          <div v-if="researchTimerText === 'Tech 🧬'" class="research-area">
            <button
              v-if="canResearchSelectedTechnology"
              @click="researchTech(user.google_id, selectedTechnology)"
              class="research-tech-btn"
            >
              Research
            </button>
            <p class="text-align-center already-researched" v-else>
              Already Researched
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showNotificationsMenu"
      @click="closeNotificationsMenu"
      class="notifications-menu-modal"
    >
      <div @click.stop class="notifications-menu-content relative">
        <span class="close-btn" @click="closeNotificationsMenu">❌</span>
        <div class="notifications-header">
          <p class="notifications-menu-title">Recent Events</p>
        </div>
        <div v-if="user?.notifications?.length" class="notifications-list">
          <!-- Recent Events -->
          <div v-if="recentNotifications.length">
            <div
              v-for="(notification, index) in recentNotifications"
              :key="index"
              class="notification-message"
            >
              <p>{{ notification.message }}</p>
              <span class="notification-timestamp">{{
                formatTimestamp(notification.timestamp)
              }}</span>
            </div>
          </div>

          <!-- Older Events -->
          <div v-if="olderNotifications.length" class="older-events">
            <p class="older-events-title">Older Events</p>
            <div
              v-for="(notification, index) in olderNotifications"
              :key="index"
              class="notification-message"
            >
              <p>{{ notification.message }}</p>
              <span class="notification-timestamp">{{
                formatTimestamp(notification.timestamp)
              }}</span>
            </div>
          </div>
        </div>
        <div v-else class="no-events-message">
          <p>
            It seems there haven't been any recent events to display. Consider
            building a farm or adding wood to the campfire to get things going!
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="showSettingsMenu"
      @click="closeSettingsMenu"
      class="settings-menu-modal"
    >
      <div @click.stop class="settings-menu-content">
        <span class="close-btn" @click="closeSettingsMenu">❌</span>
        <p class="settings-menu-title">Settings</p>
        <img
          @click="openAvatarModal"
          :src="`/img/avatars/${user.avatar || 'chick'}.jpg`"
          alt="player avatar"
          class="avatar"
        />
        <div
          v-if="showAvatarModal"
          @click="closeAvatarModal"
          class="avatar-modal"
        >
          <div @click.stop class="avatar-modal-content">
            <span class="close-btn" @click="closeAvatarModal">❌</span>
            <p class="avatar-modal-title">Select Your Avatar</p>
            <img
              :src="`/img/avatars/${user.avatar || 'chick'}.jpg`"
              alt="current avatar"
              class="current-avatar"
            />

            <div class="avatar-selection-grid">
              <div
                v-for="avatar in avatars"
                :key="avatar"
                class="avatar-option"
                @click="selectAvatar(avatar)"
              >
                <img
                  :src="`/img/avatars/${avatar}.jpg`"
                  :alt="avatar"
                  class="avatar-image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="toggle-container">
          <label class="toggle-label">Attack Notifications</label>
          <label class="switch">
            <input
              type="checkbox"
              v-model="notifyAttack"
              @change="updateAttackNotificationSettings"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <p @click="signOut" class="sign-out-btn">Sign Out</p>
        <p @click="deleteAccount" class="delete-account-btn">Delete Account</p>
      </div>
    </div>

    <div
      v-if="showConfirmationPopup"
      @click="closeConfirmationPopup"
      class="confirmation-popup"
    >
      <div @click.stop class="confirmation-popup-content">
        <span class="close-btn" @click="closeConfirmationPopup">❌</span>
        <p>Are you sure you want to delete your account?</p>
        <button @click="confirmDelete" class="confirm">
          <strong>Confirm</strong>
        </button>
        <button @click="cancelDelete" class="cancel">
          <strong>Cancel</strong>
        </button>
      </div>
    </div>

    <div v-if="showTechTree" @click="closeTechTree" class="tech-tree-modal">
      <div class="filler">
        <div @click.stop class="tech-tree-container">
          <span class="close-btn" @click="closeTechTree">❌</span>

          <h2 class="research-new-tech">Research Technologies</h2>
          <span class="your-science-label">
            {{ formattedPointsOrScore(user?.science) || 0 }}🧬
          </span>
          <div class="tech-tree">
            <!-- Loop through user's technologies -->
            <div
              v-if="techs?.length"
              v-for="technology in techs"
              :key="technology.id"
              class="technology-node"
              :class="{
                researched: user.technologies.includes(technology.id),
                buyable: user.science >= technology.cost,
                notbuyable: user.science < technology.cost,
              }"
              @click="openInfoBox(technology)"
            >
              <p>{{ technology.name }}</p>
              <p
                v-if="!user.technologies.includes(technology.id)"
                class="tech-cost"
              >
                {{ formattedPointsOrScore(technology.cost) }} 🧬
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div> -->
    <!-- <button @click="requestNotificationPermission">
        Request Notification Permission
      </button> -->
    <!-- </div> -->

    <!-- make achievements menu modal -->
    <div
      v-if="showAchievementsMenu"
      @click="closeAchievementsMenu"
      class="achievements-menu-modal"
    >
      <div @click.stop class="achievements-menu-content relative">
        <span class="close-btn" @click="closeAchievementsMenu">❌</span>
        <p class="achievements-menu-title">Achievements</p>
        <div class="achievements-list">
          <div
            v-for="(achievement, index) in achievements"
            :key="index"
            class="achievement"
          >
            <p class="achievement-name">{{ achievement.name }}</p>
            <p class="achievement-level">Level: {{ achievement.level }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <div @click="openSettingsMenu" class="gear-icon">⚙️</div>
      <div @click="openNotificationsMenu" class="bell-icon">🔔</div>
      <!-- <div @click="openAchievementsMenu" class="trophy-icon">🏆</div> -->
      <p class="username">{{ user.displayName || "loading" }}</p>
    </div>

    <div class="user-info-box">
      <div class="user-info">
        <div class="info-item" @click="showResourceTooltip('points')">
          <p class="user-label">
            <span class="emoji"
              >🌟<span class="points">{{
                formattedPointsOrScore(user.points) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.wood" class="user-info">
        <div class="info-item" @click="showResourceTooltip('wood')">
          <p class="user-label">
            <span class="emoji"
              >🌲<span class="points">{{
                formattedPointsOrScore(user.wood) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.electricity" class="user-info">
        <div class="info-item" @click="showResourceTooltip('electricity')">
          <p class="user-label">
            <span class="emoji"
              >⚡️<span class="points">{{
                formattedPointsOrScore(user.electricity) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.piety" class="user-info">
        <div class="info-item" @click="showResourceTooltip('piety')">
          <p class="user-label">
            <span class="emoji"
              >🙏<span class="points">{{
                formattedPointsOrScore(user.piety) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.science" class="user-info">
        <div class="info-item" @click="showResourceTooltip('science')">
          <p class="user-label">
            <span class="emoji"
              >🧬<span class="points">{{
                formattedPointsOrScore(user.science) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.underlings" class="user-info">
        <div class="info-item" @click="showResourceTooltip('underlings')">
          <p class="user-label">
            <span class="emoji"
              >👹<span class="points">{{
                formattedPointsOrScore(user.underlings) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.overlords" class="user-info">
        <div class="info-item" @click="showResourceTooltip('overlords')">
          <p class="user-label">
            <span class="emoji"
              >🎭<span class="points">{{
                formattedPointsOrScore(user.overlords) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.diamonds" class="user-info">
        <div class="info-item" @click="showResourceTooltip('diamonds')">
          <p class="user-label">
            <span class="emoji"
              >💎<span class="points">{{
                formattedPointsOrScore(user.diamonds) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.essence" class="user-info">
        <div class="info-item" @click="showResourceTooltip('essence')">
          <p class="user-label">
            <span class="emoji"
              >🌌<span class="points">{{
                formattedPointsOrScore(user.essence) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.bugRemains" class="user-info">
        <div class="info-item" @click="showResourceTooltip('bugRemains')">
          <p class="user-label">
            <span class="emoji"
              >🕷️<span class="points">{{
                formattedPointsOrScore(user.bugRemains) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.farms" class="user-info">
        <div class="info-item" @click="showResourceTooltip('farms')">
          <p class="user-label">
            <span class="emoji"
              >🚜<span class="points">{{
                formattedPointsOrScore(user.farms) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.stealthFarms" class="user-info">
        <div class="info-item" @click="showResourceTooltip('stealthFarms')">
          <p class="user-label">
            <span class="emoji"
              >👻<span class="points">{{
                formattedPointsOrScore(user.stealthFarms) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.priests" class="user-info">
        <div class="info-item" @click="showResourceTooltip('priests')">
          <p class="user-label">
            <span class="emoji"
              >⛪<span class="points">{{
                formattedPointsOrScore(user.priests) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>

      <div v-if="user.guardTowers" class="user-info">
        <div class="info-item" @click="showResourceTooltip('guardTowers')">
          <p class="user-label">
            <span class="emoji"
              >🏰<span class="points">{{
                formattedPointsOrScore(user.guardTowers) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.laboratories" class="user-info">
        <div class="info-item" @click="showResourceTooltip('laboratories')">
          <p class="user-label">
            <span class="emoji"
              >🔬<span class="points">{{
                formattedPointsOrScore(user.laboratories) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.woodpowers" class="user-info">
        <div class="info-item" @click="showResourceTooltip('woodpowers')">
          <p class="user-label">
            <span class="emoji"
              >🌲⚡️<span class="points">{{
                formattedPointsOrScore(user.woodpowers) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div v-if="user.siphonTowers" class="user-info">
        <div
          class="info-item"
          @click="() => showResourceTooltip('siphonTowers')"
        >
          <p class="user-label">
            <span class="emoji"
              >🗼<span class="points">{{
                formattedPointsOrScore(user.siphonTowers) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
      <div
        v-if="user.leech"
        @click="removeLeech"
        class="user-info"
        :class="{ leech: user.leech > 0 }"
      >
        <div class="info-item">
          <p class="user-label">
            <span class="emoji"
              >🩸<span class="points">{{
                formattedPointsOrScore(user.leech) || 0
              }}</span></span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="flex2-wrapper">
      <div class="flex2">
        <div class="relative">
          <button
            @click="gainPoint"
            @mousedown="startClicking"
            @mouseup="stopClicking"
            @mouseleave="stopClicking"
            class="btn gain-point-btn"
          >
            +{{ pointAmount }} 🌟
          </button>
          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip"
              >Click to gain 1 point.</span
            >
          </div>
        </div>

        <div>
          <button @click="showStructuresMenu = !showStructuresMenu" class="btn">
            {{ showStructuresMenu ? "Hide Structures" : "Show Structures" }}
          </button>
        </div>

        <div v-if="user && showStructuresMenu" class="relative">
          <button @click="openBuildFarmMenu" class="btn gain-farm-btn">
            Farm 🚜
          </button>
          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip"
              >Invest 100 points to build a farm. Farms generate 1 point every
              two hours.</span
            >
          </div>
          <p class="price-label">-{{ farmPrice }} points</p>
        </div>
        <div v-if="user && showStructuresMenu" class="relative">
          <button @click="openHirePriestMenu" class="btn hire-priest-btn">
            Priest ⛪
          </button>
          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip"
              >Spend {{ priestPrice }} points to hire a priest. Priests produce
              1 Piety every 11 hours.</span
            >
          </div>
          <p class="price-label">-{{ priestPrice }} points</p>
        </div>
        <div v-if="user && showStructuresMenu" class="relative relative">
          <button @click="openBuildStealthFarmMenu" class="btn buy-farm-btn">
            Stealth Farm 👻
          </button>
          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip"
              >Purchase a stealth farm for {{ stealthFarmPrice }} points.
              Stealth farms operate covertly without the knowledge of anyone
              else providing 1-3 points every 6 hours.</span
            >
          </div>
          <p class="price-label">-{{ stealthFarmPrice }} points</p>
        </div>
        <div v-if="user && showStructuresMenu" class="relative">
          <button @click="openBuildLaboratoryMenu" class="btn buy-lab-btn">
            Lab 🔬
          </button>
          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip"
              >Invest {{ laboratoryPrice }} points to build a laboratory.
              Laboratories generate one science every 16 hours, contributing to
              your scientific progress.</span
            >
          </div>
          <p class="price-label">-{{ laboratoryPrice }} points</p>
        </div>
        <div v-if="user?.points && showStructuresMenu" class="relative">
          <button @click="openBuildGuardTowerMenu" class="btn buy-tower-btn">
            Guard Tower 🏰
          </button>
          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip"
              >Guard Towers provide defense against attacks. When taking damage,
              one Guard Tower is used to halve the incoming damage to
              infrastructure.
            </span>
          </div>
          <p class="price-label">-{{ guardTowerPrice }} diamond</p>
        </div>

        <div v-if="user && showStructuresMenu" class="relative">
          <button @click="openBuildWoodPowerMenu" class="btn contribute-btn">
            Wood Power 🌲⚡️
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Construct a Wood Power Plant. Through controlled combustion, these
              plants efficiently convert 5 units of wood into 1 unit of
              electricity every 7 hours.
            </span>
          </div>
          <p class="price-label">-{{ woodPowerPrice }} points</p>
        </div>

        <div v-if="user && showStructuresMenu" class="relative">
          <button @click="openBuildSiphonTowerMenu" class="btn contribute-btn">
            Siphon Tower 🗼
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Construct a Siphon Tower. Siphon Towers are used to extract
              resources from your enemies. Siphon Towers require electricity to
              operate.
            </span>
          </div>
          <p class="price-label">-{{ siphonTowerPrice }} points</p>
        </div>

        <div v-if="user" class="relative">
          <button @click="openSpawnOverlordMenu" class="btn contribute-btn">
            Overlord 🎭
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Spawn Overlords to attack your enemies! Overlords are very
              powerful units that can be used to pummel your opponents into
              submission.
            </span>
          </div>
          <p class="price-label">-{{ overlordPrice }} points</p>
        </div>

        <div v-if="user" class="relative">
          <button @click="openSpawnUnderlingMenu" class="btn contribute-btn">
            Underling 👹
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Spawn Underlings to attack your enemies! Underlings are powerful
              units that can be used to destroy enemies' infrastructure.
            </span>
          </div>
          <p class="price-label">-{{ underlingPrice }} points</p>
        </div>

        <div v-if="user" class="relative">
          <button
            v-if="canConvertPiety && user && !isProcessingPiety"
            @click="convertPietyToFortune"
            class="btn convert-piety-btn"
          >
            {{ conversionTimerText }}
          </button>

          <button v-else class="btn convert-piety-btn" disabled>
            {{ conversionTimerText }}
          </button>
          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip"
              >Convert {{ goodFortunePrice }} piety to generate fortune (100
              points). This action is on a cooldown, so use it wisely to boost
              your overall points.</span
            >
          </div>
          <p class="price-label">-{{ goodFortunePrice }} piety</p>
        </div>

        <div v-if="user" class="relative">
          <button @click="openTechTree" class="btn buy-lab-btn">
            {{ researchTimerText }}
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Access the Tech Tree to explore and unlock diverse technologies.
            </span>
          </div>
          <!-- <p v-if="researchTimerText === 'Tech 🧬'" class="price-label">
            -🧬
          </p> -->
        </div>

        <div v-if="user" class="relative">
          <button @click="openMiningExpeditionMenu" class="btn contribute-btn">
            {{ expeditionTimerText }}
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Explore the world and make choices to determine the outcome of
              your expedition. Expeditions can yield valuable resources and
              rewards.
            </span>
          </div>
        </div>

        <div v-if="user" class="relative">
          <button @click="openLaunchAttackMenu" class="btn contribute-btn">
            {{ attackTimerText }}
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Launch an attack against your enemies! Attacks require an
              immediate response from your opponents.
            </span>
          </div>
        </div>

        <div v-if="user" class="relative">
          <button @click="openLeechMenu" class="btn contribute-btn">
            {{ leechTimerText }}
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Spend 1 Overlord to attach a leech to a user. Leeches drain one
              from the user every minute.
            </span>
          </div>
        </div>

        <div v-if="user && user?.technologies?.includes(11)" class="relative">
          <button @click="openDivineSpellMenu" class="btn contribute-btn">
            {{ spellTimerText }}
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Spend Piety to cast a divine spell. Divine spells are powerful and
              can be used for good and evil.
            </span>
          </div>
        </div>

        <div v-if="user" class="relative">
          <button @click="openGainWoodMenu" class="btn">Wood 🌲</button>
          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip"
              >Click to gain 1 wood at the cost of {{ woodPrice }} point.</span
            >
          </div>
          <p class="price-label wood-price-label">-{{ woodPrice }} points</p>
        </div>

        <div v-if="user" class="relative">
          <button @click="openThrowWoodMenu" class="btn contribute-btn">
            Throw Wood 🔥
          </button>

          <div @click="toggleTooltip" class="tooltip">
            <span class="info-icon">ℹ️</span>
            <span class="tooltiptext" v-show="showTooltip">
              Compete to throw the most Wood onto the campfire! The top
              contributor gains the most benefits. 🔥
            </span>
          </div>
          <p class="price-label">-{{ throwWoodPrice }} wood</p>
        </div>
      </div>
    </div>

    <!-- Notifications -->
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="notification"
      :class="{
        positive: notification.positive,
        negative: !notification.positive,
      }"
    >
      {{ notification.message }}
    </div>
  </div>
</template>

<script>
import {
  ref,
  watch,
  onBeforeUnmount,
  onMounted,
  computed,
  watchEffect,
} from "vue";
import { signOut as signOutFirebase } from "firebase/auth";
import { setAuthenticated } from "../authStore"; // Update the path to your store
import { auth } from "@/main";
import RandomFact from "@/components/RandomFact.vue";
import ResourceTooltip from "@/components/ResourceTooltip.vue";
export default {
  emits: ["updateUser"],
  props: {
    user: Object,
    duration: {
      type: Number,
      default: 4500,
    },
    isDarkTheme: {
      type: Boolean,
      default: true,
    },
    leaderboard: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  components: {
    ResourceTooltip,
    RandomFact,
  },
  setup(props, { emit }) {
    const showStructuresMenu = ref(false);
    const tooltipMessage = ref("");
    const showResourceTooltipBool = ref(false);

    const resources = {
      points: { emoji: "🌟" },
      wood: { emoji: "🌲" },
      electricity: { emoji: "⚡️" },
      piety: { emoji: "🙏" },
      science: { emoji: "🧬" },
      underlings: { emoji: "👹" },
      overlords: { emoji: "🎭" },
      diamonds: { emoji: "💎" },
      essence: { emoji: "🌌" },
      bugRemains: { emoji: "🕷️" },
      farms: { emoji: "🚜" },
      stealthFarms: { emoji: "👻" },
      priests: { emoji: "⛪" },
      guardTowers: { emoji: "🏰" },
      laboratories: { emoji: "🔬" },
      woodpowers: { emoji: "🌲⚡️" },
      siphonTowers: { emoji: "🗼" },
      leech: { emoji: "🩸" },
    };
    const tooltipTimer = ref(null); // Timer variable to track the tooltip visibility timeout

    const showResourceTooltip = (resourceKey) => {
      // Clear any existing timer to prevent the tooltip from hiding too early
      if (tooltipTimer.value) {
        clearTimeout(tooltipTimer.value);
      }

      // Set the tooltip message
      tooltipMessage.value =
        resourceKey.charAt(0).toUpperCase() + resourceKey.slice(1); // Capitalize the key for display

      // Show the tooltip
      showResourceTooltipBool.value = true;

      // Hide the tooltip after 2 seconds
      tooltipTimer.value = setTimeout(() => {
        showResourceTooltipBool.value = false;
      }, 2000);
    };

    const showAvatarModal = ref(false);
    const avatars = [
      "chick",
      "cow",
      "dog",
      "dolphin",
      "emu",
      "horse",
      "pig",
      "rat",
      "sloth",
      "wolf",
      "giraffe",
      "tiger",
      "elephant",
      "rhino",
    ];
    const openAvatarModal = () => {
      showAvatarModal.value = true;
    };
    const closeAvatarModal = () => {
      showAvatarModal.value = false;
    };
    const updateUserAvatar = async (avatar) => {
      try {
        const user = auth.currentUser;

        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }
        props.user.avatar = avatar;
        const response = await fetch(`${backendUrl}/updateAvatar`, {
          method: "POST",
          headers: {
            Authorization: idToken,
            sessionToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            avatar,
          }),
        });

        if (!response) {
          console.error("No response from server");
          return;
        }

        if (!response.ok) {
          // Handle non-successful response (e.g., HTTP error)
          throw new Error(`Server returned error: ${response.status}`);
        }

        const responseData = await response.json(); // Use a different variable name
        console.log(responseData);

        if (responseData) {
          emit("updateUser", responseData.user);
          displayNotification(responseData.message, responseData.success, 2000);
        }
      } catch (error) {
        console.error("Error updating avatar:", error);
      }
    };
    const selectAvatar = async (avatar) => {
      console.log("Selected avatar:", avatar);
      if (avatar === props.user.avatar) {
        return;
      }
      closeAvatarModal();
      await updateUserAvatar(avatar);
      // backend fetch to update user avatar
    };
    const showLeechMenu = ref(false);
    const openLeechMenu = () => {
      if (props.user.overlords < 1) {
        displayNotification(
          "You need at least 1 Overlord to leech",
          false,
          2000
        );
        return;
      }
      if (leechTimerText.value !== "Leech 🩸") {
        displayNotification("Leech is on cooldown", false, 2000);
        return;
      }
      showLeechMenu.value = true;
      document.body.classList.add("body-no-scroll");
    };
    const closeLeechMenu = () => {
      showLeechMenu.value = false;
      document.body.classList.remove("body-no-scroll");
    };
    const showAchievementsMenu = ref(false);
    const openAchievementsMenu = () => {
      showAchievementsMenu.value = true;
      document.body.classList.add("body-no-scroll");
      console.log("Opening Achievements Menu...");
    };
    const closeAchievementsMenu = () => {
      showAchievementsMenu.value = false;
      document.body.classList.remove("body-no-scroll");
    };

    const pointAmount = ref(1);

    const expeditionResult = ref(null);
    const openExpeditionResult = (result) => {
      expeditionResult.value = result;
      document.body.classList.add("body-no-scroll");
    };
    const closeExpeditionResult = () => {
      expeditionResult.value = null;
      document.body.classList.remove("body-no-scroll");
    };
    const showExpeditionContinueModal = ref(false);

    const clickingInterval = ref(null);
    const isClicking = ref(false);

    const stopClicking = () => {
      // Stop clicking when mouse is released
      clearInterval(clickingInterval.value);
      isClicking.value = false;
    };
    const startClicking = () => {
      // Start clicking only if not already clicking
      if (!isClicking.value) {
        isClicking.value = true;
        clickingInterval.value = setInterval(gainPoint, 200);
      }
    };

    const expedition = ref(null);

    const characterDance = (character) => {
      console.log(`Dancing ${character}...`);
    };
    const selectedCharacter = ref("dwarf");
    const selectCharacter = (character) => {
      selectedCharacter.value = character;
    };

    const expeditionInProgress = ref(false);
    const showMiningExpeditionMenu = ref(false);
    const showMiningExpeditionGame = ref(false);

    const openMiningExpeditionMenu = () => {
      if (expeditionTimerText.value !== "Expedition 🧭") {
        displayNotification("Expedition is on cooldown", false, 2000);
        return;
      }
      showMiningExpeditionMenu.value = true;
      document.body.classList.add("body-no-scroll");
    };

    const closeMiningExpeditionMenu = () => {
      showMiningExpeditionMenu.value = false;
      document.body.classList.remove("body-no-scroll");
    };

    const exitMiningExpedition = async () => {
      try {
        showMiningExpeditionGame.value = false;
        document.body.classList.remove("body-no-scroll");
        const user = auth.currentUser;

        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }

        const response = await fetch(
          `${backendUrl}/exitExpedition?uid=${props.user.google_id}`,
          {
            method: "GET",
            headers: {
              Authorization: idToken,
              sessionToken,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response) {
          console.error("No response from server");
          return;
        }

        if (!response.ok) {
          throw new Error(`Server returned error: ${response.status}`);
        }
        const responseData = await response.json(); // Use a different variable name
        console.log(responseData);

        if (responseData) {
          expedition.value = null;
          closeMiningExpeditionGame();
          displayNotification(responseData.message, responseData.success, 2000);
          openExpeditionResult(responseData.changes);
        }
      } catch (error) {
        console.error("Error exiting expedition:", error);
      }
    };

    const fetchStartMiningExpeditionGame = async () => {
      try {
        const user = auth.currentUser;

        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }

        const response = await fetch(`${backendUrl}/startExpedition`, {
          method: "POST",
          headers: {
            Authorization: idToken,
            sessionToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            characterType: selectedCharacter.value,
          }),
        });

        if (!response) {
          console.error("No response from server");
          return;
        }

        if (!response.ok) {
          // Handle non-successful response (e.g., HTTP error)
          throw new Error(`Server returned error: ${response.status}`);
        }

        const responseData = await response.json(); // Use a different variable name
        console.log(responseData);

        if (responseData) {
          expedition.value = responseData;
          selectedCharacter.value = responseData.characterType;
          openMiningExpeditionGame();
        }
      } catch (error) {
        // Handle fetch or other errors
        console.error("Error updating notification settings:", error);
        // You can return an error object or throw the error as needed
        return { error: "Failed to update notification settings" };
      }
    };

    const startMiningExpedition = async () => {
      console.log("Starting mining expedition...");
      closeMiningExpeditionMenu();

      await fetchStartMiningExpeditionGame();
      console.log(
        `Starting mining expedition with ${selectedCharacter.value}...`
      );
    };

    const openMiningExpeditionGame = () => {
      showMiningExpeditionGame.value = true;
      document.body.classList.add("body-no-scroll");
      console.log("Opening mining expedition game...");
    };

    const closeMiningExpeditionGame = () => {
      showMiningExpeditionGame.value = false;
      document.body.classList.remove("body-no-scroll");
    };

    const formattedPointsOrScore = (points) => {
      const suffixes = ["", "k", "m", "b", "t", "q", "Q", "s", "S", "o"];

      if (points < 10000) {
        return points.toString();
      }

      let suffixIndex = 0;

      while (points >= 1000 && suffixIndex < suffixes.length - 1) {
        points /= 1000;
        suffixIndex++;
      }

      let formattedPoints =
        points % 1 !== 0 ? points.toFixed(1) : Math.floor(points);

      // Remove '.0' if present
      formattedPoints = formattedPoints.toString().replace(/\.0$/, "");

      return formattedPoints + suffixes[suffixIndex];
    };

    const woodPowerPrice = ref(1000);
    const notifyAttack = ref(false);
    const updateAttackNotificationSettings = async () => {
      try {
        const user = auth.currentUser;

        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }
        const response = await fetch(
          `${backendUrl}/updateAttackNotificationSettings`,
          {
            method: "POST",
            headers: {
              Authorization: idToken,
              sessionToken,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              notifyAttack: notifyAttack.value,
              uid: props.user.google_id,
            }),
          }
        );

        if (!response) {
          console.error("No response from server");
          return;
        }

        if (!response.ok) {
          // Handle non-successful response (e.g., HTTP error)
          throw new Error(`Server returned error: ${response.status}`);
        }

        return response.json(); // Assuming the server responds with JSON data
      } catch (error) {
        // Handle fetch or other errors
        console.error("Error updating notification settings:", error);
        // You can return an error object or throw the error as needed
        return { error: "Failed to update notification settings" };
      }
    };

    const showDefendModal = ref(false);
    const targetUser = ref("");
    const targetUserId = ref("");
    const selectedUser = ref({});
    const showDropdown = ref(false);
    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };
    const selectUser = (userObj) => {
      selectedUser.value = userObj;
      targetUserId.value = userObj.google_id;
      targetUser.value = userObj.displayName;
      showDropdown.value = false;
    };

    const canResearchSelectedTechnology = computed(() => {
      return decideCanResearchTechnology();
    });
    const showBuyMenu = ref(false);
    const moves = ref(["rock", "paper", "scissors"]); // Add more moves as needed
    const selectedMoves = ref([]);
    const sequence = computed(() => {
      const buyQuantityString = buyQuantity.value.toString();
      const sequence = [];

      const symbols = ["✊", "✋", "✌️"]; // Emojis for rock, paper, and scissors

      for (let i = 0; i < buyQuantityString.length; i++) {
        sequence.push([...symbols]);
      }

      return sequence;
    });

    const selectMove = (rowIndex, moveIndex) => {
      selectedMoves.value[rowIndex] = moveIndex;
    };
    const defendMoves = ref([]);
    const disabledRows = ref([]);
    const defendSequence = ref([]);

    const isDefendMoveInProgress = ref(false);

    const selectDefendMove = async (rowIndex, moveIndex) => {
      if (isDefendMoveInProgress.value) {
        displayNotification(
          "You are already in the process of making a move",
          false,
          3500
        );
        return;
      }
      if (!disabledRows[rowIndex] && defendMoves.value[rowIndex] === null) {
        // Send defend move request to the server

        isDefendMoveInProgress.value = true;

        const response = await sendDefendMoveRequest(rowIndex, moveIndex);
        if (response.error) {
          console.error(response.error);
          return;
        }
        console.log(response);

        if (response.success) {
          updateUIAfterDefendMove(response, rowIndex, moveIndex);
          displayNotification(response.message, response.moveCorrect, 10999);
          if (response.shiftAttack) {
            console.log("shift attack");

            await shiftAttack();
          }
          isDefendMoveInProgress.value = false;
        } else {
          displayNotification("Failed to Set Defender Move!", false);
          isDefendMoveInProgress.value = false;
        }

        // Update the UI based on the result
      }
    };
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const shiftAttack = async () => {
      await delay(2500);

      myAttacksToDefend.value.shift();
      defendMoves.value = [];
      disabledRows.value = [];
      defendSequence.value = [];

      if (myAttacksToDefend.value.length === 0) {
        closeDefendModal();
      } else {
        for (let i = 0; i < myAttacksToDefend.value[0].tier; i++) {
          defendSequence.value.push(["✊", "✋", "✌️"]);
          if (myAttacksToDefend.value[0].defenderMoves[i] !== null) {
            defendMoves.value.push(myAttacksToDefend.value[0].defenderMoves[i]);
            disabledRows.value.push(true);
          } else {
            defendMoves.value.push(null);
            disabledRows.value.push(null);
          }
        }
      }
    };

    const containsMinus = (x) => {
      x = x.toString();
      return x.includes("-");
    };

    const sendDefendMoveRequest = async (rowIndex, moveIndex) => {
      console.log(rowIndex, moveIndex);
      try {
        const user = auth.currentUser;

        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }
        const response = await fetch(`${backendUrl}/defendMove`, {
          method: "POST",
          headers: {
            Authorization: idToken,
            sessionToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            attackId: myAttacksToDefend.value[0].id,
            myid: myAttacksToDefend.value[0].defenderUserId,
            uid: props.user.google_id,
            rowIndex,
            moveIndex,
          }),
        });

        if (!response) {
          console.error("No response from server");
          return;
        }

        if (!response.ok) {
          // Handle non-successful response (e.g., HTTP error)
          throw new Error(`Server returned error: ${response.status}`);
        }

        return response.json(); // Assuming the server responds with JSON data
      } catch (error) {
        // Handle fetch or other errors
        console.error("Error sending defend move request:", error);
        // You can return an error object or throw the error as needed
        return { error: "Failed to send defend move request" };
      }
    };

    const moveCorrect = ref(null);
    const lastRowClicked = ref(null);
    const updateUIAfterDefendMove = (response, rowIndex, moveIndex) => {
      // Update the selected move
      defendMoves.value[rowIndex] = moveIndex;
      // Update the UI based on the server response
      // For example, you can check if the move was correct and update the style
      moveCorrect.value = response.moveCorrect;
      lastRowClicked.value = rowIndex;
      // Update the style based on the correctness of the move
      disabledRows.value[rowIndex] = true; // Disable the row after a move is made
    };

    const getIconObject = (move) => {
      switch (move) {
        case "rock":
          return "👊";
        case "paper":
          return "✋";
        case "scissors":
          return "✌️";
        default:
          return null;
      }
    };

    const closeDefendModal = () => {
      showDefendModal.value = false;
      document.body.classList.remove("body-no-scroll");
    };

    const buyQuantity = ref(1);
    const maxQuantity = ref(1);
    const currentBuyType = ref(null);

    const openBuyMenu = (type) => {
      if (type.resourceType !== undefined) {
        if (type.resourceType === "wood")
          if (type.resource === 0) {
            displayNotification("You don't have any wood to throw", false);
            return;
          } else if (type.resourceType === "underlings")
            if (type.resource === 0) {
              displayNotification(
                "You don't have any underlings to send",
                false
              );
              return;
            }

        maxQuantity.value = Math.floor(type.resource / type.cost);
      } else {
        maxQuantity.value = Math.floor((props.user?.points || 0) / type.cost);
      }
      if (maxQuantity.value === 0) {
        displayNotification("Not enough points", false);
        return;
      }
      document.body.classList.add("body-no-scroll");
      buyQuantity.value = 1;
      currentBuyType.value = type;
      showBuyMenu.value = true;
    };
    const validateInput = () => {
      if (!buyQuantity.value) buyQuantity.value = 1;

      if (buyQuantity.value < 1) {
        buyQuantity.value = 1;
      }
      if (buyQuantity.value > maxQuantity.value) {
        buyQuantity.value = maxQuantity.value;
      }
    };
    const alterSelectedMovesLength = () => {
      const quantityString = buyQuantity.value.toString();
      const targetLength = quantityString.length;

      while (selectedMoves.value.length > targetLength) {
        selectedMoves.value.pop(); // Remove the last element if too many
      }

      while (selectedMoves.value.length < targetLength) {
        selectedMoves.value.push(1); // Add a default value if too few
      }
    };
    const validateInputAttack = () => {
      if (!buyQuantity.value) buyQuantity.value = 1;

      if (buyQuantity.value < 1) {
        buyQuantity.value = 1;
      }
      if (buyQuantity.value > (Math.floor(maxQuantity.value / 10) * 10 || 1)) {
        buyQuantity.value = Math.floor(maxQuantity.value / 10) * 10 || 1;
      }
      if (buyQuantity.value >= 10) {
        buyQuantity.value = Math.floor(buyQuantity.value / 10) * 10;
      }
      if (buyQuantity.value < 10) buyQuantity.value = 1;

      if (buyQuantity.value > 1000000000) buyQuantity.value = 1000000000;

      alterSelectedMovesLength();
    };
    const closeBuyMenu = () => {
      document.body.classList.remove("body-no-scroll");
      showBuyMenu.value = false;
    };
    const increaseBuyQuantity = (amount) => {
      if (buyQuantity.value < 1) {
        buyQuantity.value = 0;
      }

      if (buyQuantity.value < maxQuantity.value) {
        buyQuantity.value += amount;
      }
      if (buyQuantity.value > maxQuantity.value) {
        buyQuantity.value = maxQuantity.value;
      }
    };
    const decreaseBuyQuantity = (amount) => {
      if (buyQuantity.value < 1) {
        buyQuantity.value = 1;
      }
      if (buyQuantity.value > 1) {
        buyQuantity.value -= amount;
      }
      if (buyQuantity.value < 1) {
        buyQuantity.value = 1;
      }
    };
    const multiplyBuyQuantity = () => {
      if (buyQuantity.value < 1 || maxQuantity.value < 1) {
        buyQuantity.value = 1;
        return;
      }

      let result = buyQuantity.value * 10;

      // Check if the result exceeds the maximum quantity
      if (result > maxQuantity.value) {
        result = maxQuantity.value;
      }

      result = Math.floor(result / 10) * 10;
      buyQuantity.value = result;
      if (buyQuantity.value < 1) buyQuantity.value = 1;
      buyQuantity.value = Math.pow(10, Math.floor(Math.log10(result)));
      alterSelectedMovesLength();
    };

    const divideBuyQuantity = () => {
      if (buyQuantity.value < 1 || maxQuantity.value < 1) {
        buyQuantity.value = 1;
        return;
      }

      const result = buyQuantity.value / 10;

      // Check if the result is less than 1
      if (result < 1) {
        buyQuantity.value = 1;
        return;
      }

      // Adjust the result to be divisible by 10
      buyQuantity.value = Math.pow(10, Math.floor(Math.log10(result)));

      alterSelectedMovesLength();
    };

    const setToMaxBuyQuantity = () => {
      if (currentBuyType.value.type === "Build Guard Tower") {
        if (maxQuantity.value + props.user.guardTowers >= 3) {
          maxQuantity.value = 3 - props.user.guardTowers;
        }
      }
      buyQuantity.value = maxQuantity.value;
    };
    const setToMaxAttackQuantity = () => {
      if (!maxQuantity.value) return;
      if (maxQuantity.value > 1000000000) maxQuantity.value = 1000000000;
      buyQuantity.value = Math.floor(maxQuantity.value / 10) * 10;
      if (buyQuantity.value < 1) buyQuantity.value = 1;
      if (buyQuantity.value > maxQuantity.value)
        buyQuantity.value = Math.floor(maxQuantity.value / 10) * 10;

      buyQuantity.value = Math.pow(
        10,
        Math.floor(Math.log10(buyQuantity.value))
      );

      alterSelectedMovesLength();
    };
    const confirmPurchase = () => {
      if (!currentBuyType.value) return;
      if (!currentBuyType.value.confirm) return;
      if (buyQuantity.value < 1) return;
      if (buyQuantity.value > maxQuantity.value)
        buyQuantity.value = maxQuantity.value;
      if (currentBuyType.value.type === "Launch Attack") {
        if (!targetUserId.value) {
          displayNotification("Oops! You forgot to select a target", false);
          return;
        }
      }
      currentBuyType.value.confirm();
      if (showBuyMenu.value) closeBuyMenu();
      else if (showAttackMenu.value) closeAttackMenu();
    };
    const canCastDivineSpell = ref(false);
    const selectedDivineSpell = ref(null);
    const selectDivineSpell = (spell) => {
      selectedDivineSpell.value = spell;
    };
    const showDivineSpellMenu = ref(false);
    const openDivineSpellMenu = () => {
      if (spellTimerText.value !== "Divine Spells ✨") {
        displayNotification("Divine Spells are on cooldown", false);
        return;
      }
      canCastDivineSpell.value = true;
      selectedDivineSpell.value = null;
      document.body.classList.add("body-no-scroll");
      showDivineSpellMenu.value = true;
    };
    const closeDivineSpellMenu = () => {
      document.body.classList.remove("body-no-scroll");
      showDivineSpellMenu.value = false;
    };

    const spellTimerText = ref("Loading...");
    const isProcessingSpell = ref(false);

    const spellCosts = ref([0, 100]);
    const spellCooldowns = ref([0, 8]);

    const castDivineSpell = async () => {
      if (spellTimerText.value !== "Divine Spells ✨") {
        displayNotification("Divine Spells are on cooldown", false);
        return;
      }
      if (100 > props.user.piety) {
        displayNotification("Not enough piety", false);
        return;
      }
      if (isProcessingSpell.value) {
        displayNotification("Spell is processing", false);
        return;
      }
      if (canCastSpell.value === false) {
        displayNotification("You cannot cast a spell right now", false);
        return;
      }
      const response = await sendCastDivineSpellRequest();
      if (response.error) {
        console.error(response.error);
        return;
      }

      if (response.success) {
        displayNotification(response.message, response.success);
        if (response.success) {
          if (response.spell === "5000") {
            displayNotification(`You gained 5000 points this time!`, true);
          }
        }
      } else {
        displayNotification("Failed to cast spell!", false);
      }
      closeDivineSpellMenu();
    };

    const leechTimerText = ref("Loading...");
    const isProcessingLeech = ref(false);

    const confirmLeeching = async () => {
      try {
        isProcessingLeech.value = true;
        if (!selectedUser.value) {
          displayNotification("No user selected", false);
          isProcessingLeech.value = false;
          return;
        }

        if (selectedUser.value.google_id === props.user.google_id) {
          displayNotification("You cannot leech from yourself", false);
          isProcessingLeech.value = false;
          return;
        }

        if (props.user.overlords < 1) {
          displayNotification("You need at least one Overlord to leech", false);
          isProcessingLeech.value = false;
          return;
        }

        const user = auth.currentUser;
        if (!user) {
          console.error("User not authenticated");
          isProcessingLeech.value = false;
          return;
        }

        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");
        if (!sessionToken) {
          console.error("Session token not available");
          isProcessingLeech.value = false;
          return;
        }

        let targetUserId = selectedUser.value.google_id;
        if (!targetUserId) {
          displayNotification("No user selected", false);
          isProcessingLeech.value = false;
          return;
        }
        let targetUserFirstName = selectedUser.value.displayName.split(" ")[0];
        if (!targetUserFirstName) {
          displayNotification("No user selected", false);
          isProcessingLeech.value = false;
          return;
        }

        const response = await fetch(`${backendUrl}/leech`, {
          method: "POST",
          headers: {
            Authorization: idToken,
            sessionToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            targetUserId,
            targetUserFirstName,
          }),
        });

        if (!response.ok) {
          throw new Error(`Server returned error: ${response.status}`);
        }

        const responseData = await response.json();
        displayNotification(responseData.message, responseData.success);

        if (response.ok) {
          setTimeout(() => {
            isProcessingLeech.value = false;
            updateLeechTimerText();
          }, 3000);
        }

        leechTimerText.value = "Calculating Cooldown...";
        closeLeechMenu();
      } catch (error) {
        console.error("Error confirming leeching:", error);
        displayNotification("An error occurred while leeching", false);
        isProcessingLeech.value = false;
      }
    };

    const removeLeech = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");
        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }

        const response = await fetch(`${backendUrl}/removeLeech`, {
          method: "POST",
          headers: {
            Authorization: idToken,
            sessionToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            leechAmount: props.user.leech,
          }),
        });

        if (!response.ok) {
          throw new Error(`Server returned error: ${response.status}`);
        }

        const responseData = await response.json();
        displayNotification(responseData.message, responseData.success);
      } catch (error) {
        console.error("Error removing leech:", error);
        displayNotification("An error occurred while removing leech", false);
      }
    };

    const sendCastDivineSpellRequest = async (spell) => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }
        isProcessingSpell.value = true;
        spellTimerText.value = `Calculating Cooldown...`;
        const response = await fetch(`${backendUrl}/castDivineSpell`, {
          method: "POST",
          headers: {
            Authorization: idToken,
            sessionToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            spell: selectedDivineSpell.value,
            uid: props.user.google_id,
          }),
        });

        if (!response) {
          console.error("No response from server");
          displayNotification("Failed to cast spell!", false);
          return;
        }

        if (!response.ok) {
          // Handle non-successful response (e.g., HTTP error)
          displayNotification("Failed to cast spell!", false);

          throw new Error(`Server returned error: ${response.status}`);
        }

        setTimeout(() => {
          isProcessingSpell.value = false;
          updateSpellTimerText();
        }, 3000);

        props.user.piety -= spellCosts.value[selectedDivineSpell.value];
        return response.json(); // Assuming the server responds with JSON data
      } catch (error) {
        // Handle fetch or other errors
        console.error("Error casting divine spell:", error);
        // You can return an error object or throw the error as needed
        return { error: "Failed to cast divine spell" };
      }
    };

    const showAttackMenu = ref(false);
    const openAttackMenu = (type) => {
      if (attackTimerText.value !== "Attack ⚔️") {
        displayNotification("Attack is on cooldown", false);
        return;
      }
      if (type.resourceType !== undefined) {
        if (type.resourceType === "wood")
          if (type.resource === 0) {
            displayNotification("You don't have any wood to throw", false);
            return;
          } else if (type.resourceType === "underlings")
            if (type.resource === 0) {
              displayNotification(
                "You need at least one underling to attack",
                false
              );
              return;
            }

        maxQuantity.value = Math.floor(type.resource / type.cost);
      } else {
        maxQuantity.value = Math.floor((props.user?.points || 0) / type.cost);
      }
      if (maxQuantity.value === 0) {
        displayNotification("Not enough underlings", false);
        return;
      }
      document.body.classList.add("body-no-scroll");
      buyQuantity.value = 1;
      selectedUser.value = {};
      targetUserId.value = "";
      targetUser.value = "";
      showDropdown.value = false;
      currentBuyType.value = type;
      showAttackMenu.value = true;
    };

    const closeAttackMenu = () => {
      document.body.classList.remove("body-no-scroll");
      showAttackMenu.value = false;
    };

    const selectedTechnology = ref(null);
    const techs = ref([]);
    const throwWoodPrice = ref(1);
    const guardTowerPrice = ref(1);
    const farmPrice = ref(100);
    const stealthFarmPrice = ref(300);
    const priestPrice = ref(250);
    const laboratoryPrice = ref(400);
    const goodFortunePrice = ref(1);
    const destroyFarmPrice = ref(5000);
    const woodPrice = ref(10);
    const underlingPrice = ref(500);
    const overlordPrice = ref(50);

    const fortuneGain = ref(100);

    const isProcessingPiety = ref(false);
    const isProcessingResearch = ref(false);

    const timerIntervalId = ref(null);
    const remainingTimeResearch = ref(0);

    const researchTimerText = ref("Loading...");

    const recentNotifications = computed(() =>
      props.user.notifications.filter(
        (notification) => now - notification.timestamp <= 24 * 60 * 60 * 1000
      )
    );

    const olderNotifications = computed(() =>
      props.user.notifications.filter(
        (notification) => now - notification.timestamp > 24 * 60 * 60 * 1000
      )
    );

    const formatTimestamp = (timestamp) => {
      const now = new Date().getTime();
      let difference = now - timestamp;

      const units = [
        { label: "d", divisor: 24 * 60 * 60 * 1000 },
        { label: "h", divisor: 60 * 60 * 1000 },
        { label: "m", divisor: 60 * 1000 },
      ];

      for (const unit of units) {
        const value = Math.floor(difference / unit.divisor);

        if (value >= 1) {
          return `${value}${unit.label}`;
        }
      }

      return "Just now";
    };

    let backendUrl =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_API_URL
        : "http://localhost:3003";

    // const solveChallenge = async (question) => {
    //   // Implement the logic to solve the challenge based on its format
    //   // For a simple math question, extract the numbers and perform the calculation
    //   // Example for a question like "What is 3 + 5?"
    //   const numbers = question.match(/\d+/g).map(Number);
    //   const result = numbers.reduce((a, b) => a + b, 0); // Sum the numbers
    //   return result;
    // };

    // async function fetchNonce(user, userIdToken, backendUrl) {
    //   try {
    //     // Step 1: Fetch challenge from the server
    //     const challengeResponse = await fetch(
    //       `${backendUrl}/requestChallenge?uid=${user.uid}`,
    //       {
    //         method: "GET",
    //         headers: {
    //           Authorization: userIdToken,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );

    //     if (!challengeResponse.ok) {
    //       console.error(
    //         "Error fetching challenge:",
    //         challengeResponse.statusText
    //       );
    //       return null;
    //     }

    //     const challenge = await challengeResponse.json();
    //     const userSolution = await solveChallenge(challenge.question);

    //     // Step 2: Request nonce with the solution
    //     const nonceResponse = await fetch(
    //       `${backendUrl}/requestNonce?uid=${user.uid}`,
    //       {
    //         method: "POST",
    //         headers: {
    //           Authorization: userIdToken,
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ userSolution, uid: user.uid }),
    //       }
    //     );

    //     if (!nonceResponse.ok) {
    //       console.error("Error fetching nonce:", nonceResponse.statusText);
    //       return null;
    //     }

    //     const { nonce } = await nonceResponse.json();
    //     return nonce;
    //   } catch (error) {
    //     console.error("Error in fetchNonce:", error);
    //     return null;
    //   }
    // }

    const getMyNotifications = async () => {
      try {
        const user = auth.currentUser;

        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }

        const response = await fetch(
          `${backendUrl}/getMyNotifications?uid=${props.user.google_id}`,
          {
            method: "GET",
            headers: {
              Authorization: idToken,
              sessionToken,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const notifications = await response.json();
          props.user.notifications = notifications;
          console.log("Notifications fetched successfully");
        } else {
          console.error("Error fetching notifications:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const getMyExpedition = async () => {
      try {
        const user = auth.currentUser;

        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }

        const response = await fetch(
          `${backendUrl}/getMyExpedition?uid=${props.user.google_id}`,
          {
            method: "GET",
            headers: {
              Authorization: idToken,
              sessionToken,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const fetchedExpedition = await response.json(); // <-- Use a different name
          console.log("Expedition:", fetchedExpedition);
          console.log("Expedition fetched successfully");
          if (fetchedExpedition) {
            expedition.value = fetchedExpedition;
            selectedCharacter.value = fetchedExpedition.characterType;
            openMiningExpeditionGame();
          }
        } else {
          console.error("Error fetching expedition:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const myAttacksToDefend = ref([]);
    const getMyAttacks = async () => {
      try {
        const user = auth.currentUser;

        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }

        const response = await fetch(
          `${backendUrl}/getMyAttacks?uid=${props.user.google_id}`,
          {
            method: "GET",
            headers: {
              Authorization: idToken,
              sessionToken,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const attacks = await response.json();
          console.log("Attacks fetched successfully");
          attacks.forEach((newAttack) => {
            const exists = myAttacksToDefend.value.some(
              (existingAttack) => existingAttack.id === newAttack.id
            );

            if (!exists) {
              myAttacksToDefend.value.push(newAttack);
            }
          });
          if (attacks.length > 0 && !showDefendModal.value) {
            showDefendModal.value = true;
            for (let i = 0; i < myAttacksToDefend.value[0].tier; i++) {
              defendSequence.value.push(["✊", "✋", "✌️"]);
              if (myAttacksToDefend.value[0].defenderMoves[i] !== null) {
                defendMoves.value.push(
                  myAttacksToDefend.value[0].defenderMoves[i]
                );
                disabledRows.value.push(true);
              } else {
                defendMoves.value.push(null);
                disabledRows.value.push(null);
              }
            }

            document.body.classList.add("body-no-scroll");
            startAttackTimeoutChecker();
          }
        } else {
          console.error("Error fetching attacks:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    const timeLeftToDefendAttack = ref("Calculating...");
    const startAttackTimeoutChecker = () => {
      timerIntervalId.value = setInterval(() => {
        if (myAttacksToDefend.value.length === 0) {
          clearInterval(timerIntervalId.value);
          return;
        }
        const now = new Date().getTime();
        const difference = now - myAttacksToDefend.value[0].attackTimeout;
        const remainingTime = props.duration - difference;
        if (remainingTime <= 0) {
          myAttacksToDefend.value.shift();
          if (myAttacksToDefend.value.length === 0) {
            clearInterval(timerIntervalId.value);
            closeDefendModal();
            return;
          } else {
            defendMoves.value = [];
            disabledRows.value = [];
            defendSequence.value = [];
            for (let i = 0; i < myAttacksToDefend.value[0].tier; i++) {
              defendSequence.value.push(["✊", "✋", "✌️"]);
              if (myAttacksToDefend.value[0].defenderMoves[i] !== null) {
                defendMoves.value.push(
                  myAttacksToDefend.value[0].defenderMoves[i]
                );
                disabledRows.value.push(true);
              } else {
                defendMoves.value.push(null);
                disabledRows.value.push(null);
              }
            }
          }
        } else {
          timeLeftToDefendAttack.value =
            calculateTimeLeftBasedOffTimestamp(remainingTime);
        }
      }, 1000);
    };

    const calculateTimeLeftBasedOffTimestamp = (timestamp) => {
      const totalSeconds = Math.floor(timestamp / 1000);

      const hoursLeft = Math.floor(totalSeconds / 3600);
      const minutesLeft = Math.floor((totalSeconds % 3600) / 60);
      const secondsLeft = totalSeconds % 60;

      if (hoursLeft > 0) {
        return `${String(hoursLeft).padStart(2, "0")}:${String(
          minutesLeft
        ).padStart(2, "0")}:${String(secondsLeft).padStart(2, "0")}`;
      } else {
        return `${String(minutesLeft).padStart(2, "0")}:${String(
          secondsLeft
        ).padStart(2, "0")}`;
      }
    };

    const gainPoint = async () => {
      try {
        const user = auth.currentUser;

        if (!user) {
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        // const nonce = await fetchNonce(user, idToken, backendUrl);
        // if (!nonce) {
        //   console.error("Failed to fetch nonce");
        //   return;
        // }

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }

        const response = await fetch(`${backendUrl}/gainPoint`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            technologies: props.user.technologies,
            // nonce: nonce,
          }),
        });

        if (response.ok) {
          const result = await response.json();
          props.user.points += result.amount;
          displayNotification(result.message, result.success);
          console.log("Backend updated successfully");
        } else {
          console.error("Error updating backend:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        window.location.reload();
      }
    };

    const openGainWoodMenu = () => {
      openBuyMenu({
        cost: woodPrice.value,
        type: "Chop Wood",
        emoji: "🌲",
        confirm: gainWood,
      });
    };
    const gainWood = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        if (props.user.points < woodPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        // const nonce = await fetchNonce(user, idToken, backendUrl);
        // if (!nonce) {
        //   console.error("Failed to fetch nonce");
        //   return;
        // }
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        const response = await fetch(`${backendUrl}/gainWood`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          // // credentials: "include",
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
            // nonce: nonce,
          }),
        });

        if (response.ok) {
          props.user.points -= woodPrice.value * buyQuantity.value;
          props.user.wood += 1 * buyQuantity.value;
          // Reset frontend points after successfully updating the backend
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log("Backend updated successfully");
        } else {
          // Handle backend update error
          console.error("Error updating backend:", response.statusText);
          // window.location.reload();
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const openThrowWoodMenu = () => {
      openBuyMenu({
        cost: throwWoodPrice.value,
        resource: props.user?.wood,
        resourceType: "wood",
        type: "Throw Wood",
        emoji: "🔥",
        confirm: throwWood,
      });
    };
    const throwWood = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        // Check if the user has enough wood to contribute to the campfire
        if (props.user.wood < throwWoodPrice.value * buyQuantity.value) {
          displayNotification("Not enough wood to contribute", false);
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();

        // const nonce = await fetchNonce(user, idToken, backendUrl);
        // if (!nonce) {
        //   console.error("Failed to fetch nonce");
        //   return;
        // }
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make the request to your backend for campfire contribution
        const response = await fetch(`${backendUrl}/throwWood`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
            technologies: props.user.technologies,
            //  nonce: nonce
          }),
        });

        if (response.ok) {
          // Update the user's wood count on the client-side
          props.user.wood -= 1 * buyQuantity.value; // Deduct 1 wood after contribution

          // Display a notification for a successful contribution
          const result = await response.json();
          displayNotification(result.message, result.success);

          console.log(result);
        } else {
          // Display a notification for a failed contribution
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const openBuildWoodPowerMenu = () => {
      openBuyMenu({
        cost: woodPowerPrice.value,
        type: "Build Wood Power",
        emoji: "🌲⚡️",
        confirm: buildWoodPower,
      });
    };
    const buildWoodPower = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        const user = auth.currentUser;
        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }
        if (props.user.points < woodPowerPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }
        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }
        const response = await fetch(`${backendUrl}/buildWoodPower`, {
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
          }),
        });

        if (response.ok) {
          props.user.woodPowers += 1 * buyQuantity.value;
          props.user.points -= woodPowerPrice.value * buyQuantity.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const siphonTowerPrice = ref(10000);
    const openBuildSiphonTowerMenu = () => {
      openBuyMenu({
        cost: siphonTowerPrice.value,
        type: "Build Siphon Tower",
        emoji: "🗼",
        confirm: buildSiphonTower,
      });
    };
    const buildSiphonTower = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        const user = auth.currentUser;
        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }
        if (props.user.points < siphonTowerPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }
        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          console.error("Session token not available");
          return;
        }
        const response = await fetch(`${backendUrl}/buildSiphonTower`, {
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
          }),
        });

        if (response.ok) {
          props.user.siphonTowers += 1 * buyQuantity.value;
          props.user.points -= siphonTowerPrice.value * buyQuantity.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const openBuildFarmMenu = () => {
      openBuyMenu({
        cost: farmPrice.value,
        type: "Build Farm",
        emoji: "🚜",
        confirm: buildFarm,
      });
    };
    const buildFarm = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        const user = auth.currentUser;
        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }
        if (props.user.points < farmPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }
        const idToken = await user.getIdToken();
        // const nonce = await fetchNonce(user, idToken, backendUrl);
        // if (!nonce) {
        //   console.error("Failed to fetch nonce");
        //   return;
        // }
        // Make the request to your backend
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        const response = await fetch(`${backendUrl}/buildFarm`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
            //  nonce: nonce
          }),
        });
        if (response.ok) {
          props.user.farms += 1 * buyQuantity.value;
          props.user.points -= farmPrice.value * buyQuantity.value;
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    const openBuildStealthFarmMenu = () => {
      openBuyMenu({
        cost: stealthFarmPrice.value,
        type: "Build Stealth Farm",
        emoji: "👻",
        confirm: buildStealthFarm,
      });
    };
    const buildStealthFarm = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        if (props.user.points < stealthFarmPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }

        // Replace "YOUR_BACKEND_URL" with the actual URL where your backend is hosted

        // Get the current user from Firebase authentication
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        const idToken = await user.getIdToken();

        // const nonce = await fetchNonce(user, idToken, backendUrl);
        // if (!nonce) {
        //   console.error("Failed to fetch nonce");
        //   return;
        // }
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make the request to your backend
        const response = await fetch(`${backendUrl}/buildStealthFarm`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
            // nonce: nonce
          }),
        });

        if (response.ok) {
          props.user.stealthFarms += 1 * buyQuantity.value;
          props.user.points -= stealthFarmPrice.value * buyQuantity.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    const openHirePriestMenu = () => {
      openBuyMenu({
        cost: priestPrice.value,
        type: "Hire Priest",
        emoji: "⛪",
        confirm: hirePriest,
      });
    };
    const hirePriest = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }
        if (props.user.points < priestPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make the request to your backend
        const response = await fetch(`${backendUrl}/hirePriest`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: user.uid,
            quantity: buyQuantity.value || 1,
          }),
        });

        if (response.ok) {
          props.user.priests += 1 * buyQuantity.value;
          props.user.points -= priestPrice.value * buyQuantity.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    const openBuildGuardTowerMenu = async () => {
      if (props.user.guardTowers >= 3) {
        displayNotification("You can only have 3 Guard Towers", false);
        return;
      }
      openBuyMenu({
        cost: guardTowerPrice.value,
        resource: props.user?.diamonds,
        resourceType: "diamonds",
        type: "Build Guard Tower",
        emoji: "🏰",
        confirm: buildGuardTower,
      });
    };
    const buildGuardTower = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        if (props.user.guardTowers >= 3) {
          displayNotification("You can only have 3 Guard Towers", false);
          return;
        }

        if (props.user.diamonds < guardTowerPrice.value * buyQuantity.value) {
          displayNotification("Not enough diamonds", false);
          return;
        }
        if (props.user.guardTowers + buyQuantity.value > 3) {
          buyQuantity.value = 3 - props.user.guardTowers;
          if (buyQuantity.value === 0) {
            displayNotification("You can only have 3 Guard Towers", false);
            return;
          }
          displayNotification(
            "You can only have 3 Guard Towers, reducing quantity for construction",
            false
          );
        }
        if (props.user.diamonds < guardTowerPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }

        // Replace "YOUR_BACKEND_URL" with the actual URL where your backend is hosted

        // Get the current user from Firebase authentication
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make the request to your backend
        const response = await fetch(`${backendUrl}/buildGuardTower`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
          }),
        });

        if (response.ok) {
          props.user.guardTowers += 1 * buyQuantity.value;
          props.user.diamonds -= guardTowerPrice.value * buyQuantity.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const openBuildLaboratoryMenu = () => {
      openBuyMenu({
        cost: laboratoryPrice.value,
        type: "Build Laboratory",
        emoji: "🔬",
        confirm: buildLaboratory,
      });
    };
    const buildLaboratory = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        if (props.user.points < laboratoryPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }

        // Replace "YOUR_BACKEND_URL" with the actual URL where your backend is hosted

        // Get the current user from Firebase authentication
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make the request to your backend
        const response = await fetch(`${backendUrl}/buildLaboratory`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
          }),
        });

        if (response.ok) {
          props.user.laboratories += 1 * buyQuantity.value;
          props.user.points -= laboratoryPrice.value * buyQuantity.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const openSpawnUnderlingMenu = () => {
      openBuyMenu({
        cost: underlingPrice.value,
        type: "Spawn Underling",
        emoji: "👹",
        confirm: spawnUnderling,
      });
    };
    const spawnUnderling = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        if (props.user.points < underlingPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }

        // Replace "YOUR_BACKEND_URL" with the actual URL where your backend is hosted

        // Get the current user from Firebase authentication
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make the request to your backend
        const response = await fetch(`${backendUrl}/spawnUnderling`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
          }),
        });

        if (response.ok) {
          props.user.underlings += 1 * buyQuantity.value;
          props.user.points -= underlingPrice.value * buyQuantity.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const openSpawnOverlordMenu = () => {
      openBuyMenu({
        cost: overlordPrice.value,
        type: "Spawn Overlord",
        emoji: "🎭",
        confirm: spawnOverlord,
      });
    };
    const spawnOverlord = async () => {
      try {
        if (!buyQuantity.value || buyQuantity.value > maxQuantity.value) {
          buyQuantity.value = 1;
        }

        if (props.user.points < overlordPrice.value * buyQuantity.value) {
          displayNotification("Not enough points", false);
          return;
        }

        // Replace "YOUR_BACKEND_URL" with the actual URL where your backend is hosted

        // Get the current user from Firebase authentication
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make the request to your backend
        const response = await fetch(`${backendUrl}/spawnOverlord`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
          }),
        });

        if (response.ok) {
          props.user.overlords += 1 * buyQuantity.value;
          props.user.points -= overlordPrice.value * buyQuantity.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const openLaunchAttackMenu = () => {
      openAttackMenu({
        cost: 1,
        type: "Launch Attack",
        resource: props.user?.underlings,
        resourceType: "underlings",
        emoji: "⚔️",
        confirm: launchAttack,
      });
      selectedMoves.value = [1];
    };
    const launchAttack = async () => {
      try {
        if (!targetUserId.value) {
          displayNotification("You must select a target", false);
          return;
        }
        if (
          !buyQuantity.value ||
          buyQuantity.value > (Math.floor(maxQuantity.value / 10) * 10 || 1)
        ) {
          buyQuantity.value = 1;
        }

        if (buyQuantity.value > 1000000000) {
          displayNotification(
            "You can't attack with more than 1,000,000,000 underlings",
            false
          );
          return;
        }

        if (props.user.underlings < buyQuantity.value) {
          displayNotification("Not enough underlings", false);
          return;
        }

        if (buyQuantity.value > 1000000000) {
          buyQuantity.value = 1000000000;
        }

        if (
          selectedMoves.value.length !== buyQuantity.value.toString().length
        ) {
          displayNotification(
            "You must select the right amount of underlings",
            false
          );
          return;
        }

        if (props.user.google_id === targetUserId.value) {
          displayNotification("You can't attack yourself", false);
          return;
        }
        // Get the current user from Firebase authentication
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }

        isProcessingAttack.value = true;
        attackTimerText.value = `Calculating Cooldown...`;
        // Make the request to your backend

        if (buyQuantity.value >= 10) {
          buyQuantity.value = Math.pow(
            10,
            Math.floor(Math.log10(buyQuantity.value))
          );
        }
        if (buyQuantity.value <= 9 && buyQuantity.value >= 1) {
          buyQuantity.value = 1;
        }

        const response = await fetch(`${backendUrl}/launchAttack`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({
            uid: props.user.google_id,
            quantity: buyQuantity.value || 1,
            moves: selectedMoves.value,
            targetUserId: targetUserId.value,
          }),
        });

        if (response.ok) {
          setTimeout(() => {
            isProcessingAttack.value = false;
            updateAttackTimerText();
          }, 3000);

          props.user.underlings -= 1 * buyQuantity.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const convertPietyToFortune = async () => {
      try {
        if (props.user.piety < goodFortunePrice.value) {
          displayNotification("Not enough piety", false);
          return;
        }
        // Replace "YOUR_BACKEND_URL" with the actual URL where your backend is hosted

        // Get the current user from Firebase authentication
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        conversionTimerText.value = `Calculating Cooldown...`;
        isProcessingPiety.value = true;

        // Make the request to your backend
        const response = await fetch(`${backendUrl}/convertPietyToFortune`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({ uid: user.uid }),
        });

        if (response.ok) {
          setTimeout(() => {
            isProcessingPiety.value = false;
            updateConversionTimerText();
          }, 3000);

          if (props.user.technologies?.includes(3)) fortuneGain.value = 150;

          props.user.piety -= 1;
          props.user.points += fortuneGain.value;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const researchTech = async (userId, tech) => {
      try {
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();

        if (!tech) {
          console.error("No technology selected");
          return;
        }

        if (!tech.id) {
          console.error("No technology id");
          return;
        }

        if (props.user.science < tech.cost) {
          displayNotification("Not enough science", false);
          return;
        }
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }

        isProcessingResearch.value = true;
        researchTimerText.value = `Allocating Scientists...`;
        // Make the request to your backend
        const response = await fetch(`${backendUrl}/researchTech`, {
          // credentials: "include",
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({ uid: userId, techId: tech.id }),
        });

        if (response.ok) {
          setTimeout(() => {
            isProcessingResearch.value = false;
            updateResearchTimerText();
            updatePointsButton();
          }, 3000); // 10000 milliseconds = 10 seconds
          props.user.science -= tech.cost;

          const result = await response.json();
          displayNotification(result.message, result.success);
          console.log(result);
          if (result.message) {
            showInfoModal.value = false;
            showTechTree.value = false;
            document.body.classList.remove("body-no-scroll");
          }
        } else {
          const result = await response.json();
          displayNotification(result.message, result.success);
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const makeExpeditionMove = async (choiceIndex) => {
      try {
        const user = auth.currentUser;
        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }
        const userId = user.uid;
        // Get the Firebase ID token
        const idToken = await user.getIdToken();

        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make a POST request to the server with the choiceIndex
        showOptions.value = false;

        const response = await fetch(`${backendUrl}/makeExpeditionMove`, {
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
            sessionToken,
          },
          body: JSON.stringify({ uid: userId, choiceIndex: choiceIndex }),
        });

        // Check if the response is successful
        if (response.ok) {
          // Extract the JSON data from the response
          const data = await response.json();
          // Handle the updated game state received from the server
          handleGameState(data);
        } else {
          // Handle errors
          console.error("Failed to make move:", response.statusText);
        }
      } catch (error) {
        // Handle network errors
        console.error("Error making move:", error.message);
      }
    };

    const handleGameState = (gameState) => {
      expedition.value = gameState;
      selectedCharacter.value = gameState.characterType;
      showOptions.value = true;
      showExpeditionContinueModal.value = true;
      console.log("New game state:", gameState);
      // Example: Update health, points, etc. based on gameState
    };

    const showOptions = ref(true);

    const decideCanResearchTechnology = () => {
      const user = props.user;
      if (!user || !user.technologies) return false;

      const tech = techs.value.find(
        (tech) => tech.id === selectedTechnology.value.id
      );
      if (!tech) return false;

      const isTechnologyInUserTechnologies = user.technologies.includes(
        selectedTechnology.value.id
      );
      if (isTechnologyInUserTechnologies) {
        return false;
      }

      const lastResearchTime =
        props.user && "lastResearchTime" in props.user
          ? props.user.lastResearchTime
          : 0;
      if (lastResearchTime >= Date.now()) return false;

      return true;
    };

    const showInfoModal = ref(false);

    const openInfoBox = (technology) => {
      selectedTechnology.value = technology;
      decideCanResearchTechnology();
      showInfoModal.value = true;
    };

    const closeInfoBox = () => {
      showInfoModal.value = false;
      selectedTechnology.value = null;
    };

    const showSettingsMenu = ref(false);
    const showConfirmationPopup = ref(false);

    const openSettingsMenu = () => {
      document.body.classList.add("body-no-scroll");
      showSettingsMenu.value = true;
    };
    const closeSettingsMenu = () => {
      document.body.classList.remove("body-no-scroll");
      showSettingsMenu.value = false;
    };

    const showNotificationsMenu = ref(false);

    const openNotificationsMenu = () => {
      document.body.classList.add("body-no-scroll");
      showNotificationsMenu.value = true;
    };
    const closeNotificationsMenu = () => {
      document.body.classList.remove("body-no-scroll");
      showNotificationsMenu.value = false;
    };
    const closeConfirmationPopup = () => {
      showConfirmationPopup.value = false;
    };
    const deleteAccount = () => {
      showConfirmationPopup.value = true;
      showSettingsMenu.value = false;
    };
    const deleteUserData = async (uid) => {
      try {
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }
        const idToken = await user.getIdToken();
        // Replace "YOUR_BACKEND_URL" with the actual URL where your Express backend is hosted
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make the request to your Express backend
        const response = await fetch(`${backendUrl}/deleteUserData`, {
          // credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken, // Add this line to include the ID token in the headers
            sessionToken,
          },
          body: JSON.stringify({ uid: uid }),
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result.message);
          return result.success;
        } else {
          const result = await response.json();
          console.error("Error:", result.message);
          return false;
        }
      } catch (error) {
        console.error("Fetch error:", error);
        return false;
      }
    };

    const confirmDelete = async () => {
      try {
        showConfirmationPopup.value = false;
        await deleteUserData(props.user.google_id);
        await signOutFirebase(auth);
        setAuthenticated(false);
        localStorage.removeItem("session_token");
        // Emit an event to notify the parent component of the action
        emit("updateUser", null);
        window.location.reload();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    };

    const cancelDelete = () => {
      showConfirmationPopup.value = false;
    };

    // Begin research

    const showTooltip = ref(false);

    const toggleTooltip = () => {
      showTooltip.value = !showTooltip.value;
    };

    const showTechTree = ref(false);

    const openTechTree = async () => {
      if (
        researchTimerText.value === "Loading..." ||
        researchTimerText.value === "Allocating Scientists..."
      )
        return;

      if (!techs.value.length) {
        await getTechnologies();
      }
      showTechTree.value = true;
      document.body.classList.add("body-no-scroll");
    };

    const getTechnologies = async () => {
      try {
        const user = auth.currentUser;

        if (!user) {
          // Handle the case when the user is not authenticated
          console.error("User not authenticated");
          return;
        }

        // Get the Firebase ID token
        const idToken = await user.getIdToken();
        const sessionToken = localStorage.getItem("sessionToken");

        if (!sessionToken) {
          // Handle the case when the session token is not available
          console.error("Session token not available");
          return;
        }
        // Make the request to your backend with user ID as a query parameter
        const response = await fetch(
          `${backendUrl}/getTechnologies?uid=${props.user.google_id}`,
          {
            // credentials: "include",
            method: "GET",
            headers: {
              Authorization: idToken,
              "Content-Type": "application/json",
              sessionToken,
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          techs.value = result;
        } else {
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const closeTechTree = () => {
      showTechTree.value = false;
      document.body.classList.remove("body-no-scroll");
    };

    const onKeyPress = (event) => {
      if (event.key === "Escape") {
        document.body.classList.remove("body-no-scroll");

        if (showInfoModal.value) {
          closeInfoBox();
        } else if (showTechTree.value) {
          closeTechTree();
        } else if (showSettingsMenu.value) {
          if (showAvatarModal.value) closeAvatarModal();
          else closeSettingsMenu();
        } else if (showConfirmationPopup.value) {
          closeConfirmationPopup();
        } else if (showNotificationsMenu.value) {
          closeNotificationsMenu();
        } else if (showBuyMenu.value) {
          closeBuyMenu();
        } else if (showAttackMenu.value) {
          closeAttackMenu();
        } else if (showDivineSpellMenu.value) {
          closeDivineSpellMenu();
        } else if (showAchievementsMenu.value) {
          closeAchievementsMenu();
        } else if (
          showMiningExpeditionMenu.value &&
          !expeditionInProgress.value
        ) {
          closeMiningExpeditionMenu();
        } else if (showLeechMenu.value) {
          closeLeechMenu();
        }
      } else if (event.key === "Enter") {
        if (showInfoModal.value) {
          researchTech(props.user.google_id, selectedTechnology.value);
          closeTechTree();
          closeInfoBox();
        } else if (showBuyMenu.value) {
          confirmPurchase();
          setTimeout(() => {
            closeBuyMenu();
          }, 0.1);
        } else if (showAttackMenu.value) {
          launchAttack();
          closeAttackMenu();
        } else if (showDivineSpellMenu.value) {
          castDivineSpell();
          closeDivineSpellMenu();
        } else if (
          showMiningExpeditionMenu.value &&
          !expeditionInProgress.value
        ) {
          startMiningExpedition();
        }
      }
    };

    const conversionCooldown = ref(20 * 60 * 1000); // 30 minutes in milliseconds
    const canConvertPiety = ref(false);
    const canResearchTechnology = ref(false);
    const conversionTimerText = ref("Loading...");

    const requestNotificationPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        console.log("Notification permission:", permission);
        // You can handle the permission result as needed
      } catch (error) {
        console.error("Error requesting notification permission:", error);
      }
    };

    const updatePointsButton = () => {
      pointAmount.value = 1;
      if (props.user.technologies.includes(21)) pointAmount.value++;
      if (props.user.technologies.includes(22)) pointAmount.value += 3;
    };

    const notifications = ref([]);

    const hideNotification = (notification) => {
      const index = notifications.value.indexOf(notification);
      if (index !== -1) {
        notifications.value.splice(index, 1);
      }
    };

    const displayNotification = (
      newMessage,
      positive = true,
      timer = props.duration
    ) => {
      console.log(
        "Displaying notification:",
        newMessage,
        "Positive:",
        positive
      );

      const newNotification = {
        message: newMessage,
        id: Date.now(),
        positive: positive,
      };

      notifications.value.push(newNotification);

      setTimeout(() => {
        hideNotification(newNotification);
      }, timer);
    };
    const isFirstTimeInitialization = ref(true);
    // Watch for changes in user object
    const isUserInitialized = ref(false);

    watch(
      () => props.user,
      (newUser, oldUser) => {
        if (newUser) {
          if ("lastPietyConversionTime" in newUser) {
            isUserInitialized.value = true;
            if (isFirstTimeInitialization.value) {
              getMyAttacks();
              getMyExpedition();
              updatePointsButton();
              notifyAttack.value = props.user.notifyAttack;
              isFirstTimeInitialization.value = false;
              setInterval(async () => {
                await getMyAttacks();
                await getMyNotifications();
              }, 420000);
            }
          }

          // Update the information box with the new user data
          // For example, you can emit an event to update the parent component
          // or update the local state directly.
          // Here, I'm emitting an event to update the parent component.
          // emit("updateUser", newUser);
        }
      },
      { deep: true } // Use deep watching to observe nested properties
    );

    // Update the timer at regular intervals
    const timerInterval = setInterval(() => {
      // Check if the user is fully initialized before updating the timer text
      if (isUserInitialized.value) {
        if (!isProcessingPiety.value) updateConversionTimerText();
        if (!isProcessingResearch.value) updateResearchTimerText();
        if (!isProcessingAttack.value) updateAttackTimerText();
        if (!isProcessingSpell.value) updateSpellTimerText();
        if (!expeditionInProgress.value) updateExpeditionTimerText();
        if (!isProcessingLeech.value) updateLeechTimerText();
      }
    }, 1000); // Update every 1 second

    // Clear the timer when the component is unmounted
    const imagePaths = ref([
      "/img/mining/dwarf.png",
      "/img/mining/rat.png",
      "/img/mining/elf.jpg",
    ]);
    const preloadImages = () => {
      imagePaths.value.forEach((imagePath) => {
        const image = new Image();
        image.src = imagePath;
      });
    };

    onMounted(() => {
      window.addEventListener("keydown", onKeyPress);
      // preloadImages();
    });
    // Remove the event listener when the component is destroyed

    onBeforeUnmount(() => {
      clearInterval(timerInterval);
      clearInterval(timerIntervalId.value);
      window.removeEventListener("keydown", onKeyPress);
    });

    const canLaunchAttack = ref(false);
    const attackTimerText = ref("Loading...");
    const isProcessingAttack = ref(false);

    const expeditionTimerText = ref("Loading...");
    const canGoMining = ref(false);
    const updateExpeditionTimerText = () => {
      const lastExpeditionTime =
        props.user && "lastExpeditionTime" in props.user
          ? props.user.lastExpeditionTime
          : 0;

      const timeNow = Date.now();
      // Snippet for rounding remainingTime to whole seconds
      let remainingTime =
        Math.ceil((lastExpeditionTime + 1800000 - timeNow) / 1000) * 1000;

      if (remainingTime > 0) {
        remainingTime /= 1000;
        canGoMining.value = false;
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInWeek = 604800;

        let timeUnit = "";
        let timeValue = 0;

        if (remainingTime >= secondsInWeek) {
          timeValue = Math.floor(remainingTime / secondsInWeek);
          timeUnit = "week";
        } else if (remainingTime >= secondsInDay) {
          timeValue = Math.floor(remainingTime / secondsInDay);
          timeUnit = "day";
        } else if (remainingTime >= secondsInHour) {
          timeValue = Math.floor(remainingTime / secondsInHour);
          timeUnit = "hour";
        } else if (remainingTime >= secondsInMinute) {
          timeValue = Math.floor(remainingTime / secondsInMinute);
          timeUnit = "minute";
        } else {
          timeValue = remainingTime;
          timeUnit = "second";
        }

        const remainingTimeText =
          timeValue > 1
            ? `${timeValue} ${timeUnit}s`
            : `${timeValue} ${timeUnit}`;
        expeditionTimerText.value = `⌛ for ${remainingTimeText} 🧭`;
      } else {
        canGoMining.value = true;
        expeditionTimerText.value = "Expedition 🧭";
      }
    };

    const updateAttackTimerText = () => {
      const lastAttackTime =
        props.user && "lastAttackTime" in props.user
          ? props.user.lastAttackTime
          : 0;

      const timeNow = Date.now();
      let remainingTime = lastAttackTime + 21600000 - timeNow;

      if (remainingTime > 0) {
        remainingTime /= 1000;
        canLaunchAttack.value = false;
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInWeek = 604800;

        let timeUnit = "";
        let timeValue = 0;

        if (remainingTime >= secondsInWeek) {
          timeValue = Math.floor(remainingTime / secondsInWeek);
          timeUnit = "week";
        } else if (remainingTime >= secondsInDay) {
          timeValue = Math.floor(remainingTime / secondsInDay);
          timeUnit = "day";
        } else if (remainingTime >= secondsInHour) {
          timeValue = Math.floor(remainingTime / secondsInHour);
          timeUnit = "hour";
        } else if (remainingTime >= secondsInMinute) {
          timeValue = Math.floor(remainingTime / secondsInMinute);
          timeUnit = "minute";
        } else {
          timeValue = remainingTime;
          timeUnit = "second";
        }

        const remainingTimeText =
          timeValue > 1
            ? `${timeValue} ${timeUnit}s`
            : `${timeValue} ${timeUnit}`;
        attackTimerText.value = `⌛ for ${remainingTimeText} ⚔️`;
      } else {
        canLaunchAttack.value = true;
        attackTimerText.value = "Attack ⚔️";
      }
    };
    const canCastSpell = ref(false);

    const updateSpellTimerText = () => {
      const lastDivineSpellTime =
        props.user && "lastDivineSpellTime" in props.user
          ? props.user.lastDivineSpellTime
          : 0;

      const timeNow = Date.now();
      let remainingTime = lastDivineSpellTime - timeNow;

      if (remainingTime > 0) {
        remainingTime /= 1000;
        canCastSpell.value = false;
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInWeek = 604800;

        let timeUnit = "";
        let timeValue = 0;

        if (remainingTime >= secondsInWeek) {
          timeValue = Math.floor(remainingTime / secondsInWeek);
          timeUnit = "week";
        } else if (remainingTime >= secondsInDay) {
          timeValue = Math.floor(remainingTime / secondsInDay);
          timeUnit = "day";
        } else if (remainingTime >= secondsInHour) {
          timeValue = Math.floor(remainingTime / secondsInHour);
          timeUnit = "hour";
        } else if (remainingTime >= secondsInMinute) {
          timeValue = Math.floor(remainingTime / secondsInMinute);
          timeUnit = "minute";
        } else {
          timeValue = remainingTime;
          timeUnit = "second";
        }

        const remainingTimeText =
          timeValue > 1
            ? `${timeValue} ${timeUnit}s`
            : `${timeValue} ${timeUnit}`;
        spellTimerText.value = `⌛ for ${remainingTimeText} ✨`;
      } else {
        canCastSpell.value = true;
        spellTimerText.value = "Divine Spells ✨";
      }
    };

    const updateResearchTimerText = () => {
      const lastResearchTime =
        props.user && "lastResearchTime" in props.user
          ? props.user.lastResearchTime
          : 0;

      const timeNow = Date.now();
      let remainingTime = lastResearchTime - timeNow;

      if (remainingTime > 0) {
        remainingTime /= 1000;
        canResearchTechnology.value = false;
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInWeek = 604800;

        let timeUnit = "";
        let timeValue = 0;

        if (remainingTime >= secondsInWeek) {
          timeValue = Math.floor(remainingTime / secondsInWeek);
          timeUnit = "week";
        } else if (remainingTime >= secondsInDay) {
          timeValue = Math.floor(remainingTime / secondsInDay);
          timeUnit = "day";
        } else if (remainingTime >= secondsInHour) {
          timeValue = Math.floor(remainingTime / secondsInHour);
          timeUnit = "hour";
        } else if (remainingTime >= secondsInMinute) {
          timeValue = Math.floor(remainingTime / secondsInMinute);
          timeUnit = "minute";
        } else {
          timeValue = remainingTime;
          timeUnit = "second";
        }

        const remainingTimeText =
          timeValue > 1
            ? `${timeValue} ${timeUnit}s`
            : `${timeValue} ${timeUnit}`;
        researchTimerText.value = `⌛ for ${remainingTimeText} 🧬`;
      } else {
        canResearchTechnology.value = true;
        researchTimerText.value = "Tech 🧬";
      }
    };

    const updateConversionTimerText = () => {
      const lastPietyConversionTime =
        props.user && "lastPietyConversionTime" in props.user
          ? props.user.lastPietyConversionTime
          : 0;

      const elapsedTime = Date.now() - lastPietyConversionTime;

      if (props.user?.technologies?.includes(3))
        conversionCooldown.value = 10 * 60 * 1000; // 10 minutes in milliseconds
      const remainingTime = conversionCooldown.value - elapsedTime;

      if (remainingTime > 0) {
        canConvertPiety.value = false;
        const minutes = Math.floor(remainingTime / 60000);
        const seconds = Math.floor((remainingTime % 60000) / 1000);
        conversionTimerText.value = `⌛ for ${minutes}m ${seconds}s 🌟`;
      } else {
        canConvertPiety.value = true;
        conversionTimerText.value = "Fortune 🌟";
      }
    };
    const updateLeechTimerText = () => {
      const lastLeechingTime =
        props.user && "lastLeechingTime" in props.user
          ? props.user.lastLeechingTime
          : 0;

      const elapsedTime = Date.now() - lastLeechingTime;

      const fiveMinutes = 5 * 60 * 1000;

      const remainingTime = fiveMinutes - elapsedTime;

      if (remainingTime > 0) {
        const minutes = Math.floor(remainingTime / 60000);
        const seconds = Math.floor((remainingTime % 60000) / 1000);
        leechTimerText.value = `⌛ for ${minutes}m ${seconds}s 🩸`;
      } else {
        leechTimerText.value = "Leech 🩸";
      }
    };

    const signOut = async () => {
      try {
        emit("updateUser", null);
        localStorage.removeItem("session_token");
        await signOutFirebase(auth);
        setAuthenticated(false);
        showSettingsMenu.value = false;
        window.location.reload();
      } catch (error) {
        console.error("Sign-out error:", error);
      }
    };

    const now = new Date().getTime();

    return {
      showStructuresMenu,
      showResourceTooltip,
      resources,
      formattedPointsOrScore,
      showResourceTooltipBool,
      tooltipMessage,
      avatars,
      openAvatarModal,
      closeAvatarModal,
      showAvatarModal,
      selectAvatar,
      removeLeech,
      leechTimerText,
      confirmLeeching,
      showLeechMenu,
      openLeechMenu,
      closeLeechMenu,
      showAchievementsMenu,
      openAchievementsMenu,
      closeAchievementsMenu,
      pointAmount,
      openBuildGuardTowerMenu,
      closeExpeditionResult,
      openExpeditionResult,
      expeditionResult,
      containsMinus,
      showOptions,
      makeExpeditionMove,
      showExpeditionContinueModal,
      exitMiningExpedition,
      isClicking,
      clickingInterval,
      startClicking,
      stopClicking,
      expedition,
      canGoMining,
      expeditionTimerText,
      updateExpeditionTimerText,
      showMiningExpeditionGame,
      characterDance,
      selectCharacter,
      selectedCharacter,
      startMiningExpedition,
      showMiningExpeditionMenu,
      closeMiningExpeditionMenu,
      openMiningExpeditionMenu,

      openBuildSiphonTowerMenu,
      siphonTowerPrice,
      woodPowerPrice,
      openBuildWoodPowerMenu,
      buildWoodPower,
      spellCosts,
      spellCooldowns,
      selectedDivineSpell,
      selectDivineSpell,
      spellTimerText,
      canCastSpell,
      castDivineSpell,

      showDivineSpellMenu,
      openDivineSpellMenu,
      closeDivineSpellMenu,
      notifyAttack,
      updateAttackNotificationSettings,
      lastRowClicked,
      moveCorrect,
      disabledRows,

      defendMoves,
      selectDefendMove,
      defendSequence,
      timeLeftToDefendAttack,
      myAttacksToDefend,
      closeDefendModal,
      showDefendModal,

      canLaunchAttack,
      attackTimerText,
      updateAttackTimerText,
      selectedUser,
      showDropdown,
      toggleDropdown,
      selectUser,

      targetUser,
      targetUserId,

      alterSelectedMovesLength,
      getIconObject,
      moves,
      selectedMoves,
      selectMove,
      sequence,
      divideBuyQuantity,
      multiplyBuyQuantity,
      setToMaxAttackQuantity,
      validateInputAttack,
      openAttackMenu,
      closeAttackMenu,
      showAttackMenu,

      openLaunchAttackMenu,
      launchAttack,
      openSpawnOverlordMenu,
      spawnOverlord,
      overlordPrice,
      underlingPrice,

      displayNotification,
      validateInput,
      openSpawnUnderlingMenu,

      openBuildFarmMenu,
      openBuildStealthFarmMenu,
      openHirePriestMenu,
      openThrowWoodMenu,
      openGainWoodMenu,
      openBuildLaboratoryMenu,

      currentBuyType,
      openBuyMenu,
      closeBuyMenu,
      increaseBuyQuantity,
      decreaseBuyQuantity,
      setToMaxBuyQuantity,
      confirmPurchase,
      buyQuantity,
      maxQuantity,
      showBuyMenu,

      formatTimestamp,
      throwWood,
      throwWoodPrice,
      canResearchSelectedTechnology,
      techs,
      researchTimerText,
      isProcessingResearch,
      isProcessingPiety,
      signOut,
      spawnUnderling,
      buildGuardTower,
      guardTowerPrice,
      buildFarm,
      buildStealthFarm,
      gainPoint,
      gainWood,
      woodPrice,
      hirePriest,
      convertPietyToFortune,
      notifications,
      farmPrice,
      stealthFarmPrice,
      priestPrice,
      canConvertPiety,
      goodFortunePrice,
      conversionTimerText,
      updateResearchTimerText,
      updateLeechTimerText,
      updateConversionTimerText,
      showTooltip,
      toggleTooltip,
      requestNotificationPermission,
      laboratoryPrice,
      buildLaboratory,
      openTechTree,
      showTechTree,
      closeTechTree,
      onKeyPress,
      destroyFarmPrice,
      researchTech,
      showSettingsMenu,
      openSettingsMenu,
      closeSettingsMenu,
      closeConfirmationPopup,
      showConfirmationPopup,
      confirmDelete,
      cancelDelete,
      deleteAccount,
      remainingTimeResearch,
      openInfoBox,
      closeInfoBox,
      showInfoModal,
      selectedTechnology,
      canResearchTechnology,
      showNotificationsMenu,
      closeNotificationsMenu,
      openNotificationsMenu,
      recentNotifications,
      olderNotifications,
      canCastDivineSpell,
    };
  },
};
</script>

<style scoped>
/* Tech Node styling */
.technology-node {
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out 0s, scale 0.2s ease-in-out 0s;
  position: relative;
  overflow: hidden;
  background-color: var(--button-bg);
  color: var(--primary-text);
  border-radius: 10px;
  padding: 12px;
  margin: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.technology-node:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  scale: 1.05;
}
.technology-node p:hover {
  scale: 1;
}

.technology-node p {
  font-size: 14px;
}

/* Tech Tree Modal styling */
.tech-tree-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Container styling */

.tech-tree-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 14px 20px 14px 20px;
  background-color: var(--primary-bg);
  color: var(--primary-text);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  border-radius: 10px;
  position: relative;
  max-height: 94%;
  overflow: scroll;
  max-width: 468px;
  z-index: 2;
}

/* Tech Tree structure */
.tech-tree {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.info-icon {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  padding: 4px;
  z-index: 24;
  border-radius: 8px 0 0 0;
}
.tooltip {
  position: absolute; /* Change to absolute */
  left: 0px;
  top: 16px;
  transform: translateY(-50%);
  display: inline-block;
  vertical-align: middle;
  padding: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    transform 0.2s ease-in-out;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #3498db;
  color: #ffffff;
  text-align: left;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 18px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.price-label {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  width: inherit;
  color: #fff;
  width: 80px;

  pointer-events: none;
}
.price-label:hover {
  transition: background-color 0.3s ease, color 0.3s ease,
    transform 0.2s ease-in-out;
}
.btn:hover ~ .price-label {
  color: #fff;
}

.btn:hover + .tooltip {
  color: #fff;
}

.game-container {
  margin: 0px auto 0px auto;
  max-width: 600px;
  padding: 20px 20px 0px 20px;
  background-color: var(--secondary-bg);
  color: var(--primary-text);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.game-title {
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
}

.emoji {
  font-size: 22px;
}

.user-info-box {
  background-color: var(--primary-bg);
  padding: 15px;
  max-width: 88%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Three columns layout */
  gap: 10px; /* Adjust the gap between items as needed */
  margin: 0 auto;
  min-width: 300px;
}
/* 640px or more then make it 3 in each row */
@media screen and (min-width: 640px) {
  .user-info-box {
    grid-template-columns: repeat(3, 1fr);
  }
}

.info-item {
  background-color: var(--primary-bg);
  border-radius: 8px;
  border: 2px solid var(--secondary-bg);
}
.info-item p {
  margin-top: 6px;
  margin-bottom: 6px;
}

.user-label,
.user-value {
  font-size: 18px;
  color: var(--primary-text);
  margin-top: 12px;
  margin-bottom: 12px;
}
.user-label {
  flex: 1;
}

.username {
  text-align: center !important;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  background-color: var(--primary-bg);
  max-width: 88%;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 22px;
}
.points {
  /* font-weight: bold; */
  font-weight: 500;
  font-size: 19px;
}

.flex2-wrapper {
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: center;
  margin: 0px auto 0 auto;
  min-width: 300px;
  max-width: 88%;
  background-color: var(--primary-bg);
  padding: 15px 15px;
}
.notification-messages {
  margin: 14px 0;
  text-align: left;
}
.flex2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 17px;
  margin-bottom: 10px;
}
.flex2 .btn {
  margin-bottom: -6px;
}
.btn {
  height: 100px;
  width: 100px;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  padding: 20px 10px 20px 10px;
  border: none;
  color: #fff;
  /* Optional: Add box shadow for a subtle lift effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  /* Adjust colors to match your design */
  /* Adjust colors to match your design */
  background-color: var(--button-bg); /* Light gray background color */
}
.btn:hover {
  transition: background-color 0.3s ease, color 0.3s ease,
    transform 0.2s ease-in-out;
}

@media screen and (max-width: 529px) {
  .username {
    padding-top: 22px;
    padding-bottom: 16px;
    font-size: 20px;
  }
}
.btn:hover {
  background-color: #3498db; /* Subtle blue background on hover */
  color: #fff; /* White text color on hover for better visibility */
}

.tooltip .info-icon {
  opacity: 1;
  transition: opacity 0.3s ease; /* You can adjust the duration and easing as needed */
}

/* Hide the .info-icon when the .btn is active */
.btn:active + .tooltip .info-icon {
  opacity: 0;
  transform: scale(0.85);
}

.notification {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 16px;
  z-index: 10000000000;
  width: 100%;
  transition: background-color 0.5s;
}

.notification.positive {
  background-color: #28a745;
}

.notification.negative {
  color: black;
  font-weight: 600;
  background-color: #ff4444;
}

/* Not Signed In Styles */
.not-signed-in {
  text-align: center;
  margin-top: 50px;
}

.not-signed-in-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.not-signed-in-text {
  font-size: 16px;
  color: #555;
}

.small-margin-bottom {
  margin-bottom: 10px;
}

.close-btn {
  color: #aaa;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.greenBorder {
  border: 1px solid #2ecc71 !important;
}
.prerequisites {
  border: 1px solid #e74c3c;
}
.red-bold-bad {
  color: #e74c3c;
  font-weight: bold;
}
.some-spacing {
  margin-bottom: 10px;
  margin-top: 10px;
}
.small-text {
  font-size: 11px;
}
.green-text {
  color: #2ecc71;
}
.gear-icon {
  cursor: pointer;
  position: absolute;
  top: 16px;
  left: 32px;
  font-size: 24px;
}
.gear-icon:hover {
  opacity: 0.8;
}

.bell-icon {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 32px;
  font-size: 24px;
}
.bell-icon:hover {
  opacity: 0.8;
}

.trophy-icon {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 72px;
  font-size: 24px;
}

.achievements-menu-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.achievements-menu-content {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  position: relative;
}

.achievements-menu-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--accent-text);
}

.achievements-list {
  max-height: 300px;
  overflow-y: auto;
}

.achievement {
  background-color: var(--secondary-bg);
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 10px;
}

.achievement-name {
  font-size: 18px;
  color: var(--accent-text);
}

.achievement-level {
  font-size: 14px;
  color: var(--secondary-text);
}
.trophy-icon:hover {
  opacity: 0.8;
}

.notifications-menu-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}
.settings-menu-modal,
.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.settings-menu-content,
.confirmation-popup-content {
  background: var(--primary-bg);
  color: var(--primary-text);
  padding: 24px 20px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.settings-menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.settings-menu-title {
  font-size: 1.5em;
  margin-bottom: 16px;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.toggle-label {
  margin-right: 10px;
}

.sign-out-btn,
.delete-account-btn {
  cursor: pointer;
  color: var(--primary-text);
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.sign-out-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.delete-account-btn {
  color: #f44336;
}

.delete-account-btn:hover {
  background-color: rgba(244, 67, 54, 0.1);
}

.confirmation-popup-content p {
  margin-bottom: 24px;
}

.confirmation-popup-content button {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirmation-popup-content button.confirm {
  background-color: #4caf50;
  color: white;
}

.confirmation-popup-content button.cancel {
  background-color: #f44336;
  color: white;
}

.confirmation-popup-content button:hover {
  opacity: 0.8;
}
.info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.info-modal {
  background: var(--primary-bg);
  color: var(--primary-text);
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 3;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-btn {
  font-size: 24px;
  cursor: pointer;
  color: var(--secondary-text);
  transition: color 0.3s;
}

.close-btn:hover {
  color: var(--highlight-color);
}

.text-info-name {
  font-size: 28px;
  margin: 0;
  width: 100%;
  text-align: center;
}

.tech-info p {
  margin-bottom: 15px;
  line-height: 1.5;
}

.research-tech-btn {
  background-color: #3498db;
  margin-top: 10px;
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.research-tech-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.already-researched {
  color: var(--secondary-text);
  text-align: center;
  margin-top: 10px;
}
.research-new-tech {
  margin-top: 0px;
  margin-bottom: 10px;
}

.research-area {
  margin-top: 20px;
}
.text-info-name {
  text-align: center;
  font-size: 24px;
}
.notifications-menu-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 24px 12px 24px;
  max-width: 520px;
  min-width: 260px;
  min-height: 200px;
  background: var(--primary-bg);
  color: var(--primary-text);
  border-radius: 8px;
  max-height: 94%;
  overflow: hidden;
  display: auto;
}

.notifications-header {
  margin-bottom: 14px;
}

.notifications-menu-title {
  font-size: 24px;
  margin: 0;
}

.notifications-list {
  width: 100%;
  overflow-y: auto;
}

.notification-message {
  margin-bottom: 14px;
  padding: 14px 20px;
  border-radius: 8px;
  background-color: var(--accent-bg);
  color: var(--accent-text);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 16px;
  position: relative;
}

.notification-message p {
  margin: 0 30px 0 0;
}

.notification-timestamp {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 10px;
  font-size: 13px;
  color: var(--secondary-text);
}

.older-events {
  margin-top: 20px;
}

.older-events-title {
  font-size: 20px;
  margin-bottom: 14px;
  color: var(--primary-text);
}

.no-events-message {
  margin-top: 20px;
  text-align: center;
}
.notbuyable {
  border: 1px solid #e74c3c;
}
.buyable {
  border: 1px solid #3498db;
}
.researched {
  border: 1px solid #2ecc71;
}
.tech-cost {
  font-size: 9px;
  margin-top: 5px;
  text-align: center;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: inherit;
  color: var(--secondary-text);
  width: 80px;

  pointer-events: none;
}

.your-science-label {
  position: absolute;
  top: 16px;
  left: 14px;
  font-size: 18px; /* Larger font size for readability */
  color: var(--secondary-text); /* Dark color for the text */
}
.your-science-label-bottom {
  position: absolute;
  bottom: 16px;
  left: 14px;
  font-size: 18px; /* Larger font size for readability */
  color: var(--secondary-text); /* Dark color for the text */
}

.divine-spell-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.leech-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  overflow-y: scroll;
}

.attack-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  overflow-y: scroll;
}
.buy-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.leech-menu-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.buy-menu {
  background-color: var(--secondary-bg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.buy-menu .modal {
  background-color: var(--primary-bg);
  padding: 20px;
}
.spell-menu .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  border-radius: 10px;
  color: var(--primary-text);
  background-color: var(--secondary-bg);
  padding: 20px 30px 30px 30px;
  margin: 10px;
}
.spell-menu .modal-content .close-btn {
  top: 10px;
  right: 10px;
}

.attack-menu-overlay .buy-menu {
  margin-top: 60px;
  margin-bottom: 60px;
}
.buy-menu .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  border-radius: 10px;
  color: var(--primary-text);
  padding: 20px;
}
.buy-menu .modal-content .type-title {
  font-size: 20px; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 10px;
}

.buy-menu .modal-content .buy-emoji {
  font-size: 40px; /* Adjust size as needed */
  margin: 10px 0;
}

.buy-menu .modal-content .buy-cost {
  color: var(--secondary-text);
  margin: 10px 0;
}

.buy-menu .quantity-selector {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.buy-menu .quantity-selector button {
  background-color: var(--button-bg);
  color: var(--primary-text);
  border: none;
  padding: 4px 10px;
  margin: 0 3px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 40px;
}
.attack-menu-overlay .buy-menu .quantity-selector input {
  min-width: 80px;
}
.buy-menu .quantity-selector button:hover {
  background-color: var(--button-hover-bg);
}
.buy-menu .quantity-selector input[type="number"] {
  -moz-appearance: textfield;
  appearance: none; /* Standard property */
}

/* Chrome, Safari, Edge */
.buy-menu .quantity-selector input::-webkit-outer-spin-button,
.buy-menu .quantity-selector input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buy-menu .quantity-selector input {
  width: 84px;
  text-align: center;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 5px 1px;
  background-color: var(--third-bg);
  color: var(--primary-text);
  font-size: 15px;
}

.buy-menu .total-cost {
  font-size: 18px;
  margin: 10px 0;
  color: var(--primary-text);
}

.confirm-btn {
  background-color: var(--button-bg);
  color: var(--accent-text);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirm-btn:hover {
  background-color: var(--button-hover-bg);
}
.modal-content .close-btn {
  top: -4px;
  right: -4px;
}
.leech-modal {
  position: relative;
}
.leech-modal-content {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  padding: 20px 4px;
  width: 360px;
}
.filler {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.sequence {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

.sequence-label {
  font-size: 18px;
  color: var(--primary-text);
}

.sequence-row {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.move-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  padding: 8px 16px;
  background-color: var(--button-bg);
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  cursor: pointer;
  opacity: 0.65;
  font-size: 32px;
  height: 65px;
  width: 65px;
}

.move-button:hover {
  background-color: var(--button-hover-bg);
}

.move-button.selected {
  opacity: 1;
  border: 1px solid #28a745;
}
.move-button.selected:hover {
  background-color: #9ae99a;
}
/* Target User Styles */
/* Target User Styles */
.targetUser {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 300px;
}

.dropdown {
  margin: 0 auto;
  width: 80%;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--secondary-bg);
  overflow: hidden; /* Added to contain the animation */
}

.dropdown-selected {
  padding: 10px;
  color: var(--primary-text);
  cursor: pointer;
  border-radius: 0;
}

.dropdown-options {
  max-height: 0;
  overflow-y: scroll;
  transition: max-height 0.3s ease-out; /* Animation transition */
  border-top: 1px solid var(--border-color);
}

.show {
  max-height: 512px; /* Adjust as needed */
}

.dropdown-option {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  color: var(--primary-text);
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: var(--button-hover-bg) !important;
}
/* Style the modal */
.defend-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.82);
  justify-content: center;
  align-items: center;
  z-index: 9191919;
}

.defend-modal-content {
  background: var(--primary-bg);
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 360px;
  max-height: 80%;
  overflow-y: scroll;
}
.move-button {
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f0f0f0;
  transition: background-color 0.3s ease;
}

.move-button:hover {
  background: #e0e0e0;
}

.disabled {
  pointer-events: none; /* Disable clicks on disabled buttons */
  opacity: 0.377; /* Example: Lower opacity for disabled rows */
}

.selected {
  opacity: 1;
  border: 1px solid #28a745;
  background-color: #28a745;
}

.selected-defend {
  opacity: 0.8; /* Example: Lower opacity for disabled rows */
  background-color: #9e9e9e;
  border: none;
}
.unknown {
  background-color: lightgray; /* Light gray background for unknown correctness */
}
.correct {
  background-color: green; /* Green background for correct move */
}

.incorrect {
  background-color: red; /* Red background for incorrect move */
}
.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.toggle-label {
  margin-right: 14px;
  font-size: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px; /* Smaller width for the slider */
  height: 30px; /* Smaller height for the slider */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px; /* Smaller border-radius for the slider */
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px; /* Smaller height for the slider */
  width: 22px; /* Smaller width for the slider */
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.cast-spell-btn {
  height: 40px;
  width: 120px;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  padding: 20px 10px 20px 10px;
  border: none;
  background-color: var(--button-bg); /* Light gray background color */
  color: var(--primary-text); /* Dark gray text color for better readability */
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 10px;
  border: 1px solid var(--button-bg);
}
.cast-spell-btn:hover {
  background-color: var(--secondary-bg);
  border: 1px solid var(--border-color);
  opacity: 0.8;
}
.cast-spell-btn:active {
  transform: scale(0.95);
}
.grayedOut {
  opacity: 0.5;
  pointer-events: none;
}

.spell-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 24px;
  color: var(--primary-text);
}

.spells-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.spell-item {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  background-color: var(--accent-bg);
  color: var(--accent-text);
  cursor: pointer;
}
.spell-item:hover {
  background-color: var(--secondary-bg);
}
.spell-item h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}
.selectedSpell {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  border: 1px solid #28a745;
}
.mining-expedition-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.mining-expedition-overlay {
  background: var(--secondary-bg);
  color: var(--primary-text);
  border-radius: 12px;
}

.mining-expedition-modal {
  width: 450px;
  text-align: center;
  position: relative;
}

.mining-expedition-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-expedition-btn {
  font-size: 27px;
  cursor: pointer;
  color: var(--secondary-text);
  transition: color 0.3s;
  position: absolute;
  right: 14px;
  top: 14px;
}

.close-expedition-btn:hover {
  opacity: 0.9;
}

.expedition-title {
  font-size: 28px;
  font-weight: bold;
  margin: 0px 0 10px 0;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.expedition-info p {
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 1.5;
}

.expedition-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.start-expedition-btn {
  width: 200px;
  height: 80px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  background-color: var(--button-bg);
  color: var(--accent-text);
}

.start-expedition-btn:hover {
  opacity: 0.8;
}
.character-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 14px;
  margin-bottom: 40px;
}
.character-options button {
  background-color: var(--button-bg);
  color: var(--accent-text);
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.9s, opacity 0.3s transform 0.4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.character-options button img {
  width: 110px;
  height: 168px;
  margin-bottom: 6px;
  object-fit: cover;
}

.character-options button.selected-character {
  background-color: var(--button-hover-bg);
  color: var(--accent-text);
}

.character-options button img.selected-character {
  width: 120px;
  height: 180px;
}

.character-options button:hover {
  opacity: 0.8;
}

.mining-expedition-game-container {
  width: 520px;
  z-index: 2;
  height: 100%;
}

.mining-expedition-game-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
}

.mining-expedition-game-modal {
  overflow-y: scroll;
  position: relative;
  width: 100%;
  height: 100%;
}

.mining-game-container {
  max-width: 520px;
  user-select: none;
  /* margin-left: auto;
  margin-right: auto; */
  margin: auto;
}

.mining-game {
  user-select: none;
  background-color: var(--primary-bg);
  border: 1px solid var(--border-color);
  color: var(--primary-text);
  margin-top: auto;
  margin-bottom: auto;

  height: 90%;
  padding-bottom: 4%;
}

.selected-character-image {
  user-select: none;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.mining-game-box {
  width: 60px;
  height: 60px;
  background-color: var(--button-bg); /* Set your desired background color */
  border: 1px solid #808080; /* Set your desired border color */
}

.mining-game-header {
  margin-top: 12px;
}
.exit-mine-btn {
  background-color: var(--button-bg);
  color: var(--primary-text);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 28px;
  margin-top: 40px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.exit-mine-btn:hover {
  color: white;
  background-color: #ff4444;
  opacity: 0.8;
}
.mining-option-btn {
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid var(--border-color);
  background-color: var(--button-bg);
  color: var(--primary-text);
  cursor: pointer;
  width: 305px;

  min-height: 70px;
  font-size: 18px;
}
.mining-option-btn:hover {
  background-color: var(--button-hover-bg);
  opacity: 0.8;
}

.expedition-prompt {
  margin-bottom: 22px;
  text-align: center;
  font-size: 20px;
  font-weight: 100;
  color: var(--secondary-text);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.75;
  animation: glow 3s infinite alternate; /* Adjust animation duration and other properties as needed */
}
@keyframes glow {
  0% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0);
  }
  30% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.25);
  }
  50% {
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
  }
  70% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.25);
  }
  100% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0);
  }
}
.mining-game-stats {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 17px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between {
  display: flex;
  width: 100%;
}

.stat-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 68px;
  min-height: 68px;
  text-align: center;
  margin: 4px;
}

.stat-emoji {
  font-size: 24px;
  margin-bottom: 4px;
  font-size: 28px;
}

.stat-value {
  font-weight: bold;
  font-size: 18px;
}
.expedition-continue-modal-overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure the overlay is above other content */
}

.expedition-continue-modal {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 370px; /* Adjust width as needed */
  font-size: 18px;
}

.expedition-continue-modal-content {
  margin-bottom: 20px;
}

.close-expedition-continue-btn {
  background-color: #4caf50;
  color: var(--accent-text);
  padding: 14px 26px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 22px;
}
.red-text {
  color: red;
}

.green-text {
  color: green;
}
/* Styles for Expedition Results Modal */
.expedition-result-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expedition-result-modal {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  border-radius: 8px;
  padding: 20px;
}

.expedition-result-content {
  text-align: center;
}

.close-expedition-result-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--button-bg);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: var(--primary-text);
}
.notifications-menu-content .close-btn {
  top: 10px;
  right: 10px;
}
.tier-selector button {
  padding: 10px;
  margin: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  border: none;
}

.tier-selector button.selected {
  background-color: #007bff;
  color: white;
}
.leech {
  border: 1px solid #ff0000;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}
.leech:hover {
  opacity: 0.8;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}
.avatar:hover {
  opacity: 0.8;
}

.avatar-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.avatar-modal-content {
  background: var(--primary-bg);
  color: var(--primary-text);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.avatar-modal-title {
  font-size: 1.5em;
  margin-bottom: 16px;
}

.current-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 3px solid #28a745;
}

.avatar-selection-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  justify-items: center;
}

.avatar-option {
  cursor: pointer;
}

.avatar-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transition: transform 0.2s;
}

.avatar-option:hover .avatar-image {
  transform: scale(1.1);
}
.gain-point-btn {
  font-size: 20px;
}
</style>
